import { getFromId } from "store/common";
import proxy from "api/proxy";
import funcs from "tools/functions";
import store from "store";

const pureStore = (set, get) => {
  return {
    seqId: 0,
    level: null, // level > parent, child, grand
    store: {}, // Used for store the data for to send to API
    record: {}, // Used for render data in UI
    context: {}, // model, ctxView
    updateRecord: (rec) => set(() => ({ record: rec })),
    updateStore: (rec) => set(() => ({ store: rec })),
    newRecord: async (ctxView, parentRec) => {
      const session = store.get("ctxSession");
      let [_store, _record] = await funcs.getDefaults(0, ctxView);
      if (parentRec) {
        const { target, record: parent } = parentRec;
        _store[target] = parent.id;
      }

      const timeStamp = new Date().getTime();
      const hashRec = -(timeStamp + session.user * 10 ** 13);

      set(() => ({
        seqId: hashRec,
        store: { ..._store, id: hashRec },
        record: { ..._record, id: hashRec },
        context: { ctxView, model: ctxView.model },
      }));
    },
    setRecord: async (id, model, ctxView) => {
      const res = await getFromId(id, model, ctxView);
      set({
        record: { ...res },
        store: { id: id },
        context: { model, ctxView },
      });
    },
    save: async () => {
      const { model } = get().context;
      const toSave = get().store;
      const { data, error } = await proxy.saveQuery({
        model: model,
        storeRec: toSave,
      });
      if (!error && data) {
        // We need preprocess data
        let recordId;
        if (Array.isArray(data)) {
          recordId = data[0];
        } else {
          const rec = funcs.recordToJs(data);
          recordId = rec.id;
        }
        set({ store: { id: recordId } });
      }
      return { data, error };
    },
    reset: (full) => {
      if (full) {
        set({ store: {}, record: {} });
      } else {
        set({ store: { id: get().record.id } });
      }
    },
    reload: async () => {
      const { model, ctxView } = get().context;
      const store = get().store;
      await get().setRecord(store.id, model, ctxView);
    },
  };
};

export default pureStore;
