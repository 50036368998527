// Guest model
import mainStore from "store/mainStore";
import grandStore from "store/grandStore";
import proxy from "api/proxy";
import store from "store";

const GUEST = [
  { id: "adult", name: "adult" },
  { id: "child", name: "child" },
];

const SEX = [
  { id: "female", name: "female" },
  { id: "male", name: "male" },
];

const TYPES = [
  { id: "13", name: "cedula_ciudadania" },
  { id: "41", name: "pasaporte" },
  { id: "12", name: "tarjeta_identidad" },
  { id: "11", name: "registro_civil" },
  { id: "21", name: "tarjeta_extranjeria" },
  { id: "22", name: "cedula_extranjeria" },
  { id: "42", name: "tipo_documento_extranjero" },
  { id: "47", name: "pep" },
  { id: "91", name: "nuip" },
];

const model = "party.party";
const fields = [
  "name",
  "id_number",
  "type_document",
  "sex",
  "main_mobile",
  "main_email",
  "street",
  "birthday",
  "nationality.rec_name",
  "country.rec_name",
];

function getRequiredByMainGuest(record) {
  return record?.main_guest;
}

function onChangeNationality(record) {
  if (record.nationality) {
    record.origin_country = record.nationality;
    record.target_country = record.nationality;
  }
  return record;
}

async function onAutoCreateGuest() {
  const { record: mainRecord } = mainStore.getState();
  const { record, store, updateStore, updateRecord } = grandStore.getState();

  if (mainRecord.party) {
    const dom = [
      ["id", "=", mainRecord.party.id],
      ["type_document", "!=", "31"],
    ];
    const { data } = await proxy.search(model, dom, fields);

    if (data) {
      const rec = { ...data[0] };
      let recordGuest = {
        main_guest: true,
        name: rec.name,
        id_number: rec.id_number,
        address: rec.street,
        mobile: rec.main_mobile,
        email: rec.main_email,
        birthday: rec.birthday,
      };
      let storeGuest = { ...recordGuest };
      if (rec.type_document) {
        for (const _type of TYPES) {
          if (_type.id === rec.type_document) {
            recordGuest.type_document = _type;
            storeGuest.type_document = _type.id;
          }
        }
      }

      if (rec["country."]) {
        recordGuest.country = rec["country."];
        storeGuest.country = rec["country."].id;
      }
      if (rec["nationality."]) {
        recordGuest.nationality = rec["nationality."];
        storeGuest.nationality = rec["nationality."].id;
      }

      if (rec.sex) {
        for (const _sex of SEX) {
          if (_sex.id === rec.sex) {
            recordGuest.sex = _sex;
            storeGuest.sex = _sex.id;
          }
        }
      }
      const recordGrand = { ...record, ...recordGuest };
      const storeGrand = { ...store, ...storeGuest };
      updateRecord(recordGrand);
      updateStore(storeGrand);
      return {};
    }
  }
  return {
    msg: "hotel.guest.you_can_not_clone_holder",
  };
}

function visibleCreateGuest() {
  return true;
}
function visibleForeingCity(name, storeRec) {
  const config = store.get("hotelConfig");
  let country = config["nationality."]?.id;
  let res = storeRec.nationality?.id == country;
  return !res;
}
function getDefaultCountry() {
  const config = store.get("hotelConfig");
  return config["nationality."];
}

async function onChangeIdNumber(record, store) {
  if (record.id_number) {
    const fieldsNames = [
      "name",
      "email",
      "mobile",
      "birthday",
      "profession",
      "type_document",
      "sex",
      "nationality.name",
      "nationality.code",
      "origin_country.name",
      "origin_country.code",
      "target_country.name",
      "target_country.code",
      "address",
    ];
    const { data } = await proxy.search(
      "hotel.folio.guest",
      [["id_number", "=", record.id_number]],
      fieldsNames,
    );
    if (data && data.length > 0) {
      const guest = data.pop();
      delete guest.id;

      fieldsNames.forEach((field) => {
        if (field === "sex") {
          for (const _sex of SEX) {
            if (_sex.id === guest.sex) {
              record.sex = _sex;
              store.sex = _sex.id;
            }
          }
        } else if (field === "type_document") {
          for (const _type of TYPES) {
            if (_type.id === guest.sex) {
              record.sex = _type;
              store.sex = _type.id;
            }
          }
        } else if (guest[field]) {
          record[field] = guest[field];
          store[field] = guest[field];
        } else if (guest[field + "."]) {
          record[field] = guest[field + "."];
          store[field] = guest[field + "."];
        }
      });
    }
  }
  return record, store;
}

const getView = () => {
  let DictCtxView = {
    model: "hotel.folio.guest",
    form_action: ["add", "delete", "edit"],
    table_action: ["add", "edit"],
    orderBy: [["name", "ASC"]],
    selectable: null, // Options for table rows: null, multi, one
    target: "folio",
    autoSave: true,
    webfields: {
      name: { type: "char", required: true },
      type_guest: {
        type: "selection",
        options: GUEST,
        required: true,
        translate: true,
        default: { id: "adult", name: "adult" },
      },
      type_document: {
        type: "selection",
        options: TYPES,
        required: true,
        translate: true,
        default: { id: "13", name: "cedula_ciudadania" },
      },
      sex: {
        type: "selection",
        options: SEX,
        required: true,
        translate: true,
        default: { id: "male", name: "male" },
      },
      id_number: { type: "char", required: true, withChange: onChangeIdNumber },
      mobile: { type: "char", required: getRequiredByMainGuest },
      email: { type: "char", required: getRequiredByMainGuest },
      address: { type: "char", required: getRequiredByMainGuest },
      birthday: { type: "date", required: true },
      profession: { type: "char" },
      notes: { type: "text" },
      foreign_city: { type: "char", visible: visibleForeingCity },
      main_guest: {
        type: "boolean",
        translate: true,
        dependents: ["email", "mobile"],
      },
      nationality: {
        type: "many2one",
        model: "country.country",
        default: getDefaultCountry,
        required: true,
        dependents: ["origin_country", "target_country", "code"],
        withChange: onChangeNationality,
      },
      origin_country: {
        type: "many2one",
        model: "country.country",
        default: getDefaultCountry,
        required: true,
      },
      target_country: {
        type: "many2one",
        default: getDefaultCountry,
        model: "country.country",
        required: true,
      },
      clone_holder: {
        type: "button",
        visible: visibleCreateGuest,
        color: "amber",
        method: onAutoCreateGuest,
        iconLeft: "fi fi-rr-users",
      },
    },
    webtree: [
      { name: "name", width: "25%" },
      { name: "type_guest", width: "15%" },
      { name: "type_document", width: "10%" },
      { name: "id_number", width: "10%" },
      { name: "mobile", width: "10%" },
      { name: "main_guest", width: "5%" },
    ],
    webform: [
      { name: "id_number" },
      { name: "clone_holder" },
      {
        id: "info1",
        grid: [{ name: "type_guest" }, { name: "main_guest" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "type_document" },
      { name: "name" },
      {
        id: "info2",
        grid: [{ name: "birthday" }, { name: "sex" }],
        size: [1, 2],
        span: "col-span-1",
      },
      // { name: "country" },
      // { name: "subdivision" },
      // { name: "city" },
      { name: "nationality" },
      { name: "foreign_city" },
      {
        id: "info_country",
        grid: [{ name: "origin_country" }, { name: "target_country" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "address" },
      { name: "mobile" },
      { name: "email" },
      { name: "notes" },
      { name: "clone_holder" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
