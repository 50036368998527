import React, { useState, Fragment } from "react";
import { signal } from "@preact/signals-react";

import ModalForm from "components/Modals/ModalForm";
import Loading from "components/Tools/Loading";
import { classNames } from "tools/ui";
import mainStore from "store/mainStore";
import childStore from "store/childStore";

const openModal = signal(false);

/**
 * Component for create table with body and header
 * @param {object} props - component props
 * @param {boolean} props.sortable - boolean define if column is sortable
 * @param {Objetc} props.ctxView - define model view
 * @param {Array} props.records - Array of records to fill in
 * @param {function} props.updateRecords - Function for update records parent
 * @param {function} props.onClickRow - Function for handle event onClickRow
 * @param {function} props.onClickCell - Function for handle event onClickCell
 * @param {boolean} props.paginate - Define if table is paginate
 * @return {Component} - table component
 */

function GridCardsNew(props) {
  const { record, data, attrs, field, isLoading = false } = props;
  const { ctxView, style } = field;
  const { level: _level } = attrs;
  const CustomCard = ctxView.card;
  const [selectedCard, setSelectedCard] = useState(null);

  const {
    setRecord,
    // record: recordChild,
    // newRecord: newChild,
    // updateRecord: updateChildRecord,
    // updateStore: updateChildStore,
  } = childStore();

  // console.log("record === ", record);
  const { setRecord: setMainRecord } = mainStore();
  let level = _level;
  if (_level === "child") {
    level = "grand";
  } else if (_level === "main") {
    level = "child";
  }

  // function handleDelete(record) {
  //   // props.handleDelete(record);
  // }

  function onClose() {
    openModal.value = false;
    // if (reloadRecords) {
    //   reloadRecords();
    // }
  }

  function onClick(record) {
    if (record.id > 0) {
      if (level === "main") {
        setMainRecord(record.id, ctxView.model, ctxView);
      } else if (level === "child") {
        setRecord(record.id, ctxView.model, ctxView);
      } else {
        console.log("Add code for grandchild here......");
      }
    } else {
      setSelectedCard(record);
    }
    openModal.value = true;
  }

  if (isLoading) return <Loading />;

  const _records = Array.from(data.value.values());
  console.log(" style....", style);
  return (
    <Fragment>
      <div
        id="grid-cards"
        className={classNames(
          "grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4 col-span-2 gap-y-3 gap-x-2 my-3",
          style,
        )}
      >
        {_records.map((rec, idx) => {
          return (
            <CustomCard
              key={idx}
              record={rec}
              ctxView={ctxView}
              onClick={onClick}
            />
          );
        })}
      </div>
      {openModal.value && (
        <ModalForm
          open={openModal}
          ctxView={ctxView}
          onClose={onClose}
          level={level}
          fieldName={field.name}
        />
      )}
    </Fragment>
  );
}

export default GridCardsNew;
