const hotels = {
  HOTELTERRA: {
    colorPrimary: "!bg-[#0e2d1b]",
    hoverColorPrimary: "bg-[#0e2d1b]",
    colorTextPrimary: "text-[#ff8D6D]",
    colorSecundary: "bg-[#ff8D6D]",
    privacyPolicies: "https://terrabiohotel.com/politica-de-privacidad/",
    dataProtection: "https://terrabiohotel.com/proteccion-de-datos/",
    callToActions: "text-white",
    colorIcon: "#7bbb4b",
    url: "https://terrabiohotel.com/",
  },
  HOTELKOKKOBEACH: {
    colorPrimary: "!bg-[#0a5b4c]",
    hoverColorPrimary: "bg-[#0a5b4c]",
    colorTextPrimary: "text-[#ff8D6D]",
    colorSecundary: "bg-[#ff8D6D]",
    privacyPolicies: "https://terrabiohotel.com/politica-de-privacidad/",
    dataProtection: "https://terrabiohotel.com/proteccion-de-datos/",
    callToActions: "text-white",
    colorIcon: "#7bbb4b",
    url: "#",
    sizeLogo: "md:h-28",
  },
  HOTELWAIRA: {
    colorPrimary: "!bg-[#9cc25b]",
    hoverColorPrimary: "bg-[#1ca520]",
    colorTextPrimary: "text-[#244725]",
    colorSecundary: "bg-gray-800",
    privacyPolicies: "#",
    dataProtection: "#",
    callToActions: "text-white",
    colorIcon: "#1ca520",
    url: "https://wairahotel.com.co/",
    sizeLogo: "md:h-32",
    excludeTaxes: true,
  },
  LACHINCA: {
    colorPrimary: "!bg-[#52658C]",
    hoverColorPrimary: "bg-[#1ca520]",
    colorTextPrimary: "text-[#fdbd57]",
    colorSecundary: "bg-[#fdbd57]",
    privacyPolicies: "#",
    dataProtection: "#",
    callToActions: "text-black",
    colorIcon: "#cb4457",
    url: "https://wairahotel.com.co/",
    sizeLogo: "md:h-32",
    excludeTaxes: true,
  },
  HOTELFR: {
    colorPrimary: "!bg-[#3b5d1d]",
    hoverColorPrimary: "bg-[#1ca520]",
    colorTextPrimary: "text-white",
    colorSecundary: "bg-gray-800",
    privacyPolicies: "#",
    dataProtection: "#",
    callToActions: "text-white",
    colorIcon: "#1ca520",
    url: "https://wairahotel.com.co/",
    sizeLogo: "md:h-32",
    grayScale: true,
  },
  HOTELWAIRALOCAL: {
    colorPrimary: "!bg-[#9cc25b]",
    hoverColorPrimary: "bg-[#1ca520]",
    colorTextPrimary: "text-[#244725]",
    colorSecundary: "bg-gray-800",
    privacyPolicies: "#",
    dataProtection: "#",
    callToActions: "text-white",
    colorIcon: "#1ca520",
    url: "#",
    sizeLogo: "md:h-32",
    excludeTaxes: true,
  },
  ACRESHOTEL: {
    colorPrimary: "bg-[#181818]",
    hoverColorPrimary: "bg-[#181818]",
    colorTextPrimary: "text-white",
    colorSecundary: "bg-[#a14a25]",
    privacyPolicies: "#",
    dataProtection: "#",
    grayScale: false,
    callToActions: "text-white",
    url: "https://arishahotel.com/",
  },
  ABORIGENES: {
    colorPrimary: "!bg-[#000000]",
    hoverColorPrimary: "bg-[#000000]",
    colorTextPrimary: "text-[#c0622f]",
    colorSecundary: "bg-[#c0622f]",
    privacyPolicies: "",
    dataProtection: "",
    callToActions: "text-white",
    colorIcon: "#c0622f",
    url: "#",
    sizeLogo: "md:h-24",
  },
  ARISHA: {
    colorPrimary: "bg-[#181818]",
    hoverColorPrimary: "bg-[#181818]",
    colorTextPrimary: "text-white",
    colorSecundary: "bg-[#a14a25]",
    privacyPolicies: "#",
    dataProtection: "#",
    grayScale: true,
    callToActions: "text-white",
    url: "https://arishahotel.com/",
  },
  PIEDRALOCAL: {
    colorPrimary: "!bg-[#0a5b4c]",
    hoverColorPrimary: "bg-[#0a5b4c]",
    colorTextPrimary: "text-[#ff8D6D]",
    colorSecundary: "bg-[#ff8D6D]",
    privacyPolicies: "https://terrabiohotel.com/politica-de-privacidad/",
    dataProtection: "https://terrabiohotel.com/proteccion-de-datos/",
    callToActions: "text-white",
    colorIcon: "#7bbb4b",
    url: "#",
    sizeLogo: "md:h-32",
    excludeTaxes: true,
  },
};

export default hotels;
