import React, { useEffect } from "react";
import { useSignal } from "@preact/signals-react/runtime";
import { FormattedMessage as FM } from "react-intl";

import ButtonsStep from "components/Step/ButtonsStep";
import MsgInfo from "components/Step/MsgInfo";

function StepForm({ name, values, openModal, reset, reload }) {
  const buttonsStep = useSignal([]);
  const nextMsg = useSignal(null);
  const widget = useSignal(null);

  async function handleEndStep() {
    openModal.value = false;
    if (reset) {
      reset();
    }
    if (reload) {
      reload();
    }
    setStep("start");
  }

  async function handleNextStep(method) {
    const { next, msgError } = await method();
    setStep(next, msgError);
  }

  function setStep(next, msgError) {
    const { Component, msg, buttons, endButton, defaults } = values[next];
    const _buttons = [];
    if (endButton) {
      _buttons.push({ name: endButton, method: handleEndStep });
    }
    if (buttons) {
      for (const btn of buttons) {
        btn.handleStep = handleNextStep;
        _buttons.push(btn);
      }
    }
    if (msgError) {
      nextMsg.value = msgError;
    } else if (msg) {
      nextMsg.value = <FM id={msg} key={msg} />;
    }
    widget.value = Component ? <Component defaults={defaults} /> : null;
    buttonsStep.value = _buttons;
  }

  useEffect(() => {
    setStep("start");
  }, []);

  return (
    <div key={name} className="">
      <MsgInfo msg={nextMsg.value} />
      {widget.value}
      <ButtonsStep buttons={buttonsStep.value} />
    </div>
  );
}

export default StepForm;
