// Folio Occupancy Model

const getView = (props) => {
  const selectable = props?.selectable;
  const table_action = props ? props.table_action : ["edit", "delete"];

  let DictCtxView = {
    model: "hotel.folio.occupancy",
    form_action: ["edit"],
    table_action: table_action,
    domain: [],
    orderBy: [["occupancy_date", "ASC"]],
    limit: 100,
    autoSave: true,
    selectable: selectable,
    target: "folio",
    webfields: {
      occupancy_date: {
        type: "date",
        readOnly: true,
        required: true,
      },
      unit_price: {
        type: "numeric",
        required: true,
        decimalPlaces: 2,
      },
      unit_price_w_tax: {
        type: "numeric",
        readOnly: true,
        decimalPlaces: 2,
      },
      state: { type: "char", readOnly: true, translate: true },
      folio: {
        type: "many2one",
        model: "hotel.folio",
        readOnly: true,
      },
      charge: {
        type: "many2one",
        model: "hotel.folio.charge",
        readOnly: true,
      },
    },
    webtree: [
      { name: "occupancy_date", width: "20%" },
      { name: "unit_price", width: "20%" },
      { name: "unit_price_w_tax", width: "20%" },
      { name: "charge", width: "20%" },
      { name: "state", width: "20%" },
    ],
    webform: [
      { name: "occupancy_date" },
      { name: "folio" },
      {
        id: "prices",
        grid: [{ name: "unit_price" }, { name: "unit_price_w_tax" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "state" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
