import StepMoney from "./StepMoney";
import StepOpen from "./StepOpen";
import { useWizardStore } from "store/wizardStore";
import funcs from "tools/functions";
import proxy from "api/proxy";

const terminal = funcs.getCookie();
const model = "account.statement";

const MONEY = [
  100000, 50000, 20000, 10000, 5000, 2000, 1000, 500, 200, 100, 50,
];

function getDefaults() {
  const rows = new Map();
  for (const value of MONEY) {
    rows.set(value, {
      id: value,
      bill: value,
      quantity: 0,
    });
  }
  const res = {
    total: 0,
    money: new Map(rows),
    start_balance: 0,
  };
  return res;
}

async function getDevice() {
  const dom = [["code", "=", terminal.device_code]];
  const fields = ["id", "name"];
  const { data } = await proxy.search("sale.device", dom, fields);
  const device = data.length > 0 ? data[0] : null;
  return device;
}

async function onAcceptClose() {
  let next;
  let msgError;
  const method = "do_close_statement";
  const { store } = useWizardStore.getState();
  let money = [];
  if (store.money) {
    for (const rec of store.money.values()) {
      money.push([rec.id, rec.quantity]);
    }
  }

  const args = [
    {
      money: money,
      data: money,
      device: terminal.device_id,
    },
  ];
  const { error } = await proxy.methodCall({ model, method, args });
  if (error) {
    next = "error";
    msgError = error.error;
  } else {
    next = "success";
  }
  return { next, msgError };
}

async function onAcceptOpen() {
  let next;
  let msgError;
  const model = "account.statement";
  const method = "do_open_statement";
  const device = await getDevice();
  const { store } = useWizardStore.getState();
  if (!device) {
    // Add raise error message
    return { next: "error", msgError: "No existe un terminal" };
  }

  const args = [
    {
      device: device.id,
      start_balance: store.start_balance,
    },
  ];

  const { error } = await proxy.methodCall({ model, method, args });
  if (error) {
    next = "error";
    msgError = error.error;
  } else {
    next = "success";
  }
  console.log("llego aca----------------");

  return { next, msgError };
}

const openView = {
  start: {
    label: "open_start",
    Component: StepOpen,
    msg: "account.statement.open.msg_start",
    endButton: "cancel",
    buttons: [{ name: "button.ok", method: onAcceptOpen }],
  },
  success: {
    label: "open_success",
    msg: "account.statement.open.msg_success",
    endButton: "close",
  },
  error: {
    key: "open_error",
    endButton: "close",
  },
};

const closeView = {
  start: {
    label: "close_start",
    Component: StepMoney,
    endButton: "cancel",
    buttons: [{ name: "button.ok", method: onAcceptClose }],
    defaults: getDefaults(),
  },
  success: {
    label: "close_success",
    endButton: "close",
    msg: "account.statement.close.msg_success",
  },
  error: {
    label: "close_error",
    endButton: "close",
  },
};

function resetClose() {
  const { updateWizard } = useWizardStore.getState();
  const record = Object.assign({}, getDefaults());
  updateWizard(record);
}

export { openView, closeView, resetClose };
