// Funtions helpers
import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { TYPES_OEXT } from "tools/constants";
import dates from "./dates";

// const optionsMoney = { style: "currency", currency: "COP" };
// const numberFormat = new Intl.NumberFormat("es-CO", optionsMoney);

const recToTryton = (record) => {
  // This function convert record store in Js to record for Tryton DB
  // Example:
  const rec = record instanceof Map ? record : Object.entries(record);
  let _record = {};
  for (let [key, value] of rec) {
    if (key === "rec_name") {
      continue;
    }

    if (value instanceof Map) {
      let list = [];
      const _createMap = value.get("create"); // Return Map create
      const _writeMap = value.get("write"); // Return a Map write
      const _deleteArray = value.get("delete"); // Return a Array to delete
      if (_createMap || _writeMap || _deleteArray) {
        if (_createMap && _createMap.size > 0) {
          let to_create = _createMap.values();
          let list_recs = Array.from(to_create);
          for (let lrec of list_recs) {
            for (let [_field, _val] of Object.entries(lrec)) {
              if (_val instanceof Object && _val.id > 0) {
                lrec[_field] = _val.id;
              }
            }
          }
          list.push(["create", list_recs]);
        }
        if (_writeMap && _writeMap.size > 0) {
          for (const rec of _writeMap.values()) {
            const childRec = recToTryton(rec);
            delete childRec.id;
            list.push(["write", [rec.id], childRec]);
            // list.push([["write", [rec.id], Object.fromEntries(rec)]]);
          }
        }
        if (_deleteArray && _deleteArray.length > 0) {
          list.push(["delete", _deleteArray]);
        }
        if (list.length > 0) {
          _record[key] = list;
        }
      }
    } else if (value instanceof Object && value.id > 0) {
      _record[key] = value.id;
    } else if (value instanceof Object && value.id) {
      // Used for selection field
      _record[key] = value.id;
    } else if (value instanceof Date) {
      _record[key] = dates.fmtDatetime2Tryton(value);
    } else {
      _record[key] = value;
    }
  }
  return _record;
};

const recordToJs = (record, fields) => {
  // This function convert record Tryton in record Js for form/modal views
  let _record = { ...record };
  for (const [field, attr] of Object.entries(fields)) {
    let _value = record[field];
    if (attr.type === "selection") {
      let nameStr = _value;
      for (const opt of attr.options) {
        if (opt.id === _value) {
          nameStr = opt.name;
          break;
        }
      }
      if (_value) {
        _value = { id: _value, name: nameStr };
      }
    } else if (attr.type === "one2many") {
      const _field = `${field}.`;
      _value = record[_field];
      if (_value) {
        _value = recsToMap(_value);
      }
    } else if (attr.type === "many2one") {
      const _field = `${field}.`;
      _value = record[_field];
      const subValue = prepareRecord(_value);
      _record[field] = subValue;
      // delete _record[_field];
    } else if (attr.type === "date") {
      _value = dates.getTrytonDate2Js(_value, true);
    } else if (attr.type === "datetime") {
      if (_value) {
        _value = dates.getTrytonDateTime2Js(_value, true);
      } else {
        _value = "";
      }
    }
    _record[field] = _value;
  }
  return _record;
};

const recs2Map = (records) => {
  let res = new Map();
  records.forEach((rec) => {
    let data = {
      key: rec.id,
      id: rec.id,
      text: rec.name || rec.rec_name,
      value: rec.id,
      record: {
        id: rec.id,
        name: rec.name,
      },
    };
    res.set(rec.id, data);
  });
  return res;
};

const recs2Selection = (records) => {
  return records.map((rec) => {
    return {
      key: rec.id,
      id: rec.id,
      text: rec.name || rec.rec_name,
      value: rec.id,
      record: {
        id: rec.id,
        name: rec.name,
      },
    };
  });
};

const recs2Combobox = (records) => {
  return records.map((rec) => {
    return {
      key: rec.id,
      id: rec.id,
      name: rec.name || rec.rec_name,
      value: rec.id,
    };
  });
};

const recsToMap = (records) => {
  let _map = new Map();
  for (let rec of records) {
    rec = prepareRecord(rec);
    _map.set(rec.id, rec);
  }
  return _map;
};

const prepareRecord = (record) => {
  if (!record) {
    return {};
  }
  for (let [key, value] of Object.entries(record)) {
    if (Array.isArray(value)) {
      value = recsToMap(value);
    }
    if (key.includes(".")) {
      const nkey = key.replace(".", "");
      const subValue = prepareRecord(value);
      record[nkey] = subValue;
      delete record[key];
    }
  }
  return record;
};

const isVisible = (visible, record, name) => {
  let res = true;
  if (visible && record) {
    if (typeof visible === "function") {
      res = visible(name, record);
    } else if (Array.isArray(visible)) {
      for (const option of visible) {
        if (typeof option === "string") {
          const value = record[option];
          res = value ? true : false;
        } else {
          for (const [ref, refValues] of Object.entries(option)) {
            res = refValues.includes(record[ref]);
            if (res) {
              break;
            }
          }
        }
      }
    } else if (typeof visible === "boolean") {
      res = visible;
    }
  }
  return res;
};

const getInvisibles = (record, webfields) => {
  let invisibles = [];
  for (const [field, attrs] of Object.entries(webfields)) {
    if (attrs.visible) {
      let res = isVisible(attrs.visible, record, field);
      if (!res) {
        invisibles.push(field);
      }
    }
  }
  return invisibles;
};

const fmtMoney = (value) => {
  return value.toLocaleString();
};

const fmtMoneyLocal = (value, numDecimals = 2, local = "en-US") => {
  const formatter = Intl.NumberFormat(local, {
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  });

  let _value;

  if (typeof value === "string") {
    const parsedValue = parseFloat(value.replace(",", "."));
    _value = formatter.format(parsedValue);
  } else {
    _value = formatter.format(value);
  }
  return _value;
};

/**
 *
 * @param {*} props -
 * @param {Array} selectables -
 * @returns Component Map of values
 */
const selectionToDropdown = (selectables) => {
  const values = new Map();
  selectables.forEach((item) => {
    const value = item[0];
    const name = item[1];
    const text = <FM id={name} name={name} />;
    values.set(value, {
      key: value,
      text: text,
      value: value,
    });
  });
  return values;
};

async function getDefaults(id, ctxView) {
  const _fields = ctxView["webfields"];
  let toActive = { id: id };
  let toStore = { id: id };
  for (const [k, v] of Object.entries(_fields)) {
    if (typeof v.default === "function") {
      let value = await v.default();
      if (v.type === "date") {
        value = dates.fmtDate2Tryton(value);
      }
      toActive[k] = value;
      toStore[k] = value;
    } else if (v.default) {
      toActive[k] = v.default;
      toStore[k] = v.default;
    }
    if (v.type === "one2many") {
      toActive[k] = new Map();
      toStore[k] = new Map();
      let toStoreField = toStore[k];
      toStoreField.set("create", new Map());
      toStoreField.set("write", new Map());
      toStoreField.set("delete", []);
    }
  }
  return [toStore, toActive];
}

function base64ToBlobUrl(base64, extension) {
  /*
  This convert base64 data to array buffer and return blob Url
  */
  var binaryString = window.atob(base64);
  var binaryLen = binaryString.length;
  var bytes = new Uint8Array(binaryLen);
  for (var i = 0; i < binaryLen; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  const _type = TYPES_OEXT[extension];
  const blob = new Blob([bytes], { type: _type });
  return URL.createObjectURL(blob);
}

function createReportLink(reportUrl, nameFile) {
  var reportLink = document.createElement("a");
  document.body.appendChild(reportLink);
  reportLink.style = "display: none";
  reportLink.href = reportUrl;
  reportLink.download = nameFile;
  reportLink.click();
  URL.revokeObjectURL(reportUrl);
}

const excludeFields = [
  "button",
  "button_wizard",
  "icon",
  "divider",
  "multiselection",
  "title",
  "p",
  "custom",
  "button_custom_modal",
  "one2many",
  "custom_fragment",
];

function getViewFields(ctxView, typeView) {
  let fieldsToApi = [];
  let viewTarget = [];
  let _typeView = typeView || ctxView.defaultView;
  if (!_typeView) {
    _typeView = "list";
  }

  if (typeView === "list") {
    viewTarget = ctxView.webtree;
  } else if (_typeView === "form") {
    viewTarget = ctxView.webform;
  } else if (_typeView === "subcards") {
    viewTarget = ctxView.webcards;
  } else if (_typeView === "cards") {
    let cardTargets = [];
    for (const section of Object.values(ctxView.webcards)) {
      for (const ele of section) {
        let arrayEle;
        if (ele.col) {
          arrayEle = ele.col;
        } else if (ele.row) {
          arrayEle = ele.row;
        }
        for (const row of arrayEle) {
          cardTargets.push(row);
        }
      }
    }
    viewTarget = [...viewTarget, ...cardTargets];
  }

  for (const fieldObj of viewTarget) {
    if (fieldObj.grid) {
      viewTarget = [...viewTarget, ...fieldObj.grid];
    }
    if (fieldObj.children) {
      viewTarget = [...viewTarget, ...fieldObj.children];
    }
    if (fieldObj.row) {
      viewTarget = [...viewTarget, ...fieldObj.row];
    }
    if (fieldObj.tabs) {
      let tabsFields = [];
      for (const values of Object.values(fieldObj.tabs)) {
        for (const _field of values) {
          tabsFields.push(_field);
        }
      }
      viewTarget = [...viewTarget, ...tabsFields];
    }
  }

  for (const fieldObj of viewTarget) {
    const field = fieldObj.name;
    const dataField = ctxView.webfields[field];
    if (!dataField) continue;

    if (dataField && dataField.depends) {
      fieldsToApi.push(...dataField.depends);
    }

    if (dataField && dataField.function && dataField.attrs) {
      for (const f of dataField.attrs) {
        fieldsToApi.push(f);
      }
      continue;
    }

    if (dataField.function && dataField.search !== true) {
      continue;
    }
    // if (dataField.type === "button_check") {
    //   continue;
    // }

    if (!excludeFields.includes(dataField.type) || dataField.search) {
      if (dataField.search !== false) {
        fieldsToApi.push(field);
      }
    }
    if (dataField.type === "many2one") {
      let recName = "rec_name";
      if (dataField.recName) {
        recName = dataField.recName;
      }
      fieldsToApi.push(`${field}.${recName}`);
      if (dataField.fieldsNames) {
        for (const fname of dataField.fieldsNames) {
          fieldsToApi.push(`${field}.${fname}`);
        }
      }
      if (dataField.attrs) {
        for (const f of dataField.attrs) {
          fieldsToApi.push(field + "." + f);
        }
      }
    } else if (dataField.type === "one2many") {
      const subfieldsList = getViewFields(dataField.ctxView, "list");
      const subfieldsForm = [];
      const subfields = [...subfieldsList, ...subfieldsForm];
      for (const sub of subfields) {
        const nameNested = `${field}.${sub}`;
        fieldsToApi.push(nameNested);
      }
    }
  }
  return fieldsToApi;
}

function getRequired(fields, record) {
  let required = [];
  // const nested_fields = ["many2one", "one2many"];
  for (let [k, v] of Object.entries(fields)) {
    if (typeof v.required === "function") {
      const req = v.required(record);
      if (req) {
        required.push(k);
      }
    } else if (v.required) {
      required.push(k);
    }
  }
  return required;
}

function cloneMap(value) {
  return new Map(JSON.parse(JSON.stringify(Array.from(value))));
}

function getCookie() {
  let res = {};
  document.cookie.split(";").forEach((item) => {
    if (item) {
      const [key, value] = item.split("=");
      res[key.trim()] = value.trim();
    }
  });
  return res;
}

const tools = {
  fmtMoney,
  fmtMoneyLocal,
  recToTryton,
  recordToJs,
  recsToMap,
  prepareRecord,
  isVisible,
  recs2Selection,
  recs2Map,
  selectionToDropdown,
  getDefaults,
  base64ToBlobUrl,
  createReportLink,
  getInvisibles,
  recs2Combobox,
  getViewFields,
  getRequired,
  cloneMap,
  getCookie,
};

export default tools;
