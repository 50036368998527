import proxy from "api/proxy";
import { validateForm } from "tools/form";

export const storeConvert = (store) => {
  // Convert Map values into Array
  for (let [key, val] of Object.entries(store)) {
    if (val instanceof Map) {
      let childRecords = [];
      for (let rec of val.values()) {
        for (let [k, v] of Object.entries(rec)) {
          if (v && v.id) {
            rec[k] = v.id;
          }
        }
        childRecords.push(rec);
      }
      store[key] = childRecords;
    }
  }
  return store;
};

export const clientRemove = async (model, recId) => {
  const toRemove = {
    ids: [recId],
    model: model,
  };
  const { data } = await proxy.remove(toRemove);
  return data;
};

export const clientUpdate = async (
  model,
  storeData,
  storeRequired,
  record,
  fieldsNames,
) => {
  let res = {
    record: {},
    msg: "board.record_saved",
    type: "msgInfo",
  };
  let _storeData;
  if (record) {
    let store = {};
    for (const sr of storeRequired) {
      store[sr] = record[sr];
    }
    _storeData = { ...store, ...storeData };
  } else {
    _storeData = storeData;
  }

  let isValid = await validateForm("update", _storeData, storeRequired);
  if (isValid === "ok") {
    const rec_id = storeData.id;
    delete storeData.id;
    let storeJson = {};
    for (const [k, v] of Object.entries(storeData)) {
      if (v instanceof Map) {
        storeJson[k] = Array.from(v.values());
      } else {
        storeJson[k] = v;
      }
    }
    const data = {
      id: rec_id,
      model: model,
      record_data: storeJson,
      fields: fieldsNames,
    };

    const record = await proxy.save(data);
    if (record) {
      res["record"] = record;
      res["id"] = record.id;
    }
  } else {
    res["msg"] = isValid;
    res["type"] = "msgWarning";
  }
  return res;
};

export const clientAdd = async (
  model,
  storeData,
  storeRequired,
  fieldsNames,
) => {
  let res = {
    record: {},
    msg: "board.record_created",
    type: "msgInfo",
  };

  const isValid = await validateForm("create", storeData, storeRequired);
  if (isValid === "ok") {
    const record = await proxy.create(model, storeData, fieldsNames);
    res["record"] = record;
    res["id"] = record.id;
  } else {
    res["msg"] = isValid;
    res["type"] = "msgWarning";
  }

  return res;
};

export async function searchSelection(model, domain, fields, limit) {
  if (!fields) {
    fields = ["id", "rec_name"];
  }
  let items = new Map();
  const records = await proxy.search(model, domain, fields, limit);
  for (let r of records) {
    items.set(r.id, {
      key: r.id,
      value: r.id,
      text: r.rec_name || r.name,
      record: r,
    });
  }
  return items;
}

export const getRequired = (fields, record) => {
  let required = [];
  // const nested_fields = ["many2one", "one2many"];
  for (let [k, v] of Object.entries(fields)) {
    if (typeof v.required === "function") {
      const req = v.required(record);
      if (req) {
        required.push(k);
      }
    } else if (v.required) {
      required.push(k);
    }
  }
  return required;
};

export const factoryFields = (ctxView) => {
  let fieldsNames = [];
  for (let [k, v] of Object.entries(ctxView["webfields"])) {
    if (v.type === "one2many") {
      const subfields = Object.keys(v.ctxView.webfields);
      for (let f of subfields) {
        fieldsNames = fieldsNames.concat([k + "." + f]);
      }
    } else if (v.type !== "button") {
      fieldsNames.push(k);
    }
  }
  return fieldsNames;
};

export async function triggerMethod(model, method, record, required) {
  let res;
  let isValid = "ok";
  if (required && Object.keys(record).length > 1) {
    isValid = await validateForm("create", record, required);
  }
  if (isValid === "ok") {
    // const storeRec = await storeConvert(record);
    const ids = [record.id];
    const args = { model, method, ids };
    res = await proxy.buttonMethod(args);
  } else {
    res = {
      record: null,
      type: "warning",
      open_modal: true,
      msg: isValid,
    };
  }
  return res;
}
