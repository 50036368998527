// English translation

const en = {
  "account.statement.open": "Open",
  "account.statement.close": "Close",
  "account.statement.date": "Date",
  "account.statement.start_balance": "Start Balance",
  "account.statement.line": "Statement Line",
  "account.statement.line.amount": "Amount",
  "account.statement.line.statement": "Statement",
  "account.statement.line.date": "Date",
  "account.statement.line.number": "Number",
  "account.statement.line.party": "Party",
  "account.statement.open.msg_start":
    "Please, enter start balance for open account statement...",
  "account.statement.open.msg_success":
    "Estados de cuenta abiertos exitosamente!",
  "account.statement.open.msg_error": "Missing the terminal configuration!",
  "account.statement.close.msg_start":
    "Do you want close account statements for your terminal?",
  "account.statement.close.msg_success": "Account statements closed success!",
  "account.statement.close.msg_error": "Missing the terminal configuration!",
  "account.statement.create_uid": "Create User",
};

export default en;
