import React from "react";

import StdButton from "components/Buttons/StdButton";

const style =
  "flex sticky bottom-0 w-full justify-end my-4 4292md:flex-row gap-x-4";

function ButtonsGroup({ buttons, onClose, onAccept }) {
  const buttonsList = {
    cancel: (
      <StdButton
        key="cancel"
        color="rose"
        size="w-full sm:w-1/2 md:w-1/3"
        onClick={buttons.cancel}
        content="button.cancel"
      />
    ),
    ok: (
      <StdButton
        key="ok"
        color="blue"
        size="w-full sm:w-1/2 md:w-1/3"
        onClick={buttons.ok}
        content="button.ok"
      />
    ),
    close: (
      <StdButton
        key="close"
        content="close"
        color="black"
        size="w-full sm:w-1/2 md:w-1/3"
        onClick={buttons.close}
        content="button.close"
      />
    ),
  };

  const _buttons = [];
  for (const btn of Object.keys(buttons)) {
    const ntbn = buttonsList[btn];
    _buttons.push(ntbn);
  }
  return <div className={style}>{_buttons}</div>;
}

export default ButtonsGroup;
