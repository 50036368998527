import ModelFolio from "./Folio";
import WizardAddRoom from "./WizardAddRoom";
import WizardPayment from "./WizardPayment";
import WizardBill from "./WizardBill";
import modelWizardPayment from "./ModelWizardPayment";
import StatementLine from "views/AccountStatement/StatementLine";
import Invoice from "views/Invoice/Invoice";
import party from "./Party";
import imgDespegar from "assets/apps/despegar.png";
import imgBooking from "assets/apps/booking.png";
import imgExpedia from "assets/apps/expedia.png";
import imgHouse from "assets/apps/house.png";
import dates from "tools/dates";
import WizardUpdateTaxes from "./WizardUpdateTaxes";
import store from "store";
import proxy from "api/proxy";

const stateColors = {
  offer: "amber",
  confirmed: "lime",
  cancelled: "rose",
  finished: "sky",
  blocked: "gray",
};

const MEDIA = [
  { id: "phone", name: "phone" },
  { id: "email", name: "email" },
  { id: "chat", name: "chat" },
  { id: "walking", name: "walking" },
  { id: "web", name: "web" },
  { id: "channel_manager", name: "channel_manager" },
  { id: "ota", name: "ota" },
];

const RESPONSIBLE = [
  { id: "holder", name: "holder" },
  { id: "holder_guest", name: "holder_guest" },
  { id: "guest", name: "guest" },
];

const CHANNEL_PAYMENT_METHOD = [
  { id: "at_destination", name: "at_destination" },
  { id: "ota_collect", name: "ota_collect" },
];

const iconOTA = {
  house: imgHouse,
  booking: imgBooking,
  despegar: imgDespegar,
  expedia: imgExpedia,
};

function getOTAVisible(fieldName, record) {
  let res = record.channel?.kind === "ota" ?? false;
  return res;
}

function getOTARequired(record) {
  let res = record.channel?.kind === "ota" ?? false;
  return res;
}

function getImageChannel(record) {
  let image = imgHouse;
  if (record["channel."] && record["channel."].code) {
    const code = record["channel."].code;
    image = iconOTA[code];
  }
  return image;
}

const visibleConfirm = (name, record) => {
  let res = false;
  if (
    record &&
    record.state === "offer" &&
    record.lines &&
    record.lines.size > 0
  ) {
    res = true;
  }
  return res;
};
function visibleCancel(name, record) {
  if (record && record.lines && record.lines.size > 0) {
    return true;
  }
  return false;
}
function visibleBill(name, record) {
  if (
    record &&
    record.state === "confirmed" &&
    record.lines &&
    record.lines.size > 0
  ) {
    return true;
  }
  return false;
}

function onChangeChannel(record) {
  if (record.channel) {
    record.rate_plan = record.channel.rate_plan;
    record.price_list = null;
  }
  return record;
}

function visibleAddPayment(name, record) {
  return record.number ? true : false;
}

function searchPriceList(value, record) {
  let res = null;
  if (record.channel && record.channel?.rate_plan) {
    res = [["id", "in", record?.channel?.["rate_plan."]?.price_lists]];
  }
  return res;
}

async function withChangePriceList(record) {
  if (record.price_list && record.price_list?.meal_plan) {
    const { data: mealPlan } = await proxy.search(
      "hotel.meal_plan",
      [["id", "=", record?.price_list?.meal_plan]],
      ["name"],
    );
    if (mealPlan.length > 0) {
      record.meal_plan = mealPlan[0];
      return record;
    }
  }
  return record;
}

const getFilters = () => {
  return {
    offer: [
      ["state", "=", "offer"],
      // ["booking_date", ">=", yesterday],
      // ["shop", "=", session.shop],
    ],
    confirmed: [
      ["state", "=", "confirmed"],
      // ["booking_date", ">=", yesterday],
      // ["shop", "=", session.shop],
    ],
  };
};

const getView = () => {
  let DictCtxView = {
    model: "hotel.booking",
    form_action: ["edit", "add"], // options: ['save', 'delete']
    table_action: ["edit", "add"], // options: ['open', 'delete', 'edit', 'add', "info"]
    activeSearch: true,
    filters: getFilters,
    orderBy: [["number", "DESC"]],
    limit: 30,
    selectable: null, // Options for table rows: null, multi, one
    // domain: [["lines.arrival_date", "=", "2023-06-27"]], // Options: null or valid domain
    // domain: [],
    // pagination: [],
    reports: [
      {
        name: "hotel.booking",
        description: "Detalle de Reserva Confirmada",
        send_print: true,
        send_email: "send_email",
      },
      {
        name: "hotel.booking_statement",
        description: "Estado de Cuenta",
        send_print: true,
        send_email: "send_email",
      },
    ],
    wizards: [
      {
        name: "hotel.booking.update_taxes",
        Component: WizardUpdateTaxes,
        description: "Detalle de Reserva Confirmada",
      },
      // {
      //   name: "hotel.intent_payment",
      //   description: "Detalle de Reserva Confirmada",
      // },
    ],
    tags: {
      state: stateColors,
    },
    sources: {
      image: iconOTA,
    },
    webfields: {
      number: {
        type: "char",
        readOnly: true,
        searchable: true,
      },
      contact: {
        type: "char",
        searchable: true,
        required: true,
      },
      channel: {
        type: "many2one",
        model: "hotel.channel",
        searchable: true,
        getImage: getImageChannel,
        withChange: onChangeChannel,
        attrs: ["code", "rate_plan", "kind", "rate_plan.price_lists"],
        dependents: ["ota_booking_code"],
        required: true,
      },
      agent: {
        type: "many2one",
        model: "commission.agent",
        searchable: true,
        // attrs: ["code"],
      },
      ota_booking_code: {
        type: "char",
        searchable: true,
        required: getOTARequired,
        visible: getOTAVisible,
      },
      bill_to: {
        type: "selection",
        options: RESPONSIBLE,
        default: { id: "holder", name: "holder" },
        translate: true,
        required: true,
      },
      party: {
        type: "many2one",
        model: "party.party",
        searchable: true,
        ctxView: party.ctxView(),
        // attrs: ["account_receivable"],
      },
      lines: {
        type: "one2many",
        model: "hotel.folio",
        ctxView: ModelFolio.ctxView(),
      },
      environment: {
        type: "many2one",
        model: "sale.shop",
      },
      company: {
        type: "many2one",
        model: "company.rec_name",
      },
      booking_date: {
        type: "datetime",
        readOnly: true,
        default: () => dates.getNow(),
      },
      total_amount: {
        type: "numeric",
        readOnly: true,
      },
      tax_amount: {
        type: "numeric",
        readOnly: true,
      },
      total_advances: {
        type: "numeric",
        readOnly: true,
      },
      pending_to_pay: {
        type: "numeric",
        readOnly: true,
      },
      media: {
        type: "selection",
        options: MEDIA,
        translate: true,
      },
      group: {
        type: "boolean",
        translate: true,
      },
      corporative: {
        type: "boolean",
        translate: true,
      },
      meal_plan: {
        type: "many2one",
        model: "hotel.meal_plan",
        searchable: true,
        required: true,
      },
      state: {
        type: "char",
        translate: true,
        default: "offer",
        readOnly: true,
        tags: stateColors,
      },
      link_web_checkin: {
        type: "char",
        link: true,
        readOnly: true,
      },
      complimentary: {
        type: "boolean",
        translate: true,
      },
      pax: {
        type: "integer",
        readOnly: true,
      },
      notes: {
        type: "text",
      },
      channel_payment_method: {
        type: "selection",
        translate: true,
        options: CHANNEL_PAYMENT_METHOD,
      },
      payment_term: {
        type: "many2one",
        model: "account.invoice.payment_term",
        default: () => store.get("hotelConfig")["payment_term."],
      },
      payments: {
        type: "one2many",
        model: "account.statement.line",
        ctxView: StatementLine.ctxView(),
      },
      invoices: {
        type: "one2many",
        model: "account.invoice",
        ctxView: Invoice.ctxView(),
      },
      // notifications: {
      //   type: "one2many",
      //   model: "account.invoice",
      //   ctxView: Invoice.ctxView(),
      // },
      price_list: {
        type: "many2one",
        recSearch: searchPriceList,
        withChange: withChangePriceList,
        model: "product.price_list",
        required: true,
        attrs: ["meal_plan"],
      },
      rate_plan: {
        type: "many2one",
        model: "hotel.rate_plan",
        required: true,
        attrs: ["name", "price_lists"],
      },
      lead_origin: {
        type: "many2one",
        recSearch: () => [],
        model: "crm.lead_origin",
      },
      confirm: {
        type: "button",
        button_method: "confirm",
        visible: visibleConfirm,
        onSuccessMsg: "Confirmacion exitosa!",
        color: "blue",
        style: "w-full",
      },
      cancelled: {
        type: "button",
        button_method: "cancel",
        visible: visibleCancel,
        onSuccessMsg: "Cancelado exitosa!",
        color: "rose",
        style: "w-full",
      },
      block: {
        type: "button",
        button_method: "block",
        visible: visibleCancel,
        onSuccessMsg: "Bloqueo exitosa!",
        color: "black",
        style: "w-full",
      },
      no_show: {
        type: "button",
        button_method: "no_show",
        visible: visibleCancel,
        onSuccessMsg: "No mostrado exitosa!",
        color: "slate",
        style: "w-full",
      },
      add_rooms: {
        type: "button_wizard",
        Component: WizardAddRoom,
        color: "sky",
        icon: "fi fi-rr-add",
      },
      // apply_taxes_exception: {
      taxes_exception: {
        type: "button_check",
        button_method: "apply_taxes_exception",
        readOnly: {
          state: ["offer", "finished", "cancelled"],
        },
      },
      add_payment: {
        type: "button_wizard",
        Component: WizardPayment,
        ctxView: modelWizardPayment.ctxView(),
        color: "lime",
        icon: "fi fi-rr-add",
        visible: visibleAddPayment,
      },
      bill: {
        type: "button_wizard",
        Component: WizardBill,
        visible: visibleBill,
        color: "amber",
        icon: "fi fi-rs-receipt",
      },
    },
    webtree: [
      { name: "number", width: "7%" },
      { name: "channel", widget: "image" },
      { name: "ota_booking_code", width: "7%" },
      { name: "party", width: "15%" },
      { name: "contact", width: "10%" },
      { name: "agent", width: "15%" },
      { name: "total_amount", width: "10%" },
      { name: "booking_date", width: "10%", formatString: "YYYY-MM-DD" },
      { name: "group", width: "7%" },
      {
        name: "state",
        width: "10%",
        widget: "badge",
      },
    ],
    webform: [
      { name: "party", widget: "search_add_new" },
      {
        id: "infoDate",
        grid: [{ name: "number" }, { name: "booking_date" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "contact",
        grid: [{ name: "contact" }, { name: "channel" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "price_list",
        grid: [{ name: "price_list" }, { name: "meal_plan" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "info_payments",
        grid: [{ name: "bill_to" }, { name: "payment_term" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "info_kind",
        grid: [{ name: "add_rooms" }, { name: "add_payment" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "lines", widget: "cards", style: "w-full" },
      {
        id: "payment",
        grid: [{ name: "total_amount" }, { name: "total_advances" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "state",
        grid: [{ name: "pending_to_pay" }, { name: "state" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "taxes",
        grid: [{ name: "taxes_exception" }, { name: "pax" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "add_info",
        grid: [
          { name: "lead_origin" },
          { name: "ota_booking_code" },
          { name: "link_web_checkin" },
        ],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "crm",
        grid: [{ name: "agent" }],
        size: [1, 2],
        span: "col-span-1",
        collapse: true,
      },
      // {
      //   id: "marketing",
      //   grid: [{ name: "lead_origin" }, { name: "media" }],
      //   size: [1, 2],
      //   span: "col-span-1",
      //   collapse: true,
      // },
      {
        id: "segmentation",
        grid: [
          { name: "group" },
          { name: "corporative" },
          { name: "complimentary" },
        ],
        size: [1, 3],
        span: "col-span-1",
        collapse: true,
      },
      { name: "notes" },
      {
        id: "tabs",
        tabs: {
          advances_payments: [{ name: "payments" }],
          invoices: [{ name: "invoices" }],
          notifications: [{ name: "notifications" }],
        },
        size: [1, 3],
        span: "col-span-4",
      },
      {
        id: "buttons",
        grid: [
          { name: "no_show" },
          { name: "block" },
          { name: "cancelled" },
          { name: "confirm" },
          { name: "bill" },
        ],
        size: [1, 4],
        span: "col-span-2",
        collapse: true,
      },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
