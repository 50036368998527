import React from "react";
import { FormattedMessage as FM } from "react-intl";

import Tooltip from "components/Tools/Tooltip";
import { ICON_COLORS } from "components/Constants/constants";
import { classNames } from "tools/ui";

const classes =
  "flex my-auto h-10 cursor-pointer rounded-xl py-1 px-2 text-xl hover:opacity-90 disabled:opacity-50 justify-center active:scale-95";

function ButtonIcon(props) {
  const { field, attrs, data, record } = props;
  const { name, readOnly, color, icon, label, model } = field;
  const { level, ctxView } = attrs;

  console.log(" ButtonIcon ....", data);
  function handleClick(e) {
    e.preventDefault();
    // onClick(e, name);
  }

  let _size = "w-12";
  let style = "";
  // if (size) {
  //   _size = size;
  // }
  const [bgColor, iconColor] = ICON_COLORS[color];
  const _disabled = readOnly;
  console.log(" ==> ", bgColor, iconColor);
  return (
    <div
      onClick={(e) => handleClick(e)}
      className={classNames(style, _size, bgColor, classes)}
      disabled={_disabled}
    >
      <span className="flex my-auto text-xs font-medium ml-auto">
        <FM id={label} />
      </span>
      <i
        key={name}
        className={classNames("flex text-2xl my-auto", icon, iconColor)}
      />
      {name && <Tooltip tooltip={name} />}
    </div>
  );
}

export default ButtonIcon;
