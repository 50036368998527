import React, { Fragment, useEffect, useState } from "react";

import Form from "components/MeForm/Form";
import proxy from "api/proxy";
import { signal } from "@preact/signals-react";
import useMainStore from "store/mainStore";
import { useWizardStore } from "store/wizardStore";
import store from "store";

const ctxView = {
  model: "hotel.booking.update_taxes.start",
  selectable: false, // options: multi - one - false
  limit: null,
  form_action: ["open"],
  table_action: ["open"],
  webfields: {
    taxes: { type: "selection", options: [] },
    accept: {
      type: "button",
      method: "handleAccept",
      onSuccessMsg: "Taxes updated!",
      color: "blue",
    },
  },
  webform: [{ name: "taxes" }, { name: "accept" }],
  webtree: [],
};

const charges = signal([]);

function WizardUpdateTaxes(props) {
  const { record } = useMainStore();
  const { record: recordWirzad } = useWizardStore();

  let [ctxViewUpdate, setCtxViewUpdate] = useState(ctxView);

  const searchTaxesOptions = async () => {
    const hotelConfig = store.get("hotelConfig");
    const taxes = hotelConfig["taxes_auth."];
    return taxes.map((tax) => {
      return { id: tax.id, name: tax.rec_name };
    });
  };

  const searchCharges = async () => {
    const { data } = await proxy.search(
      "hotel.folio.charge",
      [
        ["folio.booking", "=", record.id],
        ["invoice_line", "=", null],
      ],
      ["id"],
    );
    if (data) {
      charges.value = data.map((charge) => charge.id);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const options = await searchTaxesOptions();
      setCtxViewUpdate({
        ...ctxViewUpdate,
        webfields: {
          ...ctxViewUpdate.webfields,
          taxes: { type: "selection", options: options },
        },
      });
      await searchCharges();
    }
    fetchData();
  }, []);

  const handleAccept = async () => {
    if (recordWirzad.taxes && charges.value) {
      const args = {
        model: "hotel.folio.charge",
        ids: charges.value,
        values: { taxes: [["add", [recordWirzad.taxes.id]]] },
      };

      const { error } = await proxy.save(args);
      console.log("error", error);
    }
    props.onClose(true);
  };

  // const onClose = () => {};

  return (
    <Fragment>
      <Form
        ctxView={ctxViewUpdate}
        record={record}
        // onClose={onClose}
        handleButton={handleAccept}
        level="wizard"
      />
    </Fragment>
  );
}

export default WizardUpdateTaxes;
