import React, { Fragment } from "react";
import { Tab } from "@headlessui/react";
import proxy from "api/proxy";
import StdButton from "components/Buttons/StdButton";
import SelectionField from "components/MeForm/SelectionField";
import NumericField from "components/MeForm/NumericField";
import mainStore from "store/mainStore";
import { signal } from "@preact/signals-react";
import ModelSale from "../models/ModelSale";
import ProductDiscount from "./ProductDiscount";

const level = "wizard";

const ATTRS = {
  level: level,
  ctxView: { model: "sale.discount.line" },
};

const handleChanged = (name, value) => {
  const _value = { ...discount };
  _value[name] = value;
  discount.value = _value;
};

const fields = {
  typeDiscount: {
    name: "type",
    label: "sale.sale.type_discount",
    type: "char",
    required: true,
    withChange: handleChanged,
    options: [
      { id: "percentage", name: "Porcentaje" },
      { id: "fixed", name: "Valor fijo" },
    ],
  },
  discountValueField: {
    name: "discount_value",
    label: "sale.sale.discount_amount",
    withChange: handleChanged,
    type: "char",
    required: true,
  },
};

const type = signal("");
const discount_value = signal("");
const discount = signal({});

function ProductDiscountItem({ record: _record, onClose }) {
  const { record, updateRecord, setRecord, save } = mainStore();

  const ctxViewSale = ModelSale.ctxView();

  const addDiscount = async () => {
    let _currentLine = { ..._record };
    let _discount = parseFloat(discount_value.value.replace(/,/g, ""));

    let amount = 0;
    if (type.value.id == "percentage") {
      if (_discount > 100) {
        alert("Este numero de porcentaje no es valido");
        return false;
      }
      let _discountTotal = 100 - _discount;
      amount = (_discountTotal * _currentLine.base_price) / 100;
    } else if (type.value.id == "fixed") {
      if (_currentLine.base_price * _currentLine.quantity < _discount) {
        alert("El precio de descuento es mayor para esta línea de producto");
        return false;
      }
      let price_discount =
        _currentLine.base_price - _discount / _currentLine.quantity;
      let balanced_price = _currentLine.base_price - price_discount;
      amount = _currentLine.base_price - balanced_price;
    }
    if (amount == 0) {
      amount = 1;
    }
    // let lines = {
    //   id: _currentLine.id,
    //   unit_price: Number(amount).toFixed(2).toString(),
    //   quantity: _currentLine.quantity,
    //   auth_discount: discount.value.id,
    // };
    // const _storeRecord = { ...store };

    // _storeRecord.lines = [lines];
    // // await proxy.save({
    // //   model: "sale.sale",
    // //   storeRec: _storeRecord,
    // // });

    await proxy.saveQuery({
      model: "sale.line",
      storeRec: {
        id: _currentLine.id,
        unit_price: Number(amount).toFixed(2).toString(),
        quantity: _currentLine.quantity,
        auth_discount: discount.value.id,
      },
    });
    await save();
    setRecord(record.id, "sale.sale", ctxViewSale);
    type.value = "";
    discount_value.value = "";
    onClose();
  };

  const ContentManualDiscount = () => {
    return (
      <div className="px-4 sm:px-6 lg:px-8 py-20 md:col-span-2 bg-blue">
        <h2 className="text-lg font-bold">
          {_record?.product.name} | {_record?.amount}
        </h2>
        <p>Agrega descuentos de porcentaje o fijo para este producto.</p>
        <div className="flex justify-center space-x-4">
          <SelectionField
            field={fields.typeDiscount}
            data={type}
            attrs={ATTRS}
          />

          <NumericField
            field={fields.discountValueField}
            data={discount_value}
            attrs={ATTRS}
          />
        </div>
        <div className="mt-5">
          <StdButton
            content={"sale.line.calculate_discount"}
            onClick={addDiscount}
            color="bluePresik"
            style="mx-auto min-w-[200px] uppercase"
          />
        </div>
      </div>
    );
  };

  const handleCurrentDiscount = (value) => {
    if (value) {
      type.value = { id: "percentage", name: "Porcentaje" };
      discount_value.value = String(value.discount);
      discount.value = value;
      console.log(value, "this is value");

      addDiscount();
    }
  };

  return (
    <Fragment>
      {/* Tabs */}
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-presik p-1">
          <Tab
            className={({ selected }) =>
              `w-full py-2.5 text-sm font-medium leading-5 text-blue-presik rounded-lg ${
                selected
                  ? "bg-white shadow"
                  : "text-white hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Descuento Autorizados
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full py-2.5 text-sm font-medium leading-5 text-blue-presik rounded-lg ${
                selected
                  ? "bg-white shadow"
                  : "text-white hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Descuento Manual
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel className="bg-white rounded-xl p-3">
            <ProductDiscount
              handleCurrent={handleCurrentDiscount}
              line={record}
              onClose={() => ""}
              grid="grid-cols-2 md:grid-cols-3"
            />
          </Tab.Panel>
          <Tab.Panel className="bg-white rounded-xl p-3">
            <ContentManualDiscount />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </Fragment>
  );
}

export default ProductDiscountItem;
