import React from "react";
import { signal } from "@preact/signals-react";

import HomeApp from "./components/HomeApp";
import CodeVerification from "./components/CodeVerification";
import FaceRecognition from "./components/FaceRecognition";

const currentStep = signal("step1");

function AttendanceControl() {
  const handleView = (view) => {
    if (view) {
      currentStep.value = view;
    }
  };

  const steps = {
    step1: <HomeApp handleView={handleView} />,
    step2: <CodeVerification handleView={handleView} />,
    step3: <FaceRecognition handleView={handleView} />,
  };

  return (
    <div className="px-8 w-full flex overflow-x-hidden relative flex-col items-center justify-start bg-gradient-to-br from-gray-800 via-gray-900 to-gray-900 mx-auto">
      {steps[currentStep.value]}
    </div>
  );
}

export default AttendanceControl;
