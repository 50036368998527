import React, { useEffect, useRef } from "react";
import { FormattedMessage as FM } from "react-intl";

import ButtonsGroup from "components/Buttons/ButtonsGroup";
import XMarkIcon from "components/Icons/XmarkIcon";
import { classNames } from "tools/ui";

let dialogStyle =
  "shadow-lg w-full h-1/3 bg-gray-100 rounded-lg sm:w-2/3 md:w-3/5";

function BasicModal({
  openModal,
  closeModal,
  handleOk = false,
  children,
  title,
  buttons,
  style,
  styleChild = false,
}) {
  const ref = useRef(null);

  useEffect(() => {
    if (openModal.value) {
      ref.current?.showModal();
    } else {
      ref.current?.close();
    }
  }, [openModal.value]);

  function onAccept() {
    if (handleOk) {
      handleOk();
    }
  }

  return (
    <dialog
      id={title}
      className={classNames(dialogStyle, style)}
      ref={ref}
      onCancel={closeModal}
    >
      <div className="flex py-4 px-5 mb-4 justify-between sticky top-0 z-10 bg-gray-100">
        <span className="ml-3 text-neutral-500 text-2xl my-auto">
          <FM id={title} key={title} />
        </span>
        <div className="cursor-pointer active:scale-95" onClick={closeModal}>
          <XMarkIcon
            width="32"
            className="fill-current text-neutral-500 hover:text-red-600 active:text-blue-300"
          />
        </div>
      </div>
      <div
        id="div-modal-children"
        className={classNames("relative px-4 overflow-hidden", styleChild)}
      >
        {children}
      </div>
      {buttons && (
        <ButtonsGroup
          buttons={buttons}
          onClose={closeModal}
          onAccept={onAccept}
        />
      )}
    </dialog>
  );
}

export default BasicModal;
