import { useStoreProducts } from "./storeProducts";

function computeReverseTax(productId, unit_price_taxed) {
  const { products } = useStoreProducts.getState();
  const product = products.get(productId);
  let unit_price = unit_price_taxed;
  if (product.tax) {
    unit_price = unit_price_taxed / (1 + product.tax);
  }
  return unit_price;
}

export { computeReverseTax };
