import React from "react";

import MenuButtonAction from "components/Buttons/MenuButtonAction";

function MenuWizard({ ctxView, activeRecord }) {
  const { wizards } = ctxView;

  return (
    <div>
      <MenuButtonAction
        id="form-toolbar-action"
        name="actions"
        className="icon"
        activeRecord={activeRecord}
        options={wizards}
      />
    </div>
  );
}

export default MenuWizard;
