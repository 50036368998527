import React, { Fragment, useEffect } from "react";
import { FormattedMessage as FM } from "react-intl";
import date from "date-and-time";
import { signal } from "@preact/signals-react";
import store from "store";

import funcs from "tools/functions";
import useMainStore from "store/mainStore";
import CalendarFilter from "components/Tools/CalendarFilter";
import PanelRight from "components/Panel/PanelRight";
import WidgetSideStatusActivity from "./components/WidgetSideStatusActivity";
import StdButton from "components/Buttons/StdButton";
import dates from "tools/dates";
import proxy from "api/proxy";
import QuickForm from "components/Meta/QuickForm";
import QuickTable from "components/Meta/QuickTable";
import ScheduledActivities from "./components/ScheduledActivities";
import MenuRest from "./components/MenuRest";

const dayToday = new Date();
const statusSection = signal(true);
const filterDay = signal(dayToday);
const statusShedule = signal(true);
const view = signal("create");

const ScreenActivity = (props) => {
  let { ctxView, ctxViewAc, permits, configuration } = props;
  console.log(configuration, "configuration");

  const session = store.get("ctxSession");
  const saleActivity = store.get("saleActivity");
  const {
    store: storeRec,
    record,
    setRecord,
    updateRecord,
    updateStore,
  } = useMainStore();

  const handleChangeDate = (value) => {
    const now = dates.getNow().setUTCHours(0, 0, 0, 0);
    const value_ = new Date(value).setUTCHours(0, 0, 0, 0);
    if (permits && now <= value_) filterDay.value = new Date(value);
  };

  const resetAndSetShop = async () => {
    const reset = await resetNewRecord();
    updateStore(reset.toStore);
    updateRecord(reset.toRecord);
  };

  const onChangeView = (event, action, record) => {
    view.value = action;
  };

  const resetNewRecord = async () => {
    const sale = await getSalesForUser();

    if (!sale) {
      let agent = saleActivity?.agent;
      let party = saleActivity?.party;
      let sale_device = saleActivity?.sale_device;
      let shop = { id: saleActivity?.shop, name: saleActivity?.shop_name };
      const nextId = -Date.now();
      const [toStore, toRecord] = await funcs.getDefaults(nextId, ctxView);
      toStore.agent = agent?.id;
      toRecord.agent = agent;
      toStore.party = party?.id;
      toStore.warehouse = saleActivity?.warehouse;
      toRecord.party = party;
      toStore.sale_device = sale_device?.id;
      toRecord.sale_device = sale_device;
      toStore.shop = shop?.id;
      toRecord.shop = shop;

      return { toStore, toRecord };
    }

    return null;
  };

  useEffect(() => {
    // updateSession();
    resetAndSetShop();
  }, [props]);

  // useEffect(() => {
  //   resetAndSetShop();
  // }, [filterDay.value, props, view.value]);

  const getSalesForUser = async () => {
    let filterDayStart = date.format(filterDay.value, "YYYY-MM-DD");
    let dom = [
      ["create_uid", "=", session.user],
      ["state", "=", "draft"],
      ["reservation", "!=", true],
      ["sale_date", "=", filterDayStart],
      ["lines.origin", "ilike", "sale_activity.activity%"],
    ];
    const { data: resSale } = await proxy.search("sale.sale", dom, ["id"]);
    if (resSale.length != 0) {
      setRecord(resSale[0].id, ctxView.model, ctxView);
      return true;
    }
    return false;
  };

  return (
    <Fragment>
      {configuration?.date_picker && (
        <CalendarFilter action={handleChangeDate} />
      )}
      {["create", "edit"].includes(view.value) &&
        (record.id < 0 || record.state == "draft") && (
          <ScheduledActivities
            currentDay={filterDay.value}
            ctxView={ctxViewAc}
            view={view.value}
            statusShedule={statusShedule.value}
            configuration={configuration}
          />
        )}
      <div
        className={`flex ${
          statusSection.value ? "flex-row justify-between" : "flex-col"
        }`}
      >
        <div
          className={
            statusSection.value == true
              ? "md:w-[65vw] xl:w-[70vw] "
              : "md:w-12/12 relative pr-10"
          }
        >
          {["create", "edit"].includes(view.value) ? (
            <QuickForm ctxView={ctxView} level="main" />
          ) : view.value === "history" ? (
            <div className="py-10 mx-4">
              <QuickTable ctxView={ctxView} onChangeView={onChangeView} />
            </div>
          ) : (
            <p>Seleccionar algo</p>
          )}
        </div>
        <div className="w-[22vw]">
          {props.permits && (
            <PanelRight
              position={"right"}
              widgets={["status", "numPeople"]}
              title="INFO ACTIVIDADES"
              bgColor={statusSection.value ? "bg-gray-100" : "bg-blue-presik"}
              status={statusSection.value}
              handleChangeStatus={false}
              displayOpen={true}
              style="bg-gray-100 px-2 custom-transition duration-500 sticky w-full"
            >
              <WidgetSideStatusActivity
                title={<FM id="booking.widget.screen.huesped_house" />}
                background={"bg-gray-100"}
                name="statusHotel"
                day={filterDay}
              />
            </PanelRight>
          )}

          <PanelRight
            position={"right"}
            widgets={["status", "numPeople"]}
            title=""
            bgColor={statusSection.value ? "bg-gray-100" : "bg-blue-presik"}
            status={statusSection.value}
            handleChangeStatus={false}
            displayOpen={true}
            style="bg-gray-100 px-2 custom-transition duration-500 sticky w-full"
          >
            {configuration?.menu_view == "show" && <MenuRest record={[]} />}
            <StdButton
              content="sale.sale.new_sale"
              size="w-full"
              color="green"
              onClick={() => {
                resetAndSetShop();
                view.value = "create";
                statusShedule.value = true;
              }}
            />
            <StdButton
              content={"sale.sale.history"}
              size="w-full"
              onClick={() => (view.value = "history")}
              color={"bluePresik"}
              disabled={view.value == "history" ? true : false}
            />
          </PanelRight>
        </div>
      </div>
    </Fragment>
  );
};

export default ScreenActivity;
