const statementField = {
  name: "statement",
  label: "Medios de pago",
  sizeLabel: "text-3xl mb-6",
};

const amountField = {
  name: "amount",
  label: "Valor a pagar",
  type: "number",
  specialCharacters: true,
};

const voucherField = {
  name: "voucher",
  label: "Voucher",
  type: "char",
};

export { statementField, amountField, voucherField };
