import React from "react";
import History from "./History";
import { icon } from "leaflet";

function Stock(props) {
  return <History {...props} option="current_stock" />;
}

function Sale(props) {
  return <History {...props} option="sale_history" />;
}

function Purchase(props) {
  return <History {...props} option="purchases" />;
}

const getView = () => {
  // function getValue(rec, field) {
  //   if (field.name === "cost_price") {
  //     return Number(
  //       parseFloat(rec["product."]["cost_price"]).toFixed(2),
  //     ).toLocaleString("es", { useGrouping: true });
  //   }
  //   return rec["product."][field.name];
  // }

  function getIcon() {
    return (
      <i
        className={
          "mr-auto my-auto text-xl fi fi-rs-shopping-cart-add text-sky-700"
        }
      />
    );
  }

  let DictCtxView = {
    model: "purchase.request",
    table_action: [],
    form_action: [],
    limit: 50,
    pagination: [],
    webfields: {
      "product.name": {
        type: "char",
        readOnly: true,
      },
      party: {
        recName: "name",
        type: "many2one",
        model: "party.party",
        required: true,
      },
      "product.code": {
        type: "char",
        readOnly: true,
      },
      "product.description": {
        type: "char",
        readOnly: true,
      },
      current_stock: {
        type: "char",
        color: "blue",
        Component: Stock,
        desired_action: "value",
      },
      sale_history: {
        type: "char",
        color: "blue",
        Component: Sale,
        desired_action: "value",
      },
      quantity: { type: "numeric", editable: true },
      "product.cost_price": {
        type: "numeric",
        // function: getValue,
        readOnly: true,
      },
      purchases: {
        type: "char",
        color: "blue",
        search: false,
        // function: getIcon,
        labeled: false,
        icon: "fi fi-rr-shopping-cart-add",
        readOnly: true,
        Component: Purchase,
      },
    },
    webform: [
      { name: "party" },
      { name: "product" },
      { name: "quantity" },
      { name: "description" },
    ],
    webtree: [
      { name: "party", width: "20%", editable: true },
      { name: "product.code", width: "5%" },
      { name: "product.name", width: "15%" },
      { name: "product.description", width: "20%" },
      { name: "current_stock", width: "5%", widget: "button_custom_modal" },
      { name: "sale_history", width: "5%", widget: "button_custom_modal" },
      { name: "quantity", width: "10%", editable: true },
      { name: "product.cost_price", width: "10%" },
      { name: "purchases", width: "5%", widget: "button_custom_modal" },
    ],
  };

  return DictCtxView;
};

export default getView;
