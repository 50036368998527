// English translation

const en = {
  "hotel.booking.apply_taxes_exception": "Apply Taxes Exception",
  "hotel.booking.number": "Number",
  "hotel.booking.bill_to": "Bill To",
  "hotel.booking.booking_date": "Booking Date",
  "hotel.booking.total_amount": "Total Amount",
  "hotel.booking.total_advances": "Advances",
  "hotel.booking.lines": "# Room",
  "hotel.booking.media": "Media",
  "hotel.booking.contact": "Contact",
  "hotel.booking.meal_plan": "Regimen",
  "hotel.booking.space": "Space",
  "hotel.booking.price_list": "Price List",
  "hotel.booking.payment_term": "Payment Term",
  "hotel.booking.pending_to_pay": "Pending to Pay",
  "hotel.booking.channel": "Channel",
  "hotel.booking.ota_booking_code": "OTA Code",
  "hotel.booking.rate_plan": "Rate Plan",
  "hotel.booking.group": "Group",
  "hotel.booking.corporative": "Corporative",
  "hotel.booking.state": "State",
  "hotel.booking.offer": "Offer",
  "hotel.booking.confirm": "Confirm",
  "hotel.booking.cancelled": "Cancelled",
  "hotel.booking.not_show": "No show",
  "hotel.booking.finished": "Finished",
  "hotel.booking": "BOOKING",
  "hotel.booking.add_rooms": "Add Rooms",
  "hotel.booking.add_rooms.accept": "Accept",
  "hotel.booking.add_payment": "Add Payment",
  "hotel.booking.plan+rate": "Plan + Tax-free rate",
  "hotel.booking.fees_taxes_not_included": "FEES AND TAXES NOT INCLUDED",
  "hotel.booking.msg_iva_foreign":
    "VAT not included for foreigners, upon verification of passport and immigration card at check-in.",
  "hotel.booking.msg_iva": "VAT included from 19%.",
  "hotel.booking.i_foreigner": "I'm foreigner",
  "hotel.booking.i_colombiano": "I'm Colombian",
  "hotel.booking.today": "Today",
  "hotel.booking.confirmed": "Confirmed",
  "hotel.booking.bill": "Bill",
  "hotel.booking.agent": "Agent",
  "hotel.booking.notes": "Notes",
  "hotel.booking.complimentary": "Complimentary",
  "hotel.booking.pax": "PAX",
  "hotel.booking.holder": "Holder",
  "hotel.booking.holder_guest": "Holder / Guest",
  "hotel.booking.guest": "Guest",
  "hotel.booking.blocked": "Blocked",
  "hotel.booking.taxes_exception": "Taxes Exception",
  "hotel.folio.overbooking": "Overbooking",
  "hotel.folio.accept": "Accept",
  "hotel.booking.add_rooms.selector.product": "Category",
  "hotel.booking.add_rooms.selector.unit_price_w_tax": "Price Unit",
  "hotel.booking.add_rooms.selector.available": "Available",
  "hotel.booking.add_rooms.selector.adults": "Adults",
  "hotel.booking.add_rooms.selector.children": "Children",
  "hotel.booking.add_rooms.selector.room": "Room",
  "hotel.booking.add_rooms.selector.add": "Add",
  "hotel.booking.add_rooms.selector.sale_price_taxed": "Price",
  "hotel.booking.add_rooms.selector.discount": "Discount",
  "hotel.booking.add_rooms.nights_quantity": "Nights Qty",
  "hotel.booking.add_payment.amount": "Amount",
  "hotel.booking.add_payment.voucher": "Voucher",
  "hotel.booking.add_payment.statement": "Statement",
  "hotel.booking.add_payment.pay": "Pay",
  "hotel.booking.advances_payments": "Advances/Payments",
  "hotel.booking.invoices": "Invoices",
  "hotel.booking.add_room.arrival_date": "Arrival Date",
  "hotel.booking.add_room.departure_date": "Departure Date",
  "hotel.booking.add_room.nights_quantity": "Noches",
  "hotel.booking.add_rooms.selector.rooms": "Room",
};

export default en;
