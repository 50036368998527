import React, { useState } from "react";

// import ModalReport from "components/Modals/ModalReport";
import MenuButtonReport from "components/Buttons/MenuButtonReport";
import ModalMsg from "components/Modals/ModalMsg";

function MenuReport({ ctxView, activeRecord }) {
  const { reports } = ctxView;
  let [modalMsg, setModalMsg] = useState(null);
  let [openModalMsg, setOpenModalMsg] = useState(false);
  // let [openReport, setOpenReport] = useState(false);

  function dialogMesssage(msg) {
    setModalMsg(msg);
    setOpenModalMsg(true);
  }

  const closeModalMsg = () => {
    setOpenModalMsg(false);
  };

  return (
    <div>
      <MenuButtonReport
        id="form-toolbar-report"
        name="reports"
        className="icon"
        activeRecord={activeRecord}
        options={reports}
        dialogMesssage={dialogMesssage}
      />
      {openModalMsg && (
        <ModalMsg
          open={openModalMsg}
          buttons={["close"]}
          msg={modalMsg}
          onClose={closeModalMsg}
        />
      )}
    </div>
  );
}

export default MenuReport;
