// English translation

const en = {
  "hotel.folio.registration_state": "Registration State",
  "hotel.folio.room": "Room",
  "hotel.folio.main_guest": "Main Guest",
  "hotel.folio.nights_quantity": "Nights",
  "hotel.folio.arrival_date": "Arrival Date",
  "hotel.folio.departure_date": "Departure Date",
  "hotel.folio.notes": "Notes",
  "hotel.folio.breakfast_included": "Breakfast Included",
  "hotel.folio.check_in": "Check-In",
  "hotel.folio.check_out": "Check-Out",
  "hotel.folio.reverse_checkout": "Reverse Check-Out",
  "hotel.folio.confirm": "Confirm",
  "hotel.folio.pending": "Pending",
  "hotel.folio.booking": "Booking",
  "hotel.folio.contact": "Contact",
  "hotel.folio.registration_card": "Registration Card",
  "hotel.folio.product": "Service",
  "hotel.folio.unit_price": "Unit Price",
  "hotel.folio.channel": "Channel",
  "hotel.folio.group": "Group",
  "hotel.folio.total_amount": "Total Amount",
  "hotel.folio.bed_breakfast": "Cama & Desayuno",
  "hotel.folio.no_breakfast": "No Breakfast",
  "hotel.folio.all_inclusive": "All Inclusive",
  "hotel.folio.full_american": "Full American",
  "hotel.folio.half_american": "Half American",
  "hotel.folio.num_adults": "Num Adults",
  "hotel.folio.num_children": "Num Children",
  "hotel.folio.number": "Number",
  "hotel.folio.pax": "PAX",
  "hotel.folio.charge.folio": "Folio",
  "hotel.folio.charge.order": "Orden",
  "hotel.folio.charge.kind": "Kind",
  "hotel.folio.charge.invoice_line": "Factura",
  "hotel.folio.charge.draft": "Borrador",
  "hotel.folio.occupancy": "Occupancy",
  "hotel.folio.occupancy.occupancy_date": "Date",
  "hotel.folio.occupancy.unit_price": "Unit Price",
  "hotel.folio.occupancy.unit_price_w_tax": "Unit Price w Tax",
  "hotel.folio.occupancy.charge": "Charge",
  "hotel.folio.occupancy.state": "State",
  "hotel.folio.occupancy.draft": "Draft",
  "hotel.folio.occupancy.confirmed": "Confirmed",
  "hotel.folio.occupancy.invoiced": "Invoiced",
  "hotel.folio.guest.city": "City",
  "sale.line.unit_price_w_tax": "Price + Tax",
};

export default en;
