import React, { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";

import MenuActionReport from "components/Report/MenuActionReport";
import ReportIcon from "assets/apps/report.png";

function MenuReport({ reports }) {
  return (
    <Popover className="relative flex h-full px-3">
      <Popover.Button className="py-3 px-6 text-md text-neutral-700 rounded-lg hover:bg-neutral-200">
        <img src={ReportIcon} className="mx-auto h-10 w-10" />
        <span>Reports</span>
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="w-screen absolute left-1/2 z-10 mt-5 flex max-w-max -translate-x-1/2 translate-y-16 px-4">
          <div className="w-screen max-w-md flex-auto overflow-hidden rounded-2xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="m-4">
              {reports.map((item, idx) => (
                <MenuActionReport report={item} key={idx} />
              ))}
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
// dialogMesssage={dialogMesssage}

export default MenuReport;
