import React from "react";

import { classNames, colors } from "tools/ui";
import upStore from "store/upStore";

const PATTERN = /^-?\d*\.?\d{0,6}$/;
const formatter = Intl.NumberFormat("en-US");

function NumericCell(props) {
  const { data, field, attrs } = props;
  const { name, format, decimalPlaces } = field;
  const { level, parentRec, record } = attrs;

  if (!field.editable) {
    const val = getFormatValue();
    return formatter.format(val);
  }

  function getFormatValue() {
    let _value;
    if (data.value && data.value !== "") {
      _value = data.value.toString().replaceAll(",", "");
      _value = parseFloat(_value).toFixed(decimalPlaces || 0);
      if (format && format === "percent") {
        _value = _value * 100;
      }
      _value = _value.toString();
    } else {
      _value = 0;
    }
    return _value;
  }

  function onChange(event) {
    let _value = event.target.value;
    _value = _value.replaceAll(",", "");
    const validNumber = PATTERN.test(_value);

    if (!validNumber) {
      return;
    }

    data.value = _value;
  }

  function handleBlur(e) {
    const _value = getFormatValue();
    data.value = _value;
    upStore(level, {
      fieldName: name,
      recId: record.id,
      parentRec: parentRec,
      value: _value,
      valueActive: data.value,
      field: field,
    });
    record[name] = data.value;
  }

  let _color = props.readOnly ? colors.readOnly : props.color ?? "bg-white";

  return (
    <input
      id={props.name}
      name={props.name}
      className={classNames(
        "h-9 rounded-md shadow-sm w-full text-right py-1 px-3 text-gray-700 border border-slate-300 focus:outline-none focus:border focus:border-cyan-500",
        _color,
      )}
      type="text"
      inputMode="numeric"
      value={formatter.format(data.value)}
      onBlur={handleBlur}
      onChange={onChange}
      readOnly={props.readOnly}
      onClick={(e) => {
        e.stopPropagation();
      }}
    />
  );
}

export default NumericCell;
