import { create } from "zustand";
import proxy from "api/proxy";

const fieldsPts = [
  "name",
  "list_price",
  "default_uom",
  "sale_uom",
  "department",
  "sale_price",
  "sale_price_taxed",
  "template.account_category",
];

const model = "product.product";
// const orderPts = [["code", "ASC"]];
const limit = 1000;

function addTax(rec, taxesCat) {
  rec.tax = taxesCat.get(rec["template."].account_category);
  return rec;
}

async function getProducts(taxesCat) {
  const domain = [
    ["template.account_category", "!=", null],
    ["template.salable", "=", true],
    ["template.active", "=", true],
  ];
  const { data } = await proxy.search(model, domain, fieldsPts, limit);
  let res = new Map();
  if (data) {
    const list = data.map((rec) => [rec.id, addTax(rec, taxesCat)]);
    res = new Map(list);
  }
  return res;
}

async function getTaxes() {
  const domain = [
    ["tax.classification_tax", "in", ["01", "02", "04"]],
    ["tax.group.kind", "=", "sale"],
    ["tax.rate", ">", 0],
  ];
  const fieldsNames = ["category", "tax.rate", "tax.classification_tax"];
  const limit = 200;
  const { data } = await proxy.search(
    "product.category-customer-account.tax",
    domain,
    fieldsNames,
    limit,
  );
  let res = new Map();
  if (data) {
    const list = data.map((rec) => [rec["category"], rec["tax."].rate]);
    res = new Map(list);
  }
  return res;
}

export const useStoreProducts = create((set) => ({
  products: new Map(),
  setProducts: async () => {
    const taxesCat = await getTaxes();
    const _products = await getProducts(taxesCat);
    set({ products: _products });
  },
}));
