import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { PlusCircleIcon } from "@heroicons/react/20/solid";
// import IconButton from "components/Buttons/IconButton";

/**
 * create component SubtableHeader
 * @param {Object} props
 * @param {Array} props.ctxView
 * @param {Function} props.onChangeView
 * @param {String} props.viewType
 * @returns component SubtableHeader
 */
const SubtableHeader = (props) => {
  const { ctxView, handleAddModal, readOnly } = props;
  const title = ctxView.model && <FM id={ctxView.model} />;

  const getButtons = () => {
    return ctxView.table_action &&
      !readOnly &&
      ctxView.table_action.includes("add") ? (
      <button
        className="flex bg-cyan-500 text-white items-center h-fit text-lg font-medium hover:bg-cyan-400 active:scale-90 px-4 space-x-2 py-1 rounded-md shadow-md transform duration-75"
        onClick={(e) => handleAddModal(e, "add")}
      >
        <span>
          <FM id="add_header_modal" />
        </span>

        <PlusCircleIcon className="text-white w-6" />
      </button>
    ) : null;
  };

  return (
    <div id="subtable-header" className="grid-cols-12 flex px-2 py-2">
      <div className="flex col-span-12 md:col-span-8 w-4/5 text-zinc-500">
        <h2 className="my-auto truncate text-xl">{title}</h2>
      </div>
      <div className="flex col-span-12 md:col-span-4 ml-auto gap-x-4">
        {getButtons()}
      </div>
    </div>
  );
};

export default SubtableHeader;
