import proxy from "api/proxy";
import { useWizardStore } from "store/wizardStore";

const getOptions = () => {
  const options = [
    { id: "11", name: "registro_civil" },
    { id: "12", name: "tarjeta_identidad" },
    { id: "13", name: "cedula_ciudadania" },
    { id: "21", name: "tarjeta_extranjeria" },
    { id: "22", name: "cedula_extranjeria" },
    { id: "31", name: "nit" },
    { id: "41", name: "pasaporte" },
    { id: "42", name: "tipo_documento_extranjero" },
    { id: "47", name: "pep" },
    { id: "50", name: "nit_otro_pais" },
    { id: "91", name: "nuip" },
  ];

  return options;
};

const DEFAULT_COUNTRY = { id: "50", code: "CO", name: "🇨🇴 Colombia" };

const getOptionsSex = [
  { id: "male", name: "male" },
  { id: "female", name: "female" },
];

function getCities(data, record) {
  let dom = null;
  if (record && record.subdivision) {
    dom = ["subdivision", "=", Number(record.subdivision.id)];
  }
  return dom;
}
function getSubdivision(data, record) {
  let dom = null;
  if (record && record.country) {
    // dom = null;
    dom = ["country", "=", Number(record.country?.id)];
  }
  return dom;
}

function reqByCountry(record) {
  return record?.country?.code === "CO";
}

function visibleByCountry(name, record) {
  return record?.country?.code === "CO";
}

function onChangeCountry(record, store) {
  if (record?.country?.code !== "CO") {
    record.subdivision = null;
    store.subdivision = null;
    record.city_co = null;
    store.city_co = null;
  }
}

const searchParty = async (storeRec, activeRecord) => {
  const { setRecord } = useWizardStore.getState();

  const idCustomer = storeRec.id_number;
  if (idCustomer) {
    const { data } = await proxy.search(
      "party.party",
      [["id_number", "=", idCustomer]],
      [
        "rec_name",
        "name",
        "id",
        "account_receivable",
        "phone",
        "main_email",
        "main_mobile",
        "mobile",
        "street",
        "birthday",
        "type_document",
        "type_document.name",
        "city_co.name",
        "subdivision.name",
        "country.name",
        "nationality.name",
        "account_receivable",
        "sex",
      ],
    );
    if (data.length > 0) {
      setRecord(data[0].id, "party.party", getView());
    }
  }
};

const getView = () => {
  let ctxView = {
    model: "party.party",
    selectable: "one",
    activeSearch: true,
    filters: null,
    form_action: ["edit", "add"],
    table_action: ["add"],
    webfields: {
      name: { type: "char", searchable: true, required: true },
      rec_name: { type: "char" },
      id_number: {
        type: "char",
        searchable: true,
        required: true,
        withChange: searchParty,
      },
      account_payable: { type: "boolean" },
      type_document: {
        type: "selection",
        options: getOptions(),
        translate: true,
        required: true,
      },
      sex: {
        type: "selection",
        options: getOptionsSex,
        translate: true,
      },
      check_digit: {
        type: "integer",
      },
      main_email: {
        type: "char",
        required: true,
      },
      main_mobile: {
        type: "char",
        // searchable: true,
        required: true,
      },
      phone: {
        type: "char",
      },
      birthday: {
        type: "date",
      },
      street: {
        type: "char",
        required: true,
        // withChange: (store, rec) => console.log("store", store, "rec", rec),

        // setter: true,
        // attrs: ["addresses", "addresses.street"],
      },
      country: {
        type: "many2one",
        model: "country.country",
        required: true,
        withChange: onChangeCountry,
        attrs: ["code"],
        default: DEFAULT_COUNTRY,
        recSearch: getCities,
        storage: true,
      },
      subdivision: {
        type: "many2one",
        model: "country.subdivision",
        required: reqByCountry,
        visible: visibleByCountry,
        attrs: ["code", "country"],
        recSearch: getSubdivision,
        storage: true,
      },
      city_co: {
        type: "many2one",
        model: "country.city",
        // setter: true,
        // function: getCity,
        required: reqByCountry,
        visible: visibleByCountry,
        recSearch: getCities,
        storage: true,
      },
      // nationality: {
      //   type: "many2one",
      //   model: "country.country",
      // },
      account_receivable: {
        type: "many2one",
        model: "account.account",
        readOnly: true,
      },
    },
    webtree: [
      { name: "name", width: "30%" },
      { name: "id_number", width: "10%" },
      { name: "account_receivable", width: "15%" },
    ],
    webform: [
      { name: "id_number" },
      { name: "name" },
      { name: "type_document" },
      { name: "street" },
      { name: "country" },
      { name: "subdivision" },
      { name: "city_co" },
      { name: "main_email" },
      { name: "main_mobile" },
      { name: "sex" },
      { name: "birthday" },
    ],
  };

  return ctxView;
};

export default { ctxView: getView };
