import React, { useState } from "react";
import CalendarAppointmentScheduling from "./Calendar";
// import Calendar2 from "./Calendar2";

const Calendar = () => {
  return (
    <div className="w-full">
      <CalendarAppointmentScheduling />
    </div>
  );
};

export default Calendar;
