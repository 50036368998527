// English translation
import { en as views_en } from "./views";

const en = {
  "app.global.spanish": "Spanish",
  "app.global.english": "English",
  "login.welcome": "Welcome!",
  "login.start_session": "START SESSION",
  "login.invalid_user_password": "Invalid user or password!",
  "login.button_enter": "ENTER",
  "login.password": "PASSWORD",
  "login.database": "DATABASE",
  "login.username": "USERNAME",
  "login.forgot_password": "Forgot password?",
  "board.loading_please_wait": "Loading... Please one moment!",
  "board.loading": "Loading...",
  "board.approve": "APPROVE",
  "board.button_edit": "EDIT",
  button_edit: "Edit",
  "board.button_add": "ADD",
  button_add: "Add",
  "board.button_back": "BACK",
  "board.button_save": "SAVE",
  "board.button_remove": "REMOVE",
  "board.button_replace": "REPLACE",
  "board.filter": "FILTER",
  "board.button_confirm": "CONFIRM",
  "board.chart.in_thousands": "In thousands",
  "board.chart.see_report": "See Report",
  "board.sheet_select": "Spread Sheet",
  "board.missing_required_fields": "Missing required fields!",
  "quicktable.select_all": "ALL",
  missing_required_fields: "Missing required fields!",
  "board.records_found": "Records found",
  "board.record_saved": "RECORD SAVED!",
  "board.records_saved": "RECORDS SAVED!",
  "board.record_created": "RECORD CREATED!",
  "board.dialog.button_discard": "Discard",
  "basic_modal.button_cancel": "CANCEL",
  "basic_modal.button_ok": "OK",
  "basic_modal.button_accept": "Accept",
  "basic_modal.button_close": "Close",
  "basic_modal.button_create": "Create",
  available: "Available",
  not_available: "Not Available",
  select_time: "Select the time...",
  "form_cloud_image.add_photo": "Add image",
  "menu.dashboard": "DASHBOARD",
  "menu.delivery": "DOMICILIOS",
  "menu.order": "PEDIDOS",
  "menu.sale_order": "SALE ORDER",
  "menu.housekeeping": "HOUSEKEEPING",
  "menu.rest_order": "REST. ORDER",
  "menu.travel_person": "TRAVEL PERSON",
  "menu.ticket": "TURNADOR",
  "menu.affiliates": "AFILIADOS",
  "menu.assistant_control": "CONTROL DE ACCESO",
  "sale.sale.number": "NUMBER",
  "sale.sale.sale_date": "DATE",
  "sale.sale.consumer": "CONSUMER",
  "sale.sale.position": "POSITION",
  "sale.sale.channel": "CHANNEL",
  "sale.sale.delivery_state": "STATUS",
  "sale.sale.comment": "COMMENT",
  "sale.sale.party": "PARTY",
  "sale.sale.waiting_time": "WAITING TIME",
  "sale.sale.order_status": "STATUS",
  "sale.sale.commanded": "COMMANDED",
  "sale.sale.draft": "DRAFT",
  "sale.sale.billing": "BILLING",
  "sale.sale.pay": "PAY",
  "sale.sale.dispatched": "DISPATCHED",
  "sale.sale.rejected": "REJECTED",
  "sale.sale.delivered": "DELIVERED",
  "sale.sale.processing": "PROCESSING",
  "sale.sale.terminal": "TERMINAL",
  "sale.sale.gateway": "PASARELA",
  "sale.sale.total_amount": "TOTAL",
  "sale.sale.source": "SOURCE",
  "sale.sale.description": "DESCRIPTION",
  "sale.sale.state": "STATE",
  "sale.sale.payment_method": "PAYMENT",
  "sale.sale.cash": "CASH",
  "sale.sale.persons": "Persons",
  "sale.line.product": "PRODUCT",
  "sale.line.quantity": "QTY",
  "sale.line.discount": "DISCOUNT",
  "sale.line.unit_price": "UNIT PRICE",
  "sale.line.unit": "UNIT",
  "sale.line.unit_price_w_tax": "UNIT PRICE",
  "sale.line.sale_price_taxed": "UNIT PRICE W.TAX",
  "sale.line.amount": "AMOUNT",
  "sale.sale.quote": "CONFIRM",
  "sale.sale.salesman": "SALESMAN",
  "sale.sale.shipment_address": "SHIP. ADDRESS",
  "sale.sale.agent": "AGENT",
  "sale.sale": "SALE",
  "sale.line": "PRODUCTS",
  button_cancel: "CANCEL",
  successful_order: "SUCCESSFUL ORDER!",
  "sale.sale.price_list": "PRICE LIST",
  button_delete: "DELETE",
  "sale.sale.shipment_date": "SHIP. DATE",
  "sale.sale.today": "TODAY",
  "menu.sale_call_center": "ORDER RECORD",
  "menu.stock_request": "STOCK REQUEST",
  "menu.inventory": "INVENTORY",
  "stock.inventory": "INVENTORY",
  "stock.inventory.line": "LINES",
  "menu.purchase_requisition": "REQUISITION",
  "stock.shipment.internal": "INTERNAL REQUEST",
  "stock.shipment.internal.reference ": "REFERENCE",
  "stock.shipment.internal.from_location": "FROM LOCATION",
  "stock.shipment.internal.to_location": "TO LOCATION",
  "stock.shipment.internal.requested_by": "REQUESTED BY",
  "stock.shipment.internal.moves": "PRODUCTS",
  "stock.shipment.internal.state": "STATE",
  "stock.shipment.internal.number": "NUMBER",
  "stock.shipment.internal.effective_date": "EFFECTIVE DATE",
  "stock.shipment.internal.today": "TODAY",
  "stock.shipment.internal.wait": "CONFIRM",
  effective_date: "PLAN. DATE",
  uom: "UoM",
  default_uom: "UoM",
  "stock.move": "PRODUCTS",
  "stock.move.product": "PRODUCT",
  "stock.move.uom": "UOM",
  "stock.move.quantity": "QUANTITY",
  waiting: "WAITING",
  request: "REQUEST",
  billing: "BILLING",
  print: "PRINT",
  user_has_not_access_permissions: "User has not access permissions!",
  "purchase.purchase.purchase_date": "DATE",
  "purchase.purchase.number": "NUMBER",
  "purchase.purchase.approved": "APPROVED",
  "purchase.purchase.confirmed": "CONFIRMED",
  "purchase.purchase.done": "DONE",
  "purchase.purchase.processing": "PROCESSING",
  "purchase.purchase.cancelled": "CANCELLED",
  "purchase.purchase.total_amount": "TOTAL AMOUNT",
  "purchase.purchase.party": "PARTY",
  "purchase.purchase.state": "STATE",
  "purchase.purchase.project": "PROJECT",
  "purchase.purchase.create_uid": "USER",
  "purchase.purchase.description": "DESCRIPTION",
  "purchase.purchase.payment_term": "PAYMENT TERM",
  "purchase.purchase.reference": "REFERENCE",
  "purchase.purchase.untaxed_amount": "UNTAXED AMOUNT",
  "purchase.purchase.tax_amount": "TAX AMOUNT",
  "purchase.purchase.comment": "COMMENT",
  "purchase.purchase.today": "TODAY",
  "purchase.purchase": "PURCHASE",
  "purchase.purchase.pending": "PENDING",
  "purchase.purchase.all": "ALL",
  "purchase.purchase.quotation": "QUOTATION",
  "purchase.purchase.draft": "DRAFT",
  "menu.purchase": "PURCHASE",
  "menu.purchase_approval": "PURCHASE APPROVAL",
  "menu.shipment_internal": "SHIPMENT INTERNAL",
  "purchase.line": "PRODUCTS",
  "product.code": "CODE",
  "product.description": "DESCRIPTION",
  "menu.surveillance_schedule": "Surveillance",
  "surveillance.schedule": "Surveillance",
  supplier: "SUPPLIER",
  code: "CODE",
  employee: "EMPLOYEE",
  active: "ACTIVE",
  city: "CITY",
  name: "NAME",
  position: "POSITION",
  "board.button_reload": "RELOAD",
  "menu.staff_access": "ACCESS CONTROL",
  successful: "SUCCESSFUL TRANSACTION!",
  total_amount_cache: "TOTAL AMOUNT",
  untaxed_amount_cache: "SUBTOTAL",
  tax_amount_cache: "IMPUESTOS",
  high: "Alta",
  urgent: "Urgente",
  low: "Baja",
  "purchase.purchase.priority": "PRIORITY",
  "purchase.purchase.approval_signature": "APPROVED BY",
  "purchase.purchase.confirm": "CONFIRM",
  "purchase.purchase.confirmation_signature": "CONFIRMED BY",
  "purchase.purchase.approval": "APPROVAL",
  "purchase.purchase.confirm_user": "CONFIRMADO POR",
  "purchase.purchase.approval_user": "APROBADO POR",
  "purchase.line.product": "PRODUCT",
  "purchase.line.unit_price": "UNIT_PRICE",
  "purchase.line.amount": "AMOUNT",
  "purchase.line.quantity": "QUANTITY",
  "purchase.line.description": "DESCRIPTION",
  "menu.hotel_planner": "PLANNER",
  "menu.hotel_calendar": "CALENDAR",
  "menu.hotel_rack": "RACK",
  "menu.take_order": "TOMA DE PEDIDO",
  "menu.order_viewer": "VISOR DE ORDENES",
  table_assigned: "MESA",
  command: "COMANDAR",
  note: "NOTA",
  order_sended: "Comm.",
  "sale.line.note": "Notes",
  "sale.line.requested": "Requested",
  "sale.line.draft": "Draft",
  "sale.line.commanded": "Comman.",
  "room.none": "None",
  "room.blocked": "Blocked",
  "room.check_in": "Check-In",
  "room.check_out": "Check-Out",
  "room.maintenance": "Maintenance",
  "room.pending": "Pending",
  "menu.self_service_sale": "SELF SALE",
  "app.webcheckin": "WEB CHECK IN",
  "menu.web_checkin": "WEB CHECK IN",
  "app.webcheckin.DIRECTO": "Directo",
  "hotel.folio.guest.cedula_de_ciudadania": "Cedula de Ciudadania",
  "hotel.folio.guest.pasaporte": "Passport",
  "hotel.folio.guest.tarjeta_de_identidad": "Tarjeta de Identidad",
  "hotel.folio.guest.tarjeta_de_extranjeria": "Tarjeta de Extranjeria",
  "hotel.folio.guest.cedula_de_extranjeria": "Cedula de Extranjeria",
  "hotel.folio.guest.male": "Male",
  "hotel.folio.guest.female": "Female",

  "hotel.maintenance": "Maintenance",
  "hotel.maintenance.start_date": "Start Date",
  "hotel.maintenance.end_date": "End Date",
  "hotel.maintenance.room": "Room",
  "hotel.maintenance.criticality": "Criticality",
  "hotel.maintenance.issue": "Issue",
  "hotel.maintenance.confirm": "Confirm",
  "hotel.maintenance.low": "Low",
  "hotel.maintenance.urgent": "Urgent",
  "hotel.maintenance.important": "Important",
  "hotel.maintenance.total_days": "Total Days",
  finish: "FINISH",
  webcheckin_success_msg:
    "Congratulations, now your check-in will be much faster at our hotel, and from now on you will be able to enjoy our Premiun services.",
  webcheckin_success_header: "Success Registration!!!",
  webcheckin_success_our_services: "Our Services",
  webcheckin_booking_page:
    "You can now add guests to each of your booked rooms, every time you want to add a person clicked on the room.",
  male: "Male",
  female: "Female",
  cedula_de_ciudadania: "Cedula de Ciudadania",
  pasaporte: "Passport",
  tarjeta_de_identidad: "Tarjeta de Identidad",
  tarjeta_de_extranjeria: "Tarjeta de Extranjeria",
  cedula_de_extranjeria: "Cedula de Extranjeria",
  adult: "Adulto",
  child: "Niño/a",
  type: "TYPE",
  link: "LINK",
  file: "FILE",
  "menu.charts": "Dashboard",
  sales: "Sales",
  payroll: "Payroll",
  hotel: "Hotel",
  crm: "CRM",
  purchases: "Purchases",
  dashboard: "Dashboard",
  go_back: "GO BACK",
  address: "ADDRESS",
  country_residence: "COUNTRY OF RESIDENCE",
  unit: "UNIT",
  company: "Company",
  user: "User",
  password: "Password",
  enter: "Enter",
  "modal_msg.record_successful": "Registration entered correctly!",
  "modal_msg.record_warning": "Adverencia!",
  "modal_msg.record_ask": "Pregunta",
  "modal_msg.record_error": "Error de Registro",
  "crm.customer_service.addres": "Dirección",
  "crm.customer_service.search": "Buscar",
  "crm.customer_service.case": "Detalle Causal",
  "crm.customer_service.response": "Respuesta",
  "crm.customer_service.description": "Resumen de Solicitud",
  "crm.customer_service.state": "Estado",
  "crm.customer_service.party": "Name",
  "crm.customer_service.cs_date": "Date",
  "crm.customer_service.number": "Número de Radicado",
  "crm.customer_service.party_id_number": "Número de Identificación",
  "crm.customer_service.draft": "Radicado",
  "crm.customer_service.open": "Abierto",
  "crm.customer_service.close": "Cerrado",
  "crm.customer_service.email": "Email",
  "crm.customer_service.attach_customer_1": "Adjunto",
  "crm.customer_service.create_service": "CREAR RADICADO",
  "crm.customer_service.ethnical_group": "Grupo Étnico",
  "crm.customer_service.special_population": "Poblacón Especial",
  "crm.customer_service.personal_information": "Información Personal",
  "crm.customer_service.pqrs_information": "Información PQRS",
  "crm.customer_service.demographic_information":
    "Información Sociademografica",
  "crm.customer_service.description_facts": "Descripción de los Hechos",
  "crm.customer_service.phone": "Phone",
  "crm.customer_service.health_provider": "Prestador de Salud",
  "crm.customer_service.detailed_especific_1": "Detalle Especifico",
  "form_modal.button_close": "CLOSE",
  button_open: "OPEN",
  "board.dialog.button_close": "Cerrar",
  "board.dialog.button_ok": "OK",
  "board.dialog.button_cancel": "Cancel",
  "hotel.room": "HOUSEKEEPING",
  "hotel.room.state": "STATE",
  "hotel.room.name": "ROOM",
  "hotel.room.cleaning_type": "CLEANING TYPE",
  "hotel.room.dirty": "DIRTY",
  "hotel.room.clean": "CLEAN",
  "hotel.room.all": "ALL",
  "hotel.room.not_authorized": "No Author.",
  "hotel.room.maintenance": "Maintenance",
  "hotel.room.location": "Location",
  "hotel.room.check_out_today": "Check Out Today",
  "hotel.room.arrival_today": "Arrival Today",
  "hotel.room.check_in_today": "Check In Today",
  "hotel.room.departure_today": "Departure Today",
  "hotel.room.housekeeping": "Housekeeping",
  "hotel.room.type_": "Type",
  "planner.date": "Date",
  "hotel.folio.meal_plan": "Meal Plan",
  "hotel.folio.pending_total": "Pending Total",
  "hotel.folio.agent": "Agent",
  "hotel.folio.unit_price_w_tax": "Precio con Imp.",
  "hotel.folio.vehicle_plate": "Vehicle Plate",
  "hotel.folio.guests": "Guests",
  "hotel.folio.payment_status": "Payment Status",
  "hotel.folio.charge": "Charges",
  "hotel.folio.charges": "Charges",
  "hotel.folio.guest": "Guest",
  "hotel.folio.guest.main_guest": "ain Guest",
  "hotel.folio.guest.name": "Name",
  "hotel.folio.guest.type_document": "Type Document",
  "hotel.folio.guest.id_number": "Id Number",
  "hotel.folio.guest.mobile": "Mobile",
  "hotel.folio.guest.email": "Email",
  "hotel.folio.guest.address": "Address",
  "hotel.folio.guest.nationality": "Nationality",
  "hotel.folio.guest.origin_country": "Origin Country",
  "hotel.folio.guest.target_country": "Target Country",
  "hotel.folio.guest.birthday": "Birthday",
  "hotel.folio.guest.notes": "Notes",
  "hotel.folio.guest.visa_date": "Visa Date",
  "hotel.folio.guest.visa_number": "Visa Number",
  "hotel.folio.guest.sex": "Sexo",
  "hotel.folio.guest.profession": "Profesion",
  "hotel.folio.guest.registro_civil": "Registro Civil de Nacimiento",
  "hotel.folio.guest.tarjeta_identidad": "Tarjeta de Identidad",
  "hotel.folio.guest.cedula_ciudadania": "Cedula de Ciudadania",
  "hotel.folio.guest.tarjeta_extranjeria": "Tarjeta de Extranjeria",
  "hotel.folio.guest.cedula_extranjeria": "Cedula de Extranjeria",
  "hotel.folio.guest.nit": "NIT",
  "hotel.folio.guest.add_guest": "Add",
  "hotel.folio.guest.cancel": "Cancel",
  "hotel.folio.guest.pasaporte": "Pasaporte",
  "hotel.folio.guest.tipo_documento_extranjero": "Tipo de Documento Extranjero",
  "hotel.folio.guest.pep": "PEP",
  "hotel.folio.guest.nit_otro_pais": "NIT de otro pais",
  "hotel.folio.guest.nuip": "NUIP",
  "hotel.folio.guest.country": "Country",
  "hotel.folio.guest.subdivision": "Subdivision",
  "hotel.folio.guest.clone_holder": "Create Guest",
  "hotel.folio.charge.sale_date": "Date",
  "hotel.folio.charge.product": "Product",
  "hotel.folio.charge.quantity": "Quantity",
  "hotel.folio.charge.unit_price_w_tax": "Unit Price W Tax",
  "hotel.folio.charge.amount": "Amount",
  "hotel.folio.charge.status": "Status",
  "hotel.folio.charge.pending": "Pending",
  "hotel.folio.charge.add_product": "Add Product",
  "hotel.booking.party": "Customer",
  "hotel.booking_statement": "Statement",
  "hotel.folio": "Folio",
  "app.webcheckin.customer": "CUSTOMER",
  "app.webcheckin.no_booking": "No. BOOKING",
  "app.webcheckin.channel": "CHANNEL",
  "app.webcheckin.arrival_date": "ARRIVAL DATE",
  "app.webcheckin.departure_date": "DEPARTURE DATE",
  "app.webcheckin.finish": "FINISH",
  "app.webcheckin.direct": "DIRECT",
  "app.webcheckin.main_guest": "ARE YOU MAIN GUEST?",
  "app.webcheckin.name": "NAME",
  "app.webcheckin.mobile": "MOBILE",
  "app.webcheckin.email": "EMAIL",
  "app.webcheckin.address": "ADDRESS",
  "app.webcheckin.nationality": "NATIONALITY",
  "app.webcheckin.country_residence": "COUNTRY RESIDENCE",
  "app.webcheckin.sex": "SEX",
  "app.webcheckin.type_guest": "TYPE GUEST",
  "app.webcheckin.id_number": "ID NUMBER",
  "app.webcheckin.type_document": "DOCUMENT TYPE",
  "app.webcheckin.visa_number": "No VISA",
  "app.webcheckin.visa_date": "EXPIRATION VISA DATE",
  "app.webcheckin.birthday": "BIRTHDAY",
  "app.webcheckin.profession": "PROFESSION",
  "app.webcheckin.add_guest": "ADD GUEST",
  "app.webcheckin.cancel": "CANCEL",
  "surveillance.location.code": "CODE",
  "surveillance.location.name": "NAME",
  "surveillance.location.customer": "CUSTOMER",
  "surveillance.location.city": "CITY",
  "schedule.scheduling": "SCHEDULING",
  "surveillance.schedule.shift": "Shift",
  "surveillance.schedule.shift.guard": "Guard",
  "surveillance.schedule.shift.location": "Location",
  "surveillance.schedule.shift.shift_date": "Fecha",
  "surveillance.schedule.shift.replicate": "Replicar",
  "surveillance.schedule.shift.extra_shift": "Turno Extra",
  "surveillance.schedule.shift.shift": "Clase de Turno",
  "surveillance.schedule.shift.duration": "Duracion",
  "surveillance.schedule.shift.enter_dtime": "Ingreso",
  "surveillance.schedule.shift.exit_dtime": "Salida",
  "surveillance.schedule.shift.position": "Posicion",
  "surveillance.schedule.shift.event_category": "Novedad Prog.",
  "surveillance.schedule.shift.event": "Novedad Efectiva",
  "surveillance.schedule.shift.notes": "Notas",
  "surveillance.schedule.shift.event_start_date": "Inicio de Novedad",
  "surveillance.schedule.shift.event_end_date": "Fin de Novedad",
  "surveillance.schedule.shift.kind": "Turno",
  "surveillance.schedule.shift.state": "Estado",
  "surveillance.schedule.shift.guard.photo_link": "Photo",
  "surveillance.schedule.shift.draft": "Draft",
  "surveillance.schedule.shift.performed": "Performed",
  "surveillance.schedule.shift.schedule": "Schedule",
  "surveillance.schedule.state": "State",
  "surveillance.schedule.draft": "Draft",
  "surveillance.schedule.planned": "Planned",
  "schedule.shift": "SHIFT",
  "schedule.search": "Search",
  "company.employee.party": "EMPLOYEE",
  "company.employee.code": "CODE",
  "modal.search": "SEARCH",
  "surveillance.schedule.period": "Period",
  "surveillance.schedule.location": "Location",
  "button.print": "IMPRIMIR",
  "crm.opportunity.state": "State",
  "crm.opportunity.agent": "Agent",
  "crm.opportunity.party_contact": "Party Contact",
  "crm.opportunity.party_category": "Category",
  "crm.opportunity.contact_phone": "Phone",
  "crm.opportunity.state.lead": "Lead",
  "crm.opportunity.state.opportunity": "Opportunity",
  "crm.opportunity.city": "City",
  "crm.opportunity.conversion_probability": "Conversion Probability",
  "crm.opportunity.lead_origin": "Origin",
  "crm.opportunity.amount": "Amount",
  "crm.opportunity.type": "Type",
  "crm.opportunity.prospect": "Prospect",
  "crm.opportunity.end_date": "End Date",
  "crm.opportunity.line.payment_term": "Payment Term",
  "crm.opportunity.line.description": "Description",
  "crm.opportunity.line.billing_frecuency": "Billing",
  "crm.opportunity.line.unit": "Unit",
  "crm.opportunity.line.availability": "Availability",
  "crm.opportunity.brochure": "Brochure",
  "crm.opportunity.company": "Company",
  "crm.opportunity.contact_email": "Contact Email",
  "crm.opportunity.more_probabilty": "More Probabilty",
  "crm.opportunity.last_90": "Ultimos 90 dias",
  "crm.opportunity.last_quotations": "Last Quotations",
  "crm.opportunity.panel.win_rate": "Win Rate",
  "crm.opportunity.panel.win_rate_today": "Today",
  "crm.opportunity.panel.win_rate_yesterday": "Yesterday",
  "crm.opportunity.panel.activities_today": "Activ. Today",
  "crm.opportunity.panel.activities_tomorrow": "Activ. Tomorrow",
  "crm.opportunity.panel.activities_pending": "Activ. Pending",
  "crm.opportunity.panel.leads": "Leads",
  "crm.opportunity.panel.leads_draft": "Draft",
  "crm.opportunity.panel.leads_convert": "Convert",
  "crm.opportunity.sales.sale_date": "Sale Date",
  "crm.prospect.name": "Name",
  "crm.prospect.phone": "Phone",
  "crm.prospect.email": "Email",
  "crm.prospect.agent": "Agent",
  "modal_form.button_accept": "Accept",
  "modal_form.button_add": "Add",
  "modal_form.button_save": "Save",
  "booking.widget.customers.title": "Booking information",
  "booking.widget.customers.name_contact": "Contact: ",
  "booking.widget.customers.name_contact.placeholder": "Name Contact",
  "booking.widget.customers.number": "Number",
  "booking.widget.customers.booking_date": "Booking Date",
  "booking.widget.customers.total_amount": "Total Amount",
  "booking.widget.customers.state": "State",
  "booking.widget.customers.rooms": "Rooms",
  "booking.widget.customers.info_huesped": "Customer information",
  "booking.widget.customers.name": "Full Name",
  "booking.widget.customers.phone": "Phone",
  "booking.widget.customers.booking_hour": "Hour",
  "booking.widget.customers.email": "Email",
  "booking.widget.customers.call": "Call",
  "booking.widget.screen.huesped_house": "Guests at home",
  "booking.widget.screen.occupied_rooms": "Occupied rooms",
  "booking.panel.more_options": "More Options",
  "booking.widget.screen.view_more": "See More",
  "booking.widget.screen.view_less": "See Less",
  "board.button_field_create": "Create",
  "board.button_field_edit": "Edit",
  "booking.widget_side.today": "Today",
  "booking.widget_side.tomorrow": "Tomorrow",
  "booking.widget_side_status.revenue_today": "Revenue today",
  "booking.widget_side_status.revenue_tomorrow": "Revenue tomorrow",
  "hotel.booking.lead_origin": "Leads",
  ".name": "Name",
  "party.party.id_number": "ID Number",
  "party.party.type_document": "Type Document",
  "party.party.nationality": "Nationality",
  "party.party.main_email": "Email",
  "party.party.main_mobile": "Mobile",
  "party.party.phone": "Phone",
  "party.party.sex": "Sex",
  "party.party.birthday": "Birthday",
  "party.party": "Add Customer",
  "party.party.street": "Street",
  "party.party.account_receivable": "Account Receivable",
  "party.party.country": "Country",
  "party.party.subdivision": "Subdivision",
  "party.party.city": "City",
  holder: "Holder",
  holder_guest: "Holder / Guest",
  guest: "Guest",
  no_breakfast: "No Breakfast",
  all_inclusive: "All Inclusive",
  bed_breakfast: "Bed Breakfast",
  full_american: "Full American",
  half_american: "Half American",
  "menu.activity": "Activities",
  "sale_activity.activity.time_start": "Time Start",
  "sale_activity.activity.time_end": "Time End",
  "sale_activity.activity.available": "Available",
  "sale_activity.activity.quota": "Quota",
  "sale_activity.activity.state": "State",
  "sale_activity.activity.add": "Add",
  "sale_activity.activity.employee": "Employee",
  "sale.sale.add_courtesy": "Add Courtesy",
  "hotel.booking.bill.party": "Party",
  "hotel.booking.bill.kind": "Kind",
  "hotel.booking.bill.all": "All",
  "hotel.booking.bill.partial": "Partial",
  all: "All",
  partial: "Partial",
  "rental.service.appbooking": "SEND RESERVATION",
  "rental.booking.number": "Number",
  "rental.service.confirm": "Confirm",
  "rental.booking.equipment": "Equipment",
  "rental.booking.type_document": "Type Document",
  "rental.booking.doc_number": "Document Number",
  "rental.booking.booking_date": "Date",
  "rental.booking.first_name": "Name",
  "rental.booking.last_name": "Last Name",
  "rental.booking.mobile": "Mobile",
  "rental.booking.state": "State",
  "rental.booking.draft": "Draft",
  "rental.booking.booking": "Booking",
  "rental.booking.processed": "Processed",
  "rental.booking.cancelled": "Cancelled",
  "rental.booking": "Rental Booking",
  "rental.service": "Sale Service",
  "rental.booking.fullName": "Full Name",
  "rental.service.number": "Number",
  "rental.service.product": "Product",
  "rental.service.start_date": "Start date",
  "rental.booking.party": "Customer",
  "rental.booking.notification_pickup": "Notification Pickup",
  "rental.booking.comment": "Comment",
  "rental.booking.email": "Email",
  "rental.booking.address": "Address",
  "rental.booking.subdivision": "State",
  "rental.booking.suburb": "Suburb",
  "rental.booking.city": "City",
  "rental.booking.post_code": "Post code",
  "rental.booking.start_date": "Start date",
  "rental.booking.end_date": "End date",
  "rental.booking.lapse_time": "Weeks",
  "rental.booking.company": "Company",
  "rental.booking.send_confirmation_email": "Resend email",
  "rental.service.done": "Done",
  "rental.service.cancelled": "Cancelled",
  "rental.service.renewed": "Renewed",
  "rental.booking.add_signature": "Add signature",
  "maintenance.equipment.code": "Code",
  "maintenance.equipment.gps_link": "Gps link",
  "maintenance.equipment.gps_passwd": "Gps password",
  "maintenance.equipment.brand": "Brand",
  "maintenance.equipment.type_device": "Type device",
  "maintenance.equipment.date_return": "Date return",
  "maintenance.equipment.issues": "Issues",
  "maintenance.equipment.status": "Status",
  "maintenance.equipment.available": "Available",
  "maintenance.equipment.maintenance": "Maintenance",
  "maintenance.equipment.not_available": "No available",
  "maintenance.equipment.stolen": "Stolen",
  "maintenance.equipment.status": "Status",
  driver_license: "Driver License",
  passport: "Passport",
  medicare_card: "Medicare Card",
  phone: "phone",
  fax: "fax",
  email: "email",
  chat: "chat",
  walking: "walking",
  web: "web",
  channel_manager: "channel_manager",
  ota: "ota",
  "rest_order.options": "Opciones",
  "surveillance.schedule_month.report.period": "Period",
  "surveillance.schedule_month.report.location": "Location",
  "surveillance.schedule_month.report": "Compliance Report",
  "rental.service.add_signature": "Create Service",
  "maintenance.equipment.state": "State",
  "maintenance.equipment.excelñent": "Excellent",
  "maintenance.equipment.good": "Good",
  "maintenance.equipment.acceptable": "Acceptable",
  "maintenance.equipment.bad": "Bad",
  "maintenance.equipment.product": "Product",
  "maintenance.equipment.active": "Active",
  "maintenance.equipment.gps_serial": "Gps serial",
  "maintenance.equipment.serial": "Serial",
  "maintenance.equipment.vin": "Vin",
  "maintenance.equipment.model_num": "Model num",
  "maintenance.equipment": "EQUIPMENT AND INSTRUMENT",
  "menu.maintenance": "EQUIPMENT AND INSTRUMENT",
  "menu.rental": "RENTAL",
  "menu.rentalb": "BOOKING",
  "rental.service.party": "Customer",
  "rental.service.id_number": "Number",
  "rental.service.email": "Email",
  "rental.service.mobile": "Mobile",
  "rental.service.end_date": "End Date",
  "rental.service.equipment": "Equipment",
  "rental.service.state": "State",
  "rental.service.draft": "Draft",
  "rental.service.booking": "Booking",
  "rental.service.processed": "Processed",
  "rental.service.confirmed": "Confirmed",
  "rental.service.product_check_list": "PRODUCT CHECK LIST ",
  "rental.service.product_check_list.item": "Item ",
  "rental.service.product_check_list.state_delivery": "Start State",
  "rental.service.product_check_list.state_return": "Return Check",
  "rental.service.service_date": "Service Date",
  "rental.service.bank_account_number": "Bank Account Number",
  "rental.service.bank_bsb": "Bank BSB",
  "rental.service.hiring_time": "Weeks",
  "rental.service.issues_presented": "Issues Description",
  "rental.service.odometer_start": "Start Odometer",
  "rental.service.photo_link_party_id": "PHOTO PARTY ID",
  "rental.service.photo_link_agree": "PHOTO AGREE",
  "rental.service.cover_prices": "Cover Prices",
  "rental.service.odometer_end": "Odometer Return",
  "rental.service.part_missing": "Issues Known - Parts Missing / Broken",
  monthly: "Monthly",
  one_payment: "One Payment",
  bimonthly: "One Payment",
  biannual: "Biannual",
  annual: "Annual",
  "crm.customer_service": "Filing form",
  "sale.sale.confirmed": "Confirmado",
  "sale.sale.party.mobile": "Movil",
  "sale.sale.party.name": "Cliente",
  "party.addresses": "Direcciones",
  "party.addresses.street": "Calle",
  "party.addresses.country_code": "Pais",
  "party.addresses.department_code": "Departamento",
  "party.addresses.city_code": "Ciudad",
  "party.contact_mechanisms": "Metodos de contacto",
  "party.contact_mechanisms.type": "Tipo",
  "party.contact_mechanisms.name": "Nombre",
  "party.contact_mechanisms.value": "Dato de contacto",
  "sale.sale.party.id_number": "N° Documento",
  "date_range.select": "Select",
  "date_range.filter_date": "Date to filter",
  "sale.sale.reference": "Reference",
  "sale.sale.month": "Month",
  "sale.sale.month_3": "3 Month",
  "sale.sale.month_6": "6 Month",
  "sale.sale.printBrowser": "Print",
  agent: "Agent",
  news: "News",
  leads: "Leads",
  opportunity: "Opportunity",
  won: "Won",
  lost: "Lost",
  cancelled: "Cancelled",
  amount: "Amount ",
  success_rate: "Success Rate",
  product: "Product",
  accomodation: "Accommodation",
  "crm.person": "Acompañantes",
  "crm.person.name": "Nombre",
  "crm.person.id_number": "Num. Id",
  "crm.person.phone": "Telefono",
  "crm.person.email": "Correo",
  "sale.line.person": "Acompañantes",
  "sale.line.person.name": "Nombre",
  "sale.line.person.id_number": "Num. Id",
  "sale.line.person.phone": "Telefono",
  "sale.line.person.email": "Correo",
  "sale.line.person.arrival_date": "Arrival Date",
  "sale.line.person.today": "Hoy",
  "sale.line.person.month": "Mes",
  "sale.line.person.week": "Semana",
  "sale.line.person.line": "Servicio",
  "sale.line.person.sale": "Venta",
  "web.booking.avalaible": "Available",
  "web.booking.occupation": "Occupation",
  "web.booking.minimum_stay": "Minimium stay",
  "app.booking.name": "Name",
  "app.booking.lastname": "Last name",
  "app.booking.add_pay": "Add payment",
  "app.booking.sex": "Gender",
  "app.booking.type_document": "Document type",
  "app.booking.id_number": "N° Document",
  "app.booking.mobile": "Phone",
  "app.booking.email": "Email",
  "app.booking.address": "Address",
  "app.booking.btn-form": "RESERVE",
  "app.booking.btn-form-payment": "CHECKOUT",
  "app.booking.country": "Country",
  "app.booking.department": "Department",
  "app.booking.city": "City",
  "app.booking.msg_nodata": "No rooms available",
  "app.booking.msg_nodate": "Select Check-In and Check-Out",
  "app.booking.note": "Note",
  "menu.account_statement": "ACCOUNT STATEMENT",
  "account.statement": "Statement",
  "account.statement.name": "Name",
  "account.statement.journal": "Journal",
  "account.statement.sale_device": "Sale Device",
  "account.statement.end_balance": "End Balance",
  "account.statement.start_balance": "Start Balance",
  "account.statement.turn": "Turn",
  "account.statement.state": "State",
  "account.statement.draft": "Posted",
  "account.statement.validated": "Validated",
  "account.statement.posted": "Posted",
  "account.statement.reports": "Reports",
  "account.statement.select_device": "Select sales terminal",
  "web.booking.txt_iva": "Charges and taxes included",
  "app.booking.term_cond": "Terms & Conditions",
  "app.booking.privacy": "Privacy Policy",
  "sale_pos.money_count.bill": "Bill",
  "sale_pos.money_count.quantity": "Quantity",
  "sale_pos.money_count.amount": "Amount",
  "sale_pos.money_count.total": "Total",
  "app.booking.redirect": "You are being redirected to payment...",
  "app.booking.label_form": "Add the owner's information",
  "app.booking.label_aside": "Your Reservation",
  "app.booking.btn_confirm": "CONFIRM",
  "app.booking.amount": "Total:",
  "app.booking.right_reserved": "All rights reserved",
  "app.booking.copyright": "Presik Copyright © 2024",
  "app.booking.validate_email": " Valid your email",
  "app.booking.create_reserve": "Reservation created",
  "app.booking.successfully": "successfully",
  "app.booking.alert_filter_date":
    "Select a Check-out date less than the Check-in date. ",
  "app.booking.alert_select_room": "Select a room type for your reservation.  ",
  "app.booking.date_payment": "Payment date",
  "app.booking.night": "Night",
  "app.booking.price": "PRICE",
  "app.booking.includes": "Includes",
  "app.booking.btn_reserve_item": "Add reservation",
  "app.booking.btn_add_coupon": "Add coupon",
  "app.booking.note_placeholder":
    "Dear guest, please write us your special requests.",
  "quickform.want_to_save_data": "Do you want save the changes?",
  "sale_pos.money_count": "Conteo de Dinero",
  "hotel.booking.undefined": "Indefinido",
  "booking.panel.check_in_today": "Check-In Today",
  "booking.panel.check_out_today": "Check-Out Today",
  "booking.panel.pending_today": "Pending",
  "booking.panel.guests": "Guests",
  "booking.panel.guests_today": "Today",
  "booking.panel.guests_tomorrow": "Tomorrow",
  "booking.panel.last_reservations": "Last Reservations",
  "booking.panel.occupation_rate": "Occupation Rate",
  "booking.panel.occupation_rate_today": "Today",
  "booking.panel.occupation_rate_tomorrow": "Tomorrow",
  "ticket_system.ticket.status": "Status",
  "ticket_system.ticket.waiting": "Waiting",
  "ticket_system.ticket.done": "Done",
  "ticket_system.ticket.busy": "Busy",
  "ticket_system.ticket.cancelled": "Cancelled",
  "ticket_system.ticket.turn": "# Turn",
  "ticket_system.ticket.number_id": "# Document",
  "ticket_system.ticket.position.number": "Position",
  "ticket_system.ticket.state": "State",
  "ticket_system.ticke_not_found": "There are no shifts in waiting status",
  "ticket_system.ticket.add_turn": "You must assign yourself a turn",
  "ticket_system.ticket.next_turn": "NEXT TURN",
  "ticket_system.ticket.take_turn": "TAKE TURN",
  "ticket_system.ticket.finish_turn": "END TURN",
  "ticket_system.ticket.currents_turn": "Cashier shift",
  "ticket_system.ticket.attention_schedule": "Attention schedule",
  "ticket_system.ticket.weekday": "Mon - Fri",
  "ticket_system.ticket.wekend": "Sat - Sun",
  "hotel.calendar.add_booking": "Booking",
  "hotel.calendar.add_maintenance": "Maintenance",
  "hotel.guest.you_can_not_clone_holder":
    "No puede crear automáticamente un huesped si antes no ha creado el titular",
  "account_statement_co.advanced_report": "Statement Advanced Report",
  "account_statement_co.advanced": "Statement Advanced Report",
  "account_statement_co.advanced.description":
    "Statement including all shops / environments",
  "account_statement_co.advanced.start.operation_center": "Operation Center",
  "account_statement_co.advanced.start.date": "Date",
  "account_statement_co.cash_up_report": "Cash Up Report",
  "account_statement_co.cash_up": "Cash Up Report",
  "account_statement_co.cash_up.description": "Cash Up individual report",
  "account_statement_co.cash_up.start.operation_center": "Date",
  "account_statement_co.cash_up.start.date": "Date",
  "account_statement_co.cash_up.start.shop": "Shop",
  "account_statement_co.cash_up.start.user": "User",
  "hotel.calendar.select_date": "Select Date",
  "hotel.calendar.rates": "Tarifas y Disponibilidad",
  "hotel.calendar.update_range": "Update Range",
  "hotel.calendar.update_start_date": "Start Date",
  "hotel.calendar.update_end_date": "End Date",
  "hotel.folio.guest.adult": "Adult",
  "hotel.folio.guest.child": "Child",
  add_header_modal: "Add",
  "hotel.booking.update_taxes": "Update Taxes",
};

const _en = { ...en, ...views_en };
export default _en;
