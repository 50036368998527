import date from "date-and-time";

// const FMT_DATETIME = '%Y-%b-%d-  %I:%M %p'
const re = /^[0-9]+$/;

const getTrytonDate2Js = (value) => {
  if (value) {
    return date.parse(value, "YYYY-MM-DD");
  }
};

const getTrytonDate2Fmt = (value, format) => {
  const val = date.parse(value, "YYYY-MM-DD");
  return date.format(val, format);
};

const getTrytonDateTime2Js = (value, fromUTC = false, formatString = null) => {
  if (value) {
    let utcDatetime = date.parse(value, "YYYY-MM-DD HH:mm:ss");
    if (fromUTC) {
      utcDatetime = date.addHours(utcDatetime, -5);
    }
    if (formatString) {
      utcDatetime = date.format(utcDatetime, formatString);
    }
    return utcDatetime;
  }
};

const fmtDate = (value) => {
  let _value = value;
  if (value && typeof value === "string") {
    _value = date.parse(_value, "YYYY-MM-DD");
  }
  _value = date.format(_value, "DD-MM-YYYY");
  return _value;
};

const fmtDatetime = (value) => {
  if (value) {
    const value_ = date(new Date(value)).format("YYYY-MM-DD HH:mm");
    return value_;
  }
};

const fmtDatetimeForm = (value) => {
  if (value) {
    const value_ = date.format(value, "DD-MM-YYYY HH:mm");
    return value_;
  }
};

const fmtDatetimeTable = (value, format = true) => {
  if (value) {
    // const date = new Date(value);
    let formatString = format;
    if (!formatString) {
      formatString = "DD-MM-YYYY HH:mm";
    }
    const value_ = date.format(value, formatString);
    // const day = date.getDate();
    // const month = date
    //   .toLocaleString("default", { month: "long" })
    //   .substring(0, 3);
    // const year = date.getFullYear();
    // const hour = format ? `${date.getHours()}:${date.getMinutes()}` : "";
    // const value_ = `${day}/${month}/${year} ${hour}`;
    return value_;
  }
};

const dateToday = () => {
  const _date = new Date();
  return date.format(_date, "YYYY-MM-DD");
};

const getNow = () => {
  const _date = new Date();
  return _date;
};

const getHour = () => {
  const _date = new Date();
  return date.format(_date, "hh:mm");
};

const dateAdd = (value, delta, diff) => {
  if (value) {
    let _date;
    if (delta == "years") {
      _date = date.addYears(value, diff);
    } else if (delta === "months") {
      _date = date.addMonths(value, diff);
    } else if (delta === "days") {
      _date = date.addDays(value, diff);
    }
    return date.format(_date, "YYYY-MM-DD");
  }
  // const _date = new Date();
  return date.format(value, "YYYY-MM-DD");
};

const fmtDate2Tryton = (value) => {
  if (value) {
    return date.format(value, "YYYY-MM-DD");
  }
};

const fmtDateAndTime2Tryton = (dt, tm) => {
  const datetm = dt + " " + tm;
  const dateutc = date.format(datetm, "DD-MM-YYYY HH:mm A");
  const _datetm = dateutc.utc();
  return _datetm.format("YYYY-MM-DD HH:mm:ss");
};

const fmtDatetime2Tryton = (value) => {
  const val = date.addHours(value, 5);
  return date.format(val, "YYYY-MM-DD HH:mm:ss");
};

const fmtDatetime2Epoch = (value, fromDb) => {
  if (value) {
    let _date;
    if (fromDb) {
      _date = date.format(value, "YYYY-MM-DDTHH:mm");
    } else {
      _date = date.parse(value, "DD-MM-YYYY | hh:mm A");
    }
    return _date.getTime() / 1000;
  }
};

const fmtEpochToDatetime = (value) => {
  if (value) {
    const _val = new Date(value * 1000);
    let dateFormatted = date.format(_val, "YYYY-MM-DD HH:mm:ss", true);
    return dateFormatted;
  }
};

const fmtCurrency = (value) => {
  return value.toLocaleString("es", { useGrouping: true });
};

const isNumber = (value) => {
  return value === "" || re.test(value);
};

function subtractTimeFromDate(objDate, intHours) {
  var numberOfMlSeconds = objDate.getTime();
  var addMlSeconds = intHours * 60 * 60000;
  var newDateObj = new Date(numberOfMlSeconds - addMlSeconds);
  return newDateObj;
}

const tools = {
  fmtCurrency,
  fmtDate,
  fmtDatetime,
  fmtDatetimeForm,
  fmtDate2Tryton,
  fmtDatetime2Tryton,
  fmtDatetime2Epoch,
  fmtEpochToDatetime,
  getTrytonDate2Js,
  isNumber,
  dateToday,
  dateAdd,
  fmtDateAndTime2Tryton,
  subtractTimeFromDate,
  getTrytonDateTime2Js,
  fmtDatetimeTable,
  getNow,
  getHour,
  getTrytonDate2Fmt,
};

export default tools;
