import { React, Fragment } from "react";
import { signal } from "@preact/signals-react";
import { FormattedMessage as FM } from "react-intl";

import proxy from "api/proxy";
import { useWizardStore } from "store/wizardStore";
import OnScreenKeyboard from "components/Tools/OnScreenKeyboard";
import { Alert } from "components/Alerts/Alert";

const showAlert = signal(false);

const CodeVerification = ({ handleView }) => {
  const { updateWizard } = useWizardStore();

  const handleVerification = async (code) => {
    const { data } = await proxy.search(
      "company.employee",
      [["password_access", "=", code]],
      ["id", "party.name", "position.name", "department.name"],
    );
    if (data[0] && data.length > 0) {
      updateWizard(data[0]);
      handleView("step3");
      showAlert.value = false;
    } else {
      showAlert.value = true;
    }
  };

  return (
    <Fragment>
      <div className="w-full py-5 flex flex-col justify-center items-center  max-w-md ">
        <span className="text-center w-full text-white text-2xl pb-3">
          <FM id="attendance_control.warning_step2" />
        </span>
        {showAlert.value && (
          <Alert
            text="Codigo no encontrado"
            show={showAlert.value}
            onClose={() => (showAlert.value = false)}
            color="bg-red-300 text-red-800 mt-5"
            position="!w-full"
          />
        )}
        <OnScreenKeyboard
          onClick={handleVerification}
          onCancel={() => handleView("step1")}
          colorButtons="!bg-gray-300 !text-gray-800"
          limitNumber={10}
        />
      </div>
      <video />
    </Fragment>
  );
};

export default CodeVerification;
