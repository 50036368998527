import React from "react";
import { ArrowLeftCircleIcon } from "@heroicons/react/24/solid";

import DateRangeCard from "components/Tools/DateRangeCard";
import DateRangeCustomSelect from "./DateRangeCustomSelect";
import { signal } from "@preact/signals-react";

let numItems = 3;
if (window.innerWidth > 1600) {
  numItems = 5;
} else if (window.innerWidth > 1366) {
  numItems = 4;
} else if (window.innerWidth > 970) {
  numItems = 3;
}
const selectedDate = signal(null);
const today = signal(new Date());

const CalendarFilter = ({ action, styleContent = false }) => {
  const getDaysArray = (start, end) => {
    const daysArray = [];
    let currentDate = new Date(start);

    while (currentDate <= end) {
      daysArray.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return daysArray;
  };

  let startDate = new Date(today.value);
  startDate.setDate(today.value.getDate() - numItems);

  let endDate = new Date(today.value);
  endDate.setDate(today.value.getDate() + numItems);

  let daysBetweenDates = getDaysArray(startDate, endDate);

  const handleClick = (date_) => {
    selectedDate.value = new Date(date_);
    action(new Date(date_));
  };

  const handleClickCustom = (date_) => {
    selectedDate.value = new Date(date_);
    today.value = new Date(date_);
    action(new Date(date_));
  };

  const handleRangeDays = (e, direction) => {
    e.preventDefault();
    e.stopPropagation();
    let _today = new Date(today.value);
    if (direction == "previous") {
      _today.setDate(_today.getDate() - numItems);
    } else {
      _today.setDate(_today.getDate() + numItems);
    }
    today.value = new Date(_today);
    action(_today);
  };

  return (
    <div id="date-range" className={`grid mt-3 mb-8 ${styleContent}`}>
      <ul className="flex flex-row space-x-4 justify-center">
        <li className="flex">
          <ArrowLeftCircleIcon
            className="w-10 text-gray-800 cursor-pointer"
            onClick={(e) => handleRangeDays(e, "previous")}
          />
        </li>
        {daysBetweenDates.map((date_, index) => (
          <DateRangeCard
            key={index}
            date_={date_}
            selected={selectedDate.value}
            onClick={() => handleClick(date_)}
          />
        ))}
        <DateRangeCustomSelect onClick={handleClickCustom} />
        <li className="flex">
          <ArrowLeftCircleIcon
            className="w-10 text-gray-800 transform rotate-180 cursor-pointer"
            onClick={(e) => handleRangeDays(e, "next")}
          />
        </li>
      </ul>
    </div>
  );
};

export default CalendarFilter;
