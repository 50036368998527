import React from "react";
import { FormattedMessage as FM } from "react-intl";

import BasicModal from "components/Modals/BasicModal2";
import { classNames } from "tools/ui";

const base =
  "min-w-28 h-20 mx-auto p-2 rounded-lg hover:bg-neutral-200 active:scale-95 active:shadow-md cursor-pointer";
function ButtonAction({ icon, label, children, openModal, styleModal }) {
  let bgColor = "bg-white";
  let txtColor = "text-neutral-700";
  let style = "w-1/2";
  if (styleModal) {
    style = styleModal;
  }

  function onClose() {
    openModal.value = false;
  }

  async function onClick(e) {
    e.preventDefault();
    e.stopPropagation();
    openModal.value = true;
  }

  return (
    <div key={label}>
      <div onClick={onClick} className={classNames(base, bgColor, txtColor)}>
        {icon && <img src={icon} className="w-10 mx-auto" />}
        <div className="text-center mt-1">
          <span className="my-auto text-md text-neutral-600 ml-auto">
            <FM id={label} />
          </span>
        </div>
      </div>
      <BasicModal
        title={label}
        openModal={openModal}
        closeModal={onClose}
        style={style}
      >
        {children}
      </BasicModal>
    </div>
  );
}

export default ButtonAction;
