import React, { useState, useEffect } from "react";
import { FormattedMessage as FM } from "react-intl";
import date from "date-and-time";

import Screen from "components/Meta/Screen";
import Board from "components/Meta/Board";
import PanelRight from "components/Panel/PanelRight";
import HeaderPanel from "components/Panel/HeaderPanel";
import SectionSidePanel from "components/Panel/SectionSidePanel";
import MoreOptionsPanel from "components/Panel/MoreOptionsPanel";
import MinitablePanel from "components/Panel/MinitablePanel";
import Booking from "./Booking";
import { useStoreProducts } from "./storeProducts";
import { fields } from "./constants";
import proxy from "api/proxy";
import store from "store";

const fmt = "YYYY-MM-DD";
const MODEL = "hotel.booking";

const ScreenBooking = (props) => {
  const ctxView = Booking.ctxView(props.config);
  const [displayOpen, setDisplayMode] = useState(true);
  const [newCtxView, setNewCtxView] = useState(ctxView);
  const [filterDay, setFilterDay] = useState(date.format(new Date(), fmt));
  const [lastReservations, setLastReservations] = useState([]);
  const [checkData, setCheckData] = useState([]);
  const [guestsData, setGuestsData] = useState([]);
  const [occRate, setOccRate] = useState({});
  const { setProducts } = useStoreProducts();

  function handleChangeDate(value) {
    let daySelected = date.format(new Date(value), "YYYY-MM-DD");
    setFilterDay(daySelected);
  }

  async function getBookings() {
    let nextDay = new Date(filterDay);
    nextDay.setDate(nextDay.getDate() + 2);
    let nextDayFormatted = date.format(nextDay, "YYYY-MM-DD");
    let dom = [
      ["lines.arrival_date", ">=", filterDay],
      ["lines.arrival_date", "<=", nextDayFormatted],
    ];

    const { data } = await proxy.search("hotel.booking", dom, fields);
    if (data) {
      const newDomain = [["lines.arrival_date", "=", filterDay]];
      newCtxView.domain = newDomain;
      setNewCtxView(newCtxView);
    }
  }

  function handleDisplay(mode) {
    setDisplayMode(mode);
  }

  async function getLastReservations() {
    const dom = [];
    const order = [["id", "DESC"]];
    const limit = 5;
    const { data } = await proxy.search(MODEL, dom, fields, limit, order);
    setLastReservations(data);
  }

  async function getData() {
    const argsChecks = {
      model: "hotel.folio",
      method: "checks_info",
      args: [],
    };
    const { data: checks } = await proxy.methodCall(argsChecks);
    setCheckData(checks);

    const argsGuests = {
      model: "hotel.folio",
      method: "guests_info",
      args: [],
    };
    const { data: guests } = await proxy.methodCall(argsGuests);
    setGuestsData(guests);
  }

  async function getOccRate() {
    const argsOcc = {
      model: "hotel.folio",
      method: "occ_rate_info",
      args: [],
    };
    const { data } = await proxy.methodCall(argsOcc);
    setOccRate({
      title: "booking.panel.occupation_rate",
      elements: data,
    });
  }

  async function setHotelConfig() {
    const hotelConfig = store.get("hotelConfig");
    if (!hotelConfig) {
      const { data } = await proxy.search(
        "hotel.configuration",
        [],
        [
          "nationality.rec_name",
          "nationality.code",
          "taxes_auth.rec_name",
          "payment_term.name",
        ],
      );
      if (data) {
        store.set("hotelConfig", data[0]);
      }
    }
  }

  useEffect(() => {
    getBookings();
    getData();
    getOccRate();
    getLastReservations();
    setHotelConfig();
    setProducts();
  }, [filterDay]);

  return (
    <Screen className="inline-flex">
      <Board
        ctxView={newCtxView}
        calendar={true}
        handleCalendar={handleChangeDate}
        style="w-[75vw]"
      />
      <PanelRight
        title="360° SMART HOTEL"
        displayOpen={displayOpen}
        handleDisplay={handleDisplay}
      >
        <HeaderPanel
          title="booking.panel.status_info"
          dataInfo={occRate}
          name="status_info"
        />
        <SectionSidePanel key="checks" data={checkData} />
        <SectionSidePanel key="guests" data={guestsData} />
        <MinitablePanel
          title="booking.panel.last_reservations"
          data={lastReservations}
        />
        <MoreOptionsPanel title={<FM id="booking.panel.more_options" />} />
      </PanelRight>
    </Screen>
  );
};

export default ScreenBooking;
