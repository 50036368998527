import useMainStore from "store/mainStore";
import useChildStore from "store/childStore";
import useGrandStore from "store/grandStore";
import { useWizardStore } from "store/wizardStore";
import funcs from "tools/functions";

async function editTable({ level, action, fieldName, records, autoSave }) {
  // level
  // action: create, write, delete
  // records: (Optional) records to add or records to create
  let useStore;
  let parentStore;
  if (level === "child") {
    useStore = useChildStore.getState();
    parentStore = useMainStore.getState();
  } else if (level === "grand") {
    useStore = useGrandStore.getState();
    parentStore = useChildStore.getState();
  } else if (level === "wizard") {
    const { store, trigger } = useWizardStore.getState();
    // let storeTable = store[fieldName];
    // const toStore = { ...record }; //, ...activeRecord };
    // for (const rec of records) {
    //   console.log("this....", rec);
    //   storeTable.set(rec.id, rec);
    // }
    // console.log("storeTable....", storeTable, trigger);
    if (trigger) {
      trigger(store);
    }
    return;
  }
  const { record, store, save } = useStore;
  const {
    record: recordParent,
    store: storeParent,
    save: saveParent,
    reload: reloadParent,
    updateRecord: updateRecordParent,
  } = parentStore;
  let storeTable = storeParent[fieldName];
  let recordTable = recordParent[fieldName];
  console.log("recordTable > > > > > > ", recordTable);

  // TEMPORAL FIX FOR CARDS
  if (!recordTable) return;
  // console.log("store > > > > > > > ", storeParent);

  if (!storeTable) {
    // When storeTable does not exist we need create it
    storeTable = new Map();
    storeTable.set("delete", []);
    storeTable.set("create", new Map());
    storeTable.set("write", new Map());
  }

  let storeAction = storeTable.get(action);
  // console.log("record...", record);
  // console.log("action...", action);
  // console.log("level...", level);
  // console.log("records ...", records);
  // console.log("fieldName...", fieldName);
  // console.log("storeTable...", storeTable);
  // console.log("recordTable...", recordTable);
  // console.log("storeAction...", storeAction, record.id);

  let recId;
  if (action === "delete") {
    // FIXME: This must work with an Array not with an integer
    recId = records[0].id;
    recordTable.delete(recId);
    if (recId <= 0) {
      // We need call to create because this records are new
      let toCreate = storeTable.get("create");
      toCreate.delete(recId);
      await updateRecordParent(recordParent);
    } else {
      let toWrite = storeTable.get("write");
      toWrite.delete(recId);
      storeAction.push(recId);
      storeParent[fieldName] = storeTable;
      await saveParent();
      await reloadParent();
    }
    return;
  }

  let storeStd;
  if (action === "create") {
    // const actionCreate = storeTable.get("create");
    if (records) {
      for (const _record of records) {
        storeStd = funcs.recToTryton(_record);
        storeAction.set(_record.id, storeStd);
        recordTable.set(_record.id, _record);
      }
    } else if (record.id <= 0) {
      recordTable.set(record.id, record);
      storeStd = funcs.recToTryton(store);
      storeAction.set(record.id, storeStd);
    }

    storeParent[fieldName] = storeTable;
    if (autoSave && recordParent.id > 0) {
      await saveParent();
    } else {
      recordParent[fieldName] = recordTable;
    }
    await updateRecordParent(recordParent);
    return;
  }

  if (action === "write") {
    let rec = recordTable.get(record.id);
    rec = { ...rec, ...record };
    recordTable.set(record.id, rec);
    storeStd = funcs.recToTryton(store);
    storeAction.set(record.id, storeStd);
    storeParent[fieldName] = storeTable;
    if (autoSave) {
      await save();
      await reloadParent();
    } else {
      await updateRecordParent(recordParent);
    }
  }
}

export default editTable;
