import React, { useEffect } from "react";
import { signal } from "@preact/signals-react";

import proxy from "api/proxy";
import { CheckBadgeIcon } from "@heroicons/react/20/solid";
import { FormattedMessage as FM } from "react-intl";

const dataPayment = signal(null);
const title = signal(""); // Inicializa title como cadena vacía
const MODEL = "electronic.payment.";

const ScreenConfirm = () => {
  const querystring = window.location.search;
  const params = new URLSearchParams(querystring);
  const trackingNumber = params.get("tracking_number");

  const getStatusPayment = async () => {
    if (!trackingNumber) return;
    const { data } = await proxy.search(
      "electronic.payment",
      [["checkout_id", "=", trackingNumber]],
      ["id", "state"],
    );

    if (data.length > 0) {
      // Actualiza el título de manera segura
      title.value = MODEL + data[0]?.state;
      dataPayment.value = data[0];
    }
  };

  useEffect(() => {
    getStatusPayment();
  }, []);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white rounded-lg shadow-lg p-20 max-w-xl w-full text-center absolute">
        <CheckBadgeIcon className="w-20 h-20 mx-auto text-green-800" />

        {title.value &&
          title.value !== "" && ( // Asegúrate de que title tenga un valor válido
            <h1 className="text-5xl font-bold border-blue-presik mb-4">
              <FM id={title.value} />
            </h1>
          )}

        {trackingNumber && (
          <div className="mt-4">
            <p className="text-lg font-semibold text-gray-800">
              Tu ID de checkout es:
            </p>
            <span className="text-xl font-bold text-blue-500">
              {trackingNumber}
            </span>
          </div>
        )}

        <p className="text-lg text-gray-700">
          Disfruta de tu estancia en el hotel.
        </p>
        <span className="text-gray-500 w-full text-right absolute right-5 text-xs bottom-3">
          Power by Presik
        </span>
      </div>
    </div>
  );
};

export default ScreenConfirm;
