import React, { useEffect, useState } from "react";
import ModelSchedule from "./models/ModelSchedule.";
import ModelActivity from "./models/ModelActivity";
import ModelSale from "./models/ModelSale";
import ModelFolioWizard from "./models/ModelFolioWizard";
import ScreenActivity from "./ScreenActivity";
import funcs from "tools/functions";
import SelectDevice from "apps/AccountStatement/SelectDevice";
import { signal } from "@preact/signals-react";
import proxy from "api/proxy";
import store from "store";

const configuration = signal({});

const Activity = (props) => {
  const _terminal = funcs.getCookie();
  const session = store.get("ctxSession");
  const saleActivity = store.get("saleActivity");
  const { config } = props;

  const ctxView = ModelSale.ctxView({ ...config, ...configuration.value });
  const ctxViewActi = ModelActivity.ctxView(config);
  const ctxViewSchel = ModelSchedule.ctxView(config);
  const ctxViewFolio = ModelFolioWizard.ctxView(config);

  const sellerPermits = session?.groups?.some(
    (group) => group.name === "Venta en Reserva",
  );

  const updateSession = async () => {
    const _terminal = funcs.getCookie();
    // console.log(session, "this is session");
    // console.log("esta son las cookies", _terminal);

    if (!_terminal) return;
    let updateSaleActivity = {};
    let agent = saleActivity?.agent;
    let party = saleActivity?.party;
    let sale_device = saleActivity?.sale_device;
    let shop = { id: saleActivity?.shop, name: saleActivity?.shop_name };
    let modules_active = saleActivity?.modules_active;
    let allow_courtesy = saleActivity?.allow_courtesy;

    if (!sale_device) {
      sale_device = {
        id: parseInt(_terminal?.device_id),
        name: _terminal?.device_name,
      };
      if (_terminal?.shop_id != saleActivity?.shop) {
        shop = { id: parseInt(_terminal?.shop_id), name: _terminal?.shop_name };
        console.log("shop", shop);
        updateSaleActivity.sale_device = sale_device;
        updateSaleActivity.shop = parseInt(_terminal?.shop_id);
        updateSaleActivity.shop_name = _terminal?.shop_name;
      }
    }

    if (!agent) {
      const { data: agents } = await proxy.search(
        "commission.agent",
        [["user", "=", session.user]],
        ["rec_name"],
      );

      if (agents && agents.length > 0) {
        agent = agents[0];
        updateSaleActivity.agent = agent;
      }
    }
    console.log(_terminal, "-----------------");

    if (!party) {
      const { data: shops } = await proxy.search(
        "sale.shop",
        // [["id", "=", session.shop]],
        [["id", "=", _terminal.shop_id]],

        [
          "party.addresses",
          "party.mobile",
          "party.phone",
          "party.customer_payment_term",
          "party.name",
          "party.account_payable",
          "party.account_receivable",
          "party.name",
          "party.rec_name",
          "warehouse",
          "name",
        ],
      );

      if (shops[0]?.["party."]) {
        party = shops[0]["party."];
        updateSaleActivity.warehouse = shops[0]["warehouse"];
        updateSaleActivity.party = party;
      }
    }

    if (!modules_active) {
      const dom = [
        ["name", "in", ["hotel"]],
        ["state", "=", "activated"],
      ];
      const { data, error } = await proxy.search("ir.module", dom, [
        "name",
        "state",
      ]);
      modules_active = data.map((item) => item.name);
      updateSaleActivity.modules_active = modules_active;
    }

    if (!allow_courtesy) {
      const { data: configData } = await proxy.search(
        "sale.configuration",
        [],
        ["allow_courtesy"],
      );

      if (configData && configData.length > 0) {
        allow_courtesy = configData[0].allow_courtesy;
        updateSaleActivity.allow_courtesy = allow_courtesy;
      }
    }
    const { data: configDataActivi } = await proxy.search(
      "sale_activity.configuration",
      [],
      ["date_picker", "menu_view", "type_view"],
    );

    configuration.value = {
      ...configuration.value,
      modules_active,
      allow_courtesy,
      date_picker: configDataActivi[0]?.date_picker,
      menu_view: configDataActivi[0]?.menu_view,
      type_view: configDataActivi[0]?.type_view,
      shop,
    };
    if (!shop) {
      console.log("por que no llega esto");

      return false;
    }
    if (Object.keys(updateSaleActivity).length > 0) {
      await store.set("saleActivity", {
        ...saleActivity,
        ...updateSaleActivity,
      });
      await store.set("ctxSession", { ...session, shop: parseInt(shop.id) });
    }
  };

  const handleCurrentDevice = (item) => {
    updateSession();
  };

  useEffect(() => {
    updateSession();
  }, []);

  return (
    <div id="board" className="mx-4 my-3 w-full ">
      <style>
        {`
          #actions_modal {
            display:none
          }

        `}
      </style>
      {!_terminal?.device_id ? (
        <SelectDevice handleCurrent={handleCurrentDevice} />
      ) : (
        <ScreenActivity
          ctxView={ctxView}
          ctxViewAc={ctxViewActi}
          ctxViewSchel={ctxViewSchel}
          ctxViewFolio={ctxViewFolio}
          aside={true}
          position="right"
          permits={sellerPermits}
          configuration={configuration.value}
        />
      )}
    </div>
  );
};

export default Activity;
