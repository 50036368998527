import React from "react";
import { signal } from "@preact/signals-react";

import Board from "components/Meta/Board";
import statement from "./ModelStatement";
import Toolbar from "./Toolbar";
import funcs from "tools/functions";
import SelectDevice from "./SelectDevice";

const reloadBoard = signal(0);

const Statement = ({ config }) => {
  const terminal = funcs.getCookie();
  const ctxView = statement.ctxView(config);

  function reload() {
    reloadBoard.value += 1;
  }

  return (
    <div className="w-full">
      <Board
        ctxView={ctxView}
        style="max-w-7xl"
        Toolbar={<Toolbar reload={reload} />}
        reload={reloadBoard.value}
      />
      {!terminal.device_id && <SelectDevice />}
    </div>
  );
};

export default Statement;
