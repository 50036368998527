import dates from "tools/dates";
import date from "date-and-time";
import proxy from "api/proxy";

const getAvailableRooms = async (arrival, deaperture) => {
  const regex =
    /^[A-Za-z]{3} [A-Za-z]{3} \d{2} \d{4} \d{2}:\d{2}:\d{2} GMT([+-]\d{4}) \([A-Za-z\s]+ [A-Za-z]+\)$/;

  const formatDateIfValid = (date, fmtDate2Tryton) => {
    if (regex.test(date)) {
      return fmtDate2Tryton(date);
    } else {
      return null;
    }
  };

  let arrival_ = formatDateIfValid(arrival, dates.fmtDate2Tryton);
  let deaperture_ = formatDateIfValid(deaperture, dates.fmtDate2Tryton);
  const { data } = await proxy.methodCall({
    model: "hotel.room",
    method: "available_for_web",
    args: [arrival_, deaperture_, null],
    kwargs: {},
  });
  const allRooms = data.flatMap((item) => item.rooms);
  return allRooms;
};

const searchAvailableRooms = async (record, store) => {
  console.log(store.overbooking, "store.overbooking");
  const deaperture_date =
    store?.departure_date || store.parentRec?.departure_date;
  const arrival_date = store?.arrival_date || store.parentRec?.arrival_date;
  const product = store?.product?.id || store?.parentRec?.product.id;

  if (store?.overbooking) {
    return ["accommodation", "=", product];
  } else {
    let roomsAvalaible = await getAvailableRooms(arrival_date, deaperture_date);
    return [
      ["accommodation", "=", product],
      ["id", "in", roomsAvalaible],
    ];
  }
};

const withChangeDate = (record, store) => {
  const { arrival_date, departure_date, unit_price_w_tax } = record;
  const nights = getNights(
    arrival_date || store?.parentRec?.arrival_date,
    departure_date || store?.parentRec?.departure_date,
  );
  record.nights_quantity = nights;
  store.nights_quantity = nights;
};

function getNights(arrival, departure) {
  let arrival_date = arrival;
  let departure_date = departure;
  if (typeof arrival === "string") {
    arrival_date = dates.getTrytonDate2Js(arrival);
  }
  if (typeof departure_date === "string") {
    departure_date = dates.getTrytonDate2Js(departure);
  }
  return date.subtract(departure_date, arrival_date).toDays();
}

const getView = (props) => {
  console.log(props, "props");
  let editCheckIn = props?.registration_state == "check_in";

  let DictCtxView = {
    model: "hotel.folio",
    // model: "hotel.folio_update.start",
    form_action: ["add", "edit", "delete"],
    table_action: [],
    domain: [],
    orderBy: [["sale_date", "ASC"]],
    limit: 100,
    target: "folio",
    webfields: {
      arrival_date: {
        type: "date",
        readOnly: editCheckIn,
        withChange: withChangeDate,
      },
      overbooking: {
        type: "boolean",
        translate: true,
        // readOnly: edit,
      },
      departure_date: {
        type: "date",
        // readOnly: editCheckOut,
        withChange: withChangeDate,
      },
      unit_price: {
        type: "numeric",
        // readOnly: edit,
      },
      room: {
        type: "many2one",
        mode: "card",
        model: "hotel.room",
        recSearch: searchAvailableRooms,
        // readOnly: edit,
      },
      product: {
        type: "many2one",
        model: "product.product",
        recSearch: () => ["department", "=", "accommodation"],
        // readOnly: edit,
      },

      accept: {
        type: "button",
        method: "handleAccept",
        color: "blue",
        // visible: () => !edit,
      },
    },

    webtree: [],
    webform: [
      {
        id: "dates",
        grid: [{ name: "arrival_date" }, { name: "departure_date" }],
        size: [1, 2],
        span: "md:col-span-2",
      },
      { name: "unit_price" },
      { name: "overbooking" },
      { name: "product" },
      { name: "room" },

      { name: "accept", colspan: "md:col-span-2" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
