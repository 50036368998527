// Spanish translation

const es = {
  "hotel.booking.number": "Número",
  "hotel.booking.party": "Titular",
  "hotel.booking.contact": "Contacto",
  "hotel.booking.bill_to": "Facturar a",
  "hotel.booking.bill.all": "Todo",
  "hotel.booking.bill.partial": "Parcial",
  "hotel.booking.booking_date": "Fecha de Reserva",
  "hotel.booking.total_amount": "Importe",
  "hotel.booking.total_advances": "Total Anticipos",
  "hotel.booking.lines": "No. Hab",
  "hotel.booking.media": "Medio",
  "hotel.booking.meal_plan": "Regimen Comida",
  "hotel.booking.space": "Espacio",
  "hotel.booking.price_list": "Lista de Precios",
  "hotel.booking.payment_term": "Plazo de Pago",
  "hotel.booking.rate_plan": "Plan Tarifario",
  "hotel.booking.pending_to_pay": "Pendiente de Pago",
  "hotel.booking.group": "Grupo",
  "hotel.booking.corporative": "Corporativo",
  "hotel.booking.state": "Estado",
  "hotel.booking.offer": "Oferta",
  "hotel.booking.complimentary": "Complimentary",
  "hotel.booking.confirm": "Confirmar",
  "hotel.booking.cancelled": "Cancelado",
  "hotel.booking.no_show": "No Presentado",
  "hotel.booking.block": "Blockear",
  "hotel.booking.finished": "Terminado",
  "hotel.booking": "RESERVA",
  "hotel.booking.today": "Hoy",
  "hotel.booking.confirmed": "Confirmada",
  "hotel.booking.bill": "Facturar",
  "hotel.booking.channel": "Canal",
  "hotel.booking.ota_booking_code": "Cod. OTA",
  "hotel.booking.agent": "Agente",
  "hotel.booking.notes": "Notas",
  "hotel.booking.advances_payments": "Anticipos/Pagos",
  "hotel.booking.invoices": "Facturas",
  "hotel.booking.notifications": "Notificaciones",
  "hotel.booking.pax": "PAX",
  "hotel.booking.taxes_exception": "Excepción de IVA",
  "hotel.booking.holder": "Titular",
  "hotel.booking.holder_guest": "Titular / Huesped",
  "hotel.booking.guest": "Huesped",
  "hotel.booking.blocked": "Bloqueo",
  "hotel.folio": "FOLIO",
  "hotel.booking.lead_origin": "Origen",
  "hotel.booking.apply_taxes_exception": "Excepción IVA",
  "hotel.folio.overbooking": "Overbooking",
  "hotel.folio.accept": "Aceptar",
  "hotel.booking.add_rooms": "Agregar Habitación",
  "hotel.booking.add_rooms.accept": "Aceptar",
  "hotel.booking_statement": "PRE-CUENTA",
  "hotel.booking.add_payment": "Anticipo / Pago",
  "hotel.booking.plan+rate": "Plan + Tarifas sin impuestos",
  "hotel.booking.fees_taxes_not_included": "CARGOS E IMPUESTOS NO INCLUIDOS",
  "hotel.booking.msg_iva_foreign":
    "Impuesto IVA no incluido a extranjeros, previa verificación de pasaporte y tarjeta migratoria al momento del check-in.",
  "hotel.booking.msg_iva": "IVA incluido del 19%.",
  "hotel.booking.i_foreigner": "Soy Extranjero",
  "hotel.booking.i_colombiano": "Soy Colombiano",
  "hotel.booking.add_rooms.selector.product": "Categoria",
  "hotel.booking.add_rooms.selector.unit_price_w_tax": "Tarifa",
  "hotel.booking.add_rooms.selector.available": "Disponibles",
  "hotel.booking.add_rooms.selector.adults": "Adultos",
  "hotel.booking.add_rooms.selector.children": "Niños",
  "hotel.booking.add_rooms.selector.room": "Hab.",
  "hotel.booking.add_rooms.selector.add": "Agregar",
  "hotel.booking.add_rooms.selector.sale_price_taxed": "Precio",
  "hotel.booking.add_rooms.selector.discount": "Desc.",
  "hotel.booking.add_rooms.nights_quantity": "Noches",
  "hotel.booking.add_payment.amount": "Valor",
  "hotel.booking.add_payment.voucher": "Voucher",
  "hotel.booking.add_payment.statement": "Medio de Pago",
  "hotel.booking.add_payment.pay": "Pagar",
  "hotel.booking.add_room.arrival_date": "Llegada",
  "hotel.booking.add_room.departure_date": "Salida",
  "hotel.booking.add_room.nights_quantity": "Noches",
  "hotel.booking.add_rooms.selector.rooms": "Hab.",
};

export default es;
