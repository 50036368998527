import React from "react";
import { signal } from "@preact/signals-react";

import CharInlineField from "components/MeForm/CharInlineField";

const data = signal(0);
const field = {
  label: "account.statement.start_balance",
  name: "start_balance",
  type: "number",
};

function StepOpen() {
  const attrs = { level: "wizard" };

  return (
    <div key="account-statement-open" className="my-6 mx-24">
      <CharInlineField field={field} attrs={attrs} data={data} />
    </div>
  );
}

export default StepOpen;
