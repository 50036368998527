import InvoiceLine from "views/Invoice/InvoiceLine";

const getName = (record) => {
  return record.number;
};

const INVOICE_TYPE = [
  { id: "1", name: "electronic_invoice" },
  { id: "P", name: "POS" },
];

function visibleSend(fieldName, record) {
  let res = false;
  if (record.state === "draft") {
    res = true;
  }
  return res;
}

const getView = () => {
  let DictCtxView = {
    model: "account.invoice",
    row_selectable: false,
    form_action: ["open"], // options: ['save', 'delete']
    table_action: ["open"], // options: ['open', 'delete', 'edit', 'add']
    // activeSearch: true,
    form_rec_name: getName,
    reports: [
      {
        name: "electronic_invoice_co.invoice_face",
        description: "Factura Elect.",
        send_print: true,
        send_email: "send_email",
      },
    ],
    webfields: {
      number: {
        type: "char",
        readOnly: true,
        searchable: true,
      },
      reference: {
        type: "char",
        readOnly: true,
        searchable: true,
      },
      invoice_type: {
        type: "selection",
        options: INVOICE_TYPE,
        translate: true,
        readOnly: true,
      },
      party: {
        type: "many2one",
        model: "party.party",
        searchable: true,
        readOnly: true,
      },
      payment_term: {
        type: "many2one",
        model: "account.invoice.payment_term",
        readOnly: true,
        // default: 1,
      },
      lines: {
        type: "one2many",
        model: "account.invoice.line",
        ctxView: InvoiceLine.ctxView(),
        readOnly: true,
      },
      total_amount: { type: "numeric", readOnly: true },
      tax_amount: { type: "numeric", readOnly: true },
      untaxed_amount: { type: "numeric", readOnly: true },
      description: {
        type: "char",
        readOnly: true,
      },
      invoice_date: {
        type: "date",
        readOnly: true,
      },
      state: {
        type: "char",
        translate: true,
        readOnly: true,
      },
      send: {
        type: "button",
        // button_method: "process_invoice",
        // ONLY FOR TESTING NOT SEND TO DIAN
        button_method: "validate_invoice",
        visible: visibleSend,
        onSuccessMsg: "Enviada exitosamente!",
        color: "blue",
      },
    },
    webtree: [
      { name: "number", width: "10%" },
      { name: "invoice_date", width: "10%" },
      { name: "party", width: "20%" },
      { name: "description", width: "20%" },
      { name: "total_amount", width: "15%" },
      { name: "state", width: "10%" },
    ],
    webform: [
      { name: "party" },
      {
        id: "number",
        grid: [{ name: "invoice_date" }, { name: "number" }],
        size: [1, 2],
      },
      { name: "payment_term" },
      { name: "reference" },
      { name: "description", colspan: true },
      { name: "lines" },
      {
        id: "amounts",
        grid: [
          { name: "state" },
          { name: "untaxed_amount" },
          { name: "tax_amount" },
          { name: "total_amount" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      { name: "send", colspan: true },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
