import React, { useEffect } from "react";
import { signal } from "@preact/signals-react";

import proxy from "api/proxy";
import dates from "tools/dates";
import store from "store";
import { useWizardStore } from "store/wizardStore";
import ButtonAction from "components/Buttons/ButtonAction";
import StepForm from "components/Step/StepForm";
import StatementReportModel from "./StatementReportModel";
import CashUpReportModel from "./CashUpReportModel";
import MenuReport from "components/Report/MenuReport";
import iconOpenBox from "assets/apps/open-box.png";
import iconCloseBox from "assets/apps/close-box.png";
import { openView, closeView, resetClose } from "./views";

const openModalOpen = signal(false);
const openModalClose = signal(false);
const _toStore = signal(null);

const base = "flex divide-x *:px-2";

function Toolbar({ reload }) {
  const { updateWizard } = useWizardStore();

  const fieldsDefault = async () => {
    const ctxSession = store.get("ctxSession");
    const { data: dataShop } = await proxy.browse(
      "sale.shop",
      [ctxSession?.shop],
      ["name", "operation_center.name"],
    );
    const date = dates.dateToday();
    const shop = { name: dataShop[0]?.name, id: dataShop[0]?.id };
    const operation_center = dataShop[0]?.["operation_center."];
    const user = { id: ctxSession?.user, name: ctxSession.user_name };
    const toStore = { user, shop, operation_center, date };
    // const toStore = { user, shop, operation_center, date: new Date() };
    _toStore.value = toStore;
    updateWizard(toStore);
  };

  const reports = [
    {
      name: "account_statement_co.advanced",
      description: "account_statement_co.advanced.description",
      ctxView: StatementReportModel.ctxView(_toStore.value),
    },
    {
      name: "account_statement_co.cash_up",
      description: "account_statement_co.cash_up.description",
      ctxView: CashUpReportModel.ctxView(_toStore.value),
    },
  ];
  useEffect(() => {
    fieldsDefault();
  }, []);

  return (
    <div className={base}>
      <ButtonAction
        label="account.statement.open"
        icon={iconOpenBox}
        openModal={openModalOpen}
      >
        <StepForm
          key="form-account-statement-open"
          name="open"
          values={openView}
          openModal={openModalOpen}
          reload={reload}
        />
      </ButtonAction>
      <ButtonAction
        label="account.statement.close"
        icon={iconCloseBox}
        openModal={openModalClose}
        styleModal="h-fit"
      >
        <StepForm
          key="form-account-statement-close"
          name="close"
          values={closeView}
          openModal={openModalClose}
          reset={resetClose}
          reload={reload}
        />
      </ButtonAction>
      <MenuReport reports={reports} />
    </div>
  );
}

export default Toolbar;
