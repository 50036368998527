import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { classNames } from "tools/ui";
import dates from "tools/dates";

const formatter = Intl.NumberFormat("en-US");
const FMT = "DD.MMM.YYYY";
const base =
  "inline-block h-60 cursor-pointer active:scale-95 active:shadow-xl rounded-2xl border border-neutral-300 shadow-md shadow-gray-200 overflow-hidden sm:w-64 md:w-60 lg:w-60 xl:w-72";
const divHead = "flex px-4 text-neutral-100 bg-neutral-700 justify-between";
const divContent = "h-full px-4 bg-neutral-200 text-neutral-600 text-lg";
// const btnDeleteStyle =
//   "fi fi-sr-trash cursor-pointer text-2xl text-rose-400 text-right mr-auto";

function CardFolio(props) {
  const { record, ctxView, onClick } = props;
  const { model, card_action = [] } = ctxView;

  const key = record.id;

  let clickable = "";
  let selectable = card_action.includes("open");
  if (selectable) {
    clickable = "cursor-pointer active:scale-95 active:shadow-xl";
  }

  let actionDelete = false;
  if (card_action) {
    for (const action of card_action) {
      if (action === "delete") {
        actionDelete = true;
      }
    }
  }

  function handleClick(record) {
    onClick(record);
    // if (selectable) {
    // }
  }
  console.log(" record ...", record);
  const state = `${model}.${record.registration_state}`;

  const arrival_date = dates.getTrytonDate2Fmt(record.arrival_date, FMT);
  const departure_date = dates.getTrytonDate2Fmt(record.departure_date, FMT);
  return (
    <div
      key={key}
      className={classNames(base, clickable)}
      onClick={() => handleClick(record)}
    >
      <div className={divHead}>
        <div className="p-3 font-bold text-xl">{record.room.rec_name}</div>
        <div className="p-1 my-auto">
          <span className="pr-2 text-neutral-100">{record.pax}</span>
          <i className="text-neutral-100 fi fi-rr-users"></i>
        </div>
      </div>
      <div className={divContent}>
        <div className="flex text-lg py-3 font-bold text-sky-600 justify-between">
          <span>{record.product.name}</span>
        </div>
        <div className="flex justify-between">
          <span>{arrival_date}</span>
          <i className="mx-3 fi fi-rr-right text-sky-600"></i>
          <span>{departure_date}</span>
        </div>
        <div className="flex my-2 justify-between">
          <span>{record.nights_quantity} noches</span>
          <div>
            <span className="text-neutral-400">$ &nbsp; </span>
            <span>{formatter.format(record.unit_price_w_tax || 0)}/noc</span>
          </div>
        </div>
        <div className="flex p-2 w-fit text-green-700 bg-green-200 justify-between rounded-lg">
          <span>
            <FM key={state} id={state} />
          </span>
        </div>
        <div className="flex text-lg text-neutral-500 justify-between"></div>
      </div>
    </div>
  );
}

export default CardFolio;
