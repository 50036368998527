import React, { Fragment, useEffect } from "react";
import { signal } from "@preact/signals-react";

import FormWizard from "components/MeForm/FormWizard";
import ModelWizardBill from "./ModelWizardBill";
import { useWizardStore } from "store/wizardStore";
import mainStore from "store/mainStore";
import proxy from "api/proxy";
import { useBtnLoading } from "store/btnLoading";
import { Alert } from "components/Alerts/Alert";

const msgAlert = signal({
  status: false,
  text: "",
  color: "",
  icon: "",
});

const WizardBill = ({ onClose, parentRec }) => {
  const { store, reset } = useWizardStore();
  const { record, reload } = mainStore();
  const { setButtonLoading } = useBtnLoading();

  useEffect(() => {
    msgAlert.value = {
      status: false,
      text: "",
      color: "",
      icon: "",
    };
  }, []);

  async function doInvoice() {
    if (!store.party || !store.kind) {
      setButtonLoading(false);
      msgAlert.value = {
        status: true,
        text: "Llena todos los campos",
        color: "bg-red-300",
        icon: "xmark",
      };
      return false;
    }
    let foliosIds = [];
    for (const folioId of record.lines.keys()) {
      foliosIds.push(folioId);
    }
    // Charges
    let args = [record.id, foliosIds, store.party];
    if (store.selectedRows) {
      const charges_ids = Array.from(store.selectedRows.values()).map(
        (row) => row.id,
      );
      args.push(charges_ids);
    }
    const model = "hotel.booking";
    const method = "create_invoice";
    await proxy.methodCall({ model, method, args });
    onClose();
    reset();
    reload();
  }

  return (
    <Fragment>
      {msgAlert.value?.status && (
        <Alert
          show={msgAlert.value?.status}
          onClose={() => (msgAlert.value = false)}
          text={msgAlert.value?.text}
          color={msgAlert.value?.color}
          icon={msgAlert.value?.icon}
          autoRemove={true}
        />
      )}
      <FormWizard
        key="hotel-booking-wizard-bill"
        ctxView={ModelWizardBill.ctxView()}
        parentRec={{ record: record }}
        handleButton={doInvoice}
      />
    </Fragment>
  );
};

export default WizardBill;
