import React from "react";

const colors = {
  blue: "bg-cyan-300",
  bluePresik: "bg-blue-presik text-white",
  rose: "bg-rose-200",
  yellow: "bg-amber-200",
  green: "bg-lime-200",
  gray: "border-gray-300 bg-gray-200 text-center text-gray-800",
  current: "border-green-600 bg-green-400 !text-green-800 font-semibold",
};

function CardBasic({
  onClick,
  children,
  color,
  record,
  current,
  size = "w-64",
  margin = "my-4 mx-4",
}) {
  const _color = !current ? colors[color] : colors.current;
  return (
    <div
      className={`${_color} ${size} ${margin} rounded-lg p-4 shadow-lg cursor-pointer border flex items-center`}
      onClick={onClick}
    >
      {children || (
        <span className="text-center w-full">
          {record.rec_name || record.name}
        </span>
      )}
    </div>
  );
}

export default CardBasic;
