import Modelsaleline from "./ModelSaleLine";
import { checkRequiredForm } from "tools/form";

const searchParty = (value) => {
  if (!value) return [];
  const dom = [
    "OR",
    ["name", "ilike", `%${value}%`],
    ["id_number", "ilike", `${value}%`],
  ];
  return dom;
};

const getTotalAmount = (record) => {
  const { delivery_amount, lines } = record;
  const delivery =
    delivery_amount && typeof delivery_amount === "string"
      ? Number(delivery_amount.replaceAll(",", ""))
      : 0;
  let totalAmount = delivery;
  lines?.forEach((item) => {
    totalAmount = totalAmount + parseFloat(item.total_amount);
  });
  return totalAmount;
};

const validateConfirm = (field, toActive) => {
  const storeRequired = ["lines", "shop", "source", "kind", "consumer"];
  const res = checkRequiredForm(toActive, storeRequired);
  return res;
};

const readOnlyParty = (record) => {
  return record.state !== "draft";
};

const searchConsumer = (value) => {
  if (!value) return [];
  const dom = [
    "OR",
    ["name", "ilike", `%${value}%`],
    ["id_number", "ilike", `${value}%`],
    ["phone", "ilike", `${value}%`],
  ];
  return dom;
};

const paymentMethod = [
  { id: "terminal", name: "terminal" },
  { id: "cash", name: "cash" },
  { id: "partial_paid", name: "partial_paid" },
  { id: "all_paid", name: "all_paid" },
];

const kind = [
  { id: "take_away", name: "take_away" },
  { id: "to_table", name: "to_table" },
  { id: "delivery", name: "delivery" },
];

const getView = () => {
  let DictCtxView = {
    row_selectable: false,
    activeSearch: true,
    model: "sale.sale",
    form_action: ["add"],
    table_action: ["edit", "add", "delete"],
    webfields: {
      total_amount: {
        type: "numeric",
        readOnly: true,
        depends: ["lines", "delivery_amount"],
        function: getTotalAmount,
      },
      number: { type: "char", readOnly: true },
      party: {
        type: "many2one",
        model: "party.party",
        recSearch: searchParty,
        readOnly: readOnlyParty,
      },
      source: {
        type: "many2one",
        model: "sale.source",
        readOnly: true,
        required: true,
      },
      consumer: {
        type: "many2one",
        model: "party.consumer",
        recSearch: searchConsumer,
        required: true,
      },
      sale_date: {
        type: "date",
        readOnly: { state: ["quotation"] },
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
      },
      order_status: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
      },
      shop: {
        type: "many2one",
        model: "sale.shop",
        readOnly: true,
        required: true,
      },
      position: {
        type: "char",
      },
      lines: {
        type: "one2many",
        model: "sale.line",
        ctxView: Modelsaleline.ctxView(),
      },
      delivery_amount: { type: "numeric", withChange: getTotalAmount },
      description: { type: "char", readOnly: { state: ["quotation"] } },
      kind: {
        type: "selection",
        options: kind,
        readOnly: true,
        translate: true,
        required: true,
      },
      comment: { type: "text", readOnly: { state: ["quotation"] } },
      payment_method: {
        type: "selection",
        options: paymentMethod,
        required: true,
        default: { id: "cash", name: "cash" },
        translate: true,
      },
      confirm: {
        type: "button",
        method: "dash_create_order_call",
        resetForm: true,
        visible: validateConfirm,
      },
    },
    webtree: [
      { name: "number", width: "20%" },
      { name: "sale_date", width: "25%" },
      { name: "shop", width: "25%" },
      { name: "party", width: "25%" },
      { name: "consumer", width: "25%" },
      { name: "total_amount", width: "25%" },
      { name: "order_status", width: "30%" },
    ],
    webform: [
      {
        id: "infoSale",
        grid: [{ name: "source" }, { name: "shop" }, { name: "kind" }],
        size: [1, 3],
        span: "col-span-1",
      },
      { name: "consumer" },
      { name: "party" },
      {
        id: "infoSale2",
        grid: [
          { name: "position" },
          { name: "payment_method" },
          { name: "delivery_amount" },
        ],
        size: [1, 4],
        span: "col-span-2",
      },
      { name: "lines" },
      { name: "comment" },
      { name: "total_amount" },
      { name: "confirm" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
