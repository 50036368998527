import React, { useState } from "react";

import SectionHeader from "components/Meta/SectionHeader";
import GridCards from "components/Card/GridCards";
import { useRecords } from "hooks/records";
import funcs from "tools/functions";

/**
 * @param {Array } ctxView
 * @param {Array} domain
 * @param {Function} onChangeView
 * @param {Array} parent
 * @return
 */

function QuickCards(props) {
  const { ctxView, parent } = props;
  const { model } = ctxView;
  const [domain, setDomain] = useState(ctxView.domain || props.domain);
  // const [limit, setLimit] = useState(ctxView.limit ?? 100);
  const limit = ctxView.limit ?? 100;
  const [selectAll, setSelectAll] = useState(false);
  const [offset, setOffset] = useState(0);

  // let startView = "list";
  // if (ctxView.defaultView) {
  //   startView = ctxView.defaultView;
  // }
  const fields_names = funcs.getViewFields(ctxView);

  let order = ctxView.orderBy;
  let { data, refetch, isLoading } = useRecords(
    model,
    domain,
    fields_names,
    order,
    limit,
    offset,
  );

  let searchables = [];
  for (const [field, attrs] of Object.entries(ctxView.webfields)) {
    if (attrs.searchable) {
      searchables.push({
        name: field,
        type: attrs.type,
      });
    }
  }

  let actionButtons = [];
  if (ctxView.table_action.includes("remove")) {
    const action = "remove";
    if (
      (Array.isArray(action) && action.includes(parent?.state)) ||
      action === true
    ) {
      if (!props.readOnly) {
        actionButtons.push("button_remove");
      }
    }
  }
  if (ctxView.table_action.includes("update")) {
    actionButtons.push("button_edit");
  }

  function onChangePage(_activePage) {
    setOffset((_activePage - 1) * limit);
  }

  function reloadRecords() {
    if (refetch) {
      refetch();
    }
  }

  async function handleFilter(filter) {
    setDomain(filter);
    // setLimit(1000);
  }

  function handleSelectAll(value) {
    setSelectAll(value);
  }

  const activeSearch = props.activeSearch || ctxView.activeSearch;
  const _countRecords = data ? data.length : 0;

  // This fix a bug in react-query v4
  const loading = isLoading && domain;
  // https://github.com/TanStack/query/issues/3584

  return (
    <div id="quick-cards" className="grid w-full">
      <SectionHeader
        {...props}
        activeSearch={activeSearch}
        searchables={searchables}
        countRecords={_countRecords}
        handleFilter={handleFilter}
        handleSelectAll={handleSelectAll}
      />
      <GridCards
        {...props}
        records={data}
        isLoading={loading}
        limit={limit}
        offset={offset}
        countRecords={_countRecords}
        actionButtons={actionButtons}
        selectAll={selectAll}
        reloadRecords={reloadRecords}
        onChangePage={onChangePage}
      />
    </div>
  );
}

export default QuickCards;
