// Spanish translation

const es = {
  "hotel.folio.registration_state": "Status",
  "hotel.folio.room": "Habitacion",
  "hotel.folio.main_guest": "Huesped Principal",
  "hotel.folio.nights_quantity": "Noches",
  "hotel.folio.arrival_date": "Llegada",
  "hotel.folio.departure_date": "Salida",
  "hotel.folio.notes": "Notas",
  "hotel.folio.check_in": "Check-In",
  "hotel.folio.check_out": "Check-Out",
  "hotel.folio.reverse_checkout": "Reversar Check-Out",
  "hotel.folio.confirm": "Confirmar",
  "hotel.folio.pending": "Pendiente",
  "hotel.folio.booking": "Reserva",
  "hotel.folio.unit_price": "Precio Unit.",
  "hotel.folio.registration_card": "Tarjeta de Registro",
  "hotel.folio.product": "Acomodación",
  "hotel.folio.channel": "Canal",
  "hotel.folio.contact": "Contacto",
  "hotel.folio.group": "Grupo",
  "hotel.folio.total_amount": "Total",
  "hotel.folio.payment_status": "Payment Status",
  "hotel.folio.bed_breakfast": "Cama & Desayuno",
  "hotel.folio.no_breakfast": "Sin Desayuno",
  "hotel.folio.all_inclusive": "All Inclusive",
  "hotel.folio.full_american": "Full American",
  "hotel.folio.half_american": "Half American",
  "hotel.folio.num_adults": "Adultos",
  "hotel.folio.num_children": "Niños",
  "hotel.folio.number": "Número",
  "hotel.folio.charge.folio": "Folio",
  "hotel.folio.charge.order": "Orden",
  "hotel.folio.charge.kind": "Clase",
  "hotel.folio.charge.invoice_line": "Factura",
  "hotel.folio.occupancy": "Ocupación",
  "hotel.folio.occupancy.occupancy_date": "Fecha",
  "hotel.folio.occupancy.unit_price": "Precio",
  "hotel.folio.occupancy.unit_price_w_tax": "Precio Imp.",
  "hotel.folio.occupancy.charge": "Cargo",
  "hotel.folio.occupancy.state": "Estado",
  "hotel.folio.occupancy.confirmed": "Confirmad0",
  "hotel.folio.occupancy.invoiced": "Facturado",
  "hotel.folio.occupancy.draft": "Borrador",
  "hotel.folio.pax": "PAX",
  "hotel.folio.load_accommodation": "Cargar Tarifas",
  "hotel.folio.update_folio": "Actualizar Folio",
  "hotel.folio.accommodation": "Acomodación",
  "hotel.folio.guest.city": "Ciudad",
};

export default es;
