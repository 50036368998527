import { signal } from "@preact/signals-react";
import CardBasic from "components/Card/CardBasic";

import upStore from "store/upStore";
import { classNames, colors } from "tools/ui";

const style = "grid gap-3";
let _size = "p-4";

function SelectionCardField(props) {
  const { field, attrs, data } = props;
  const { name, readOnly, options = [], label, sizeLabel = "text-md" } = field;
  const { level, numCol = "4", min = false } = attrs;
  let col = `grid-cols-${numCol}`;
  _size = min ? _size : "";

  let color = "bg-transparent";
  let disabled = "";
  if (readOnly) {
    color = colors.readOnly;
    disabled = "pointer-events-none";
  }

  function selectedItem(item) {
    console.log(item, "item");

    data.value = item;
    upStore(level, {
      fieldName: name,
      value: item,
      field: field,
    });
  }

  return (
    <div className="w-full pb-5">
      {label && (
        <h3
          className={classNames(
            "mt-3 mb-2 ml-1 font-bold text-zinc-500 max-h-5",
            sizeLabel,
          )}
        >
          {label}
        </h3>
      )}
      <div className={classNames(style, col, color, _size, disabled)}>
        {options.map((rec) => (
          <CardBasic
            key={rec.id}
            onClick={() => selectedItem(rec)}
            record={rec}
            color="gray"
            current={data.value?.id === rec.id}
            size="w-full"
            margin=""
          />
        ))}
      </div>
    </div>
  );
}

export default SelectionCardField;
