import React, { Fragment, useEffect } from "react";
import { signal } from "@preact/signals-react";

import SelectionCardField from "components/MeForm/SelectionCardField";
import StdButton from "components/Buttons/StdButton";
import CharInlineField from "components/MeForm/CharInlineField";
import { Alert } from "components/Alerts/Alert";
import { statementField, amountField, voucherField } from "./PaymentFields";
import { useWizardStore } from "store/wizardStore";
import { useBtnLoading } from "store/btnLoading";
import mainStore from "store/mainStore";
import proxy from "api/proxy";
import func from "tools/functions";

const ATTRS = { level: "wizard" };
const selectedCard = signal(null);
const payMethods = signal([]);
const amount = signal(0);
const residual = signal(0);
const voucher = signal("");
const loading = signal(false);
const showAlert = signal({
  status: false,
  text: "",
  color: "",
  icon: "",
});

const WizardAddPayment = ({ onClose }) => {
  const { setButtonLoading } = useBtnLoading();
  const { store, reset } = useWizardStore();
  const { record: recordParent, save, reload } = mainStore();
  const _terminal = func.getCookie();

  useEffect(() => {
    selectedCard.value = null;
    residual.value = recordParent.pending_to_pay;
    amount.value = residual.value;
    showAlert.value = { status: false, text: "", color: "", icon: "" };
    voucher.value = "";
    getMethodPayments();
  }, []);

  async function addPayment() {
    if (!recordParent.party) {
      onClose(false, "missing_party");
      setButtonLoading(false);
      return;
    }

    let toStore = {
      booking: recordParent.id,
      statement: store.statement,
      amount_to_pay: Math.round(amount.value),
      number: store.voucher,
      party: recordParent.party.id,
    };
    const values = {
      model: "hotel.booking",
      method: "add_payment",
      args: [toStore],
    };
    const { error } = await proxy.methodCall(values);
    const newResidual = Math.round(residual.value - amount.value);
    if (newResidual > 0) {
      residual.value = newResidual;
      amount.value = newResidual;
      showAlert.value = {
        status: true,
        text: "Agregado correctamente",
        color: "bg-green-300",
        icon: "check",
      };
    } else {
      onClose(true, error);
    }
    await save();
    await reload();
  }

  const getMethodPayments = async () => {
    const { data } = await proxy.search(
      "account.statement",
      [
        ["sale_device", "=", Number(_terminal.device_id)],
        ["state", "=", "draft"],
      ],
      ["id", "rec_name", "journal.name", "journal.require_voucher", "name"],
    );
    if (data.length > 0) {
      const updatedData = data.map((item) => ({
        ...item,
        rec_name: item["journal."]?.name,
      }));

      payMethods.value = updatedData;
    }
  };

  return (
    <Fragment>
      <Alert
        show={showAlert.value?.status}
        onClose={() => (showAlert.value = false)}
        text={showAlert.value?.text}
        color={showAlert.value?.color}
        icon={showAlert.value?.icon}
        autoRemove={true}
      />
      <SelectionCardField
        field={{
          ...statementField,
          options: payMethods.value,
        }}
        data={selectedCard}
        attrs={ATTRS}
      />
      {selectedCard.value && (
        <Fragment>
          <div>
            <div className="border-b mt-4 h-1 border-gray-300" />
            {selectedCard.value["journal."]?.["require_voucher"] && (
              <CharInlineField
                field={voucherField}
                data={voucher}
                attrs={ATTRS}
              />
            )}
            <CharInlineField
              field={amountField}
              data={amount}
              attrs={ATTRS}
              autoFocus={true}
            />
          </div>
          <div className="flex justify-end mt-10 space-x-4 ">
            <StdButton
              color="rose"
              onClick={() => onClose(false)}
              content={"Cancelar"}
              loading={loading.value}
              size="w-[270px]"
            />
            <StdButton
              onClick={addPayment}
              content={"Pagar"}
              loading={loading.value}
              size="w-[270px]"
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default WizardAddPayment;
