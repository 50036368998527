import React, { Fragment, useState } from "react";
import { FormattedMessage as FM } from "react-intl";

import FormMessage from "components/MeForm/FormMessage";
import FormGrid from "components/MeForm/FormGrid";
import HeadForm from "components/MeForm/HeadForm";
import FooterForm from "components/MeForm/FooterForm";
import ModalMsg from "components/Modals/ModalMsg";
import Form from "components/MeForm/Form";
import { useBtnLoading } from "store/btnLoading";
import actionButton from "store/actionButton";
import saveStore from "store/saveStore";
import useMainStore from "store/mainStore";
import useGrandStore from "store/grandStore";
import useChildStore from "store/childStore";
import { useWizardStore } from "store/wizardStore";

/**
 * create component Form
 * @param {Object} props
 * @param {Array} props.ctxView
 * @param {Function} props.modal
 * @param {String} props.level
 * @returns
 */
function QuickForm(props) {
  // kind => modal, fixed
  // level => main, child, grand, wizard, many2one
  const {
    ctxView,
    kind,
    onClose,
    onChangeView,
    level,
    parentRec,
    mode,
    fieldName,
  } = props;
  const { webfields, webform, model, title } = ctxView;
  const [message, setMessage] = useState(null);
  const [modalProps, setModalProps] = useState({
    open: false,
    msg: "",
    type: "info",
    origin: null,
    reset: true,
    buttons: ["close"],
  });
  const { reset } = useMainStore();
  const { setButtonLoading } = useBtnLoading();

  async function handleButton(btnName, method) {
    let button = webfields[btnName];
    if (["wizard", "many2one"].includes(level)) {
      button.method();
      if (button.close && onClose) {
        onClose();
      }
    } else if (button) {
      if (method) {
        button = method;
      }
      const res = await actionButton({ button, level, model, ctxView });
      handleInfo(res ?? {});
    }
    setButtonLoading(false);
  }

  function handleInfo({ msg, data, error, button }) {
    if (msg) {
      setMessage({ type: "error", msg: msg });
    } else if (error) {
      // TypeError is for network error or similars
      const msgError = error instanceof TypeError ? error.message : error.error;
      setModalProps({
        open: true,
        msg: msgError,
        type: "error",
        reset: false,
      });
    } else if (data?.msg) {
      setModalProps({
        open: true,
        msg: data.msg,
        type: data.type,
        origin: button,
        reset: true,
      });
    }
  }

  async function onSave(actionType, records) {
    // actionType: 'create', 'delete', 'write', 'accept' (write/create)
    // records: is used only for 'records' to delete / add / create
    const res = await saveStore({
      actionType,
      level,
      model,
      webfields,
      parentRec,
      fieldName,
      ctxView,
      records,
    });

    if (res) {
      if (res.msg || res.error) {
        handleInfo(res);
        return;
      }
      if (onClose && res.close) {
        onClose();
        console.log("We need reset?");
        // reset();
      }
      if (!res.error && level === "main") {
        setMessage({ type: "info", msg: "board.record_saved" });
        return res.recId;
      }
    }
    if (onClose && actionType === "accept") {
      onClose();
    }
  }

  function _onChangeView(event, action, store) {
    if (action === "return" && store && Object.values(store).length > 1) {
      setModalProps({
        open: true,
        msg: <FM id="quickform.want_to_save_data" />,
        type: "ask",
        reset: false,
        buttons: ["cancel", "discard", "ok"],
      });
      return;
    }
    onChangeView(event, action, store);
  }

  function onOkModalMsg() {
    if (ctxView.preSave) {
      handleButton(ctxView.preSave);
    } else {
      onSave("save");
    }
    setModalProps({
      open: false,
      msg: "",
      type: "info",
      origin: null,
    });
  }

  function onDiscardModalMsg() {
    onCloseModalMsg({ resetForm: true }, true);
    _onChangeView(null, "return");
  }

  function onCloseModalMsg(button, resetForm) {
    if (resetForm) {
      reset(button?.resetForm);
    }
    setModalProps({
      open: false,
      msg: "",
      type: "info",
      origin: null,
    });
  }

  let record;
  if (level === "main") {
    record = useMainStore((state) => state.record);
  } else if (level === "child") {
    record = useChildStore((state) => state.record);
  } else if (level === "grand") {
    record = useGrandStore((state) => state.record);
  } else {
    record = useWizardStore((state) => state.record);
  }

  return (
    <Fragment>
      <HeadForm
        key="form-header"
        ctxView={ctxView}
        kind={kind}
        onChangeView={_onChangeView}
        title={title}
        colspan={true}
        onSave={onSave}
        record={record}
        onClose={onClose}
      />
      <FormMessage
        message={message}
        handleCloseMessage={() => setMessage(null)}
      />
      <FormGrid>
        <Form
          key="form"
          ctxView={ctxView}
          webform={webform}
          handleButton={handleButton}
          record={record}
          parentRec={parentRec}
          level={level}
        />
      </FormGrid>
      {(kind === "modal" || level === "wizard") && (
        <FooterForm
          ctxView={ctxView}
          handleButton={handleButton}
          onSave={onSave}
          mode={mode}
        />
      )}
      {modalProps.open && (
        <ModalMsg
          open={modalProps.open}
          type={modalProps.type}
          msg={modalProps.msg}
          reset={modalProps.reset}
          onClose={onCloseModalMsg}
          onOK={onOkModalMsg}
          onDiscard={onDiscardModalMsg}
          origin={modalProps.origin}
          buttons={modalProps.buttons}
        />
      )}
    </Fragment>
  );
}

export default QuickForm;
