/*
--------------------------------------------------------------------------
  PRESIK KID PLATFORM
  Copyright © 2025 Presik SAS, All rights reserved.
--------------------------------------------------------------------------
*/

import React from "react";
import { createRoot } from "react-dom/client";

import MainApp from "./MainApp";
import "index.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(<MainApp />);
