// Spanish translation

const es = {
  "account.statement.open": "Abrir",
  "account.statement.close": "Cerrar",
  "account.statement.date": "Fecha",
  "account.statement.start_balance": "Saldo Base",
  "account.statement.line": "Pago / Anticipo",
  "account.statement.line.amount": "Valor",
  "account.statement.line.statement": "Medio de Pago",
  "account.statement.line.date": "Fecha",
  "account.statement.line.number": "Numero",
  "account.statement.line.party": "Tercero",
  "account.statement.open.msg_start":
    "Por favor, ingrese el saldo inicial para abrir los estados de cuenta...",
  "account.statement.open.msg_success":
    "Estados de cuenta abiertos exitosamente!",
  "account.statement.open.msg_error": "Falta la configuración del terminal!",
  "account.statement.close.msg_start":
    "Desea cerrar los estados de cuenta de su terminal?",
  "account.statement.close.msg_success":
    "Estados de cuenta cerrados exitosamente!",
  "account.statement.close.msg_error": "Falta la configuración del terminal!",
  "account.statement.create_uid": "Usuario",
};

export default es;
