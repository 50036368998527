import React, { useEffect } from "react";
import { FormattedMessage as FM } from "react-intl";

import SubTable from "components/MeTable/SubTable";
import MsgInfo from "components/Step/MsgInfo";
import CardTotal from "./CardTotal";
import ModelMoney from "./ModelMoney";
import { useWizardStore } from "store/wizardStore";
import funcs from "tools/functions";

function FormMoney({ defaults }) {
  const { store, record, setTrigger, updateWizard } = useWizardStore();
  const data = { value: store.money };
  const parentRec = {
    record: record,
    fieldName: "money",
  };

  const field = {
    name: "money",
    ctxView: ModelMoney.ctxView(),
  };

  const attrs = {
    level: "wizard",
    parentRec: parentRec,
  };

  useEffect(() => {
    setTrigger(onChangeRows);
    const record = Object.assign({}, defaults);
    updateWizard(record);
  }, [defaults]);

  function onChangeRows(_store) {
    let _total = 0;
    for (const value of _store.money.values()) {
      _total += value.bill * value.quantity;
    }
    record.total = _total;
    updateWizard({ ...store, total: funcs.fmtMoney(_total) });
  }

  return (
    <div
      key="account-statement-step-money"
      className="w-full text-gray-600 text-xl"
    >
      <div className="flex w-full mt-6 pl-3">
        <div className="w-1/2">
          <SubTable field={field} attrs={attrs} data={data} />
        </div>
        <div className="w-1/2">
          <CardTotal />
          <div className="mt-12 px-12">
            <MsgInfo
              msg={
                <FM
                  id="account.statement.close.msg_start"
                  key="account_statement_close"
                />
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormMoney;
