import React from "react";
import { FormattedMessage as FM } from "react-intl";

import ImageFace from "assets/img/reconocimiento-facial.png";
import StdButton from "components/Buttons/StdButton";
import RegisterDate from "./RegisterDate";

const HomeApp = ({ handleView }) => {
  return (
    <div className="w-full min-h-full grid grid-cols-2  container  ">
      <div className="flex h-full flex-col justify-center items-start space-y-10 z-20">
        <div>
          <h1 className="text-4xl md:text-6xl xl:text-7xl uppercase font-semibold text-left  text-[#00726e] ">
            <FM id="attendance_control.control" />{" "}
            <span className="text-white">
              <FM id="attendance_control.attendance" />{" "}
            </span>
          </h1>
          <h2 className="text-3xl  uppercase font-medium text-left  text-[#00726e] uppe">
            <FM id="attendance_control.face_recognition" />{" "}
          </h2>
        </div>
        <div className="flex flex-col text-white justify-center text-left space-y-5">
          <RegisterDate isSingleLine={false} style={"md:text-4xl"} />
        </div>
      </div>
      <div className="absolute md:static flex justify-center items-center md:pl-7 h-full w-full z-10">
        <img
          src={ImageFace}
          className="mx-auto absolute w-full md:w-auto md:h-full bottom-0"
        />
      </div>
      <div className="pt-10 col-span-2 flex z-10">
        <StdButton
          style={"h-16 min-w-[240px]  !from-[#00726e] !to-[#00726e] !text-2xl"}
          name="passwd"
          content="attendance_control.start"
          onClick={() => handleView("step2")}
        />
      </div>
    </div>
  );
};

export default HomeApp;
