import React from "react";
import { FormattedMessage as FM } from "react-intl";

import ColForm from "components/MeForm/ColForm";

function FormField(props) {
  return (
    <ColForm colspan={props.colspan}>
      {props.label && (
        <label
          className="flex text-base mt-3 mb-2 ml-1 font-bold text-zinc-600 max-h-5"
          htmlFor={props.name}
        >
          <FM id={props.label} key={props.label} />
          {props.required && (
            <p className="font-bold text-base text-red-500">&nbsp; *</p>
          )}
        </label>
      )}
      {props.children}
    </ColForm>
  );
}

export default FormField;
