import React, { useState } from "react";
import { FormattedMessage as FM } from "react-intl";

import ModalReport from "components/Modals/ModalReport";

function MenuActionReport({ report }) {
  let [openReport, setOpenReport] = useState(false);

  function onClick() {
    setOpenReport(true);
  }

  function onClose() {
    setOpenReport(false);
  }

  return (
    <div
      key={report.name}
      className="flex px-3 py-2 relative gap-x-6 rounded-lg hover:bg-stone-100 hover:text-sky-700 hover:cursor-pointer"
      onClick={onClick}
    >
      <div className="grid">
        <p className="">
          <FM id={report.name} />
        </p>
        <p className="text-stone-400 mt-2">
          <FM id={report.description} />
        </p>
      </div>
      <ModalReport
        onClose={onClose}
        open={openReport}
        ctxView={report.ctxView}
      />
    </div>
  );
}

export default MenuActionReport;
