// Model Add Room Wizard

function getOptionsRooms(record) {
  let res = new Map();
  if (record) {
    res = record.rooms;
  }
  console.log("getOptionsRooms ...", record, res);
  return res;
}

const getView = () => {
  let DictCtxView = {
    model: "hotel.booking.add_rooms.selector",
    form_action: [],
    table_action: [],
    webfields: {
      main_guest: {
        type: "many2one",
        model: "party.party",
        readOnly: {
          registration_state: ["check_in", "check_out"],
        },
      },
      unit_price: {
        type: "numeric",
        required: true,
        editable: true,
      },
      discount: {
        type: "numeric",
        // required: true,
      },
      room: {
        type: "many2one",
        model: "hotel.room",
        // required: true,
        editable: true,
        options: getOptionsRooms,
      },
      product: {
        type: "many2one",
        model: "product.product",
        recSearch: () => [
          ["template.type", "=", "service"],
          ["template.kind", "=", "accommodation"],
        ],
        attrs: ["template.customer_taxes_used", "code"],
      },
      sale_price_taxed: {
        type: "numeric",
        editable: true,
      },
      available: {
        type: "integer",
        readOnly: true,
      },
      adults: {
        type: "integer",
        editable: true,
      },
      children: {
        type: "integer",
        editable: true,
      },
      add: {
        type: "button",
        color: "blue",
        iconRight: "fi fi-rr-add",
        // method: addRoom, Thism method is added in custom model
        // visible: true,
      },
    },
    webtree: [
      { name: "product", width: "20%" },
      { name: "available", width: "5%" },
      { name: "sale_price_taxed", width: "10%" },
      { name: "discount", width: "10%" },
      { name: "adults", width: "5%" },
      { name: "children", width: "5%" },
      { name: "room", width: "20%" },
      { name: "add", width: "10%" },
    ],
    webform: [],
  };

  return DictCtxView;
};

export default { ctxView: getView };
