import React from "react";
import { FormattedMessage as FM } from "react-intl";

import { classNames } from "tools/ui";

const classes =
  "flex mt-auto mb-2 h-12 border border-neutral-400 shadow-md cursor-pointer rounded-lg overflow-hidden text-xl hover:opacity-90 disabled:opacity-50 justify-center active:scale-95";

function ButtonCheck({ field, attrs, data, record }) {
  const { name, readOnly, label, method } = field;
  const { handleButton } = attrs;

  let iconCheck = "fi fi-rr-circle-xmark";
  let iconColor = "text-neutral-100";
  let bgLeft = "bg-neutral-300";
  let textLeft = "text-neutral-700";
  let bgRight = "bg-neutral-500";
  if (data.value) {
    iconCheck = "fi fi-rs-check-circle";
    iconColor = "text-neutral-100";
    bgLeft = "bg-sky-800";
    textLeft = "text-white";
    bgRight = "bg-sky-500";
  }

  function handleClick(e) {
    e.preventDefault();
    data.value = !data.value;
    if (handleButton) {
      handleButton(name, method);
    }
  }

  let _size = "w-full";
  let style = "";

  const bgColor = "bg-white";
  // const _disabled = readOnly;
  return (
    <div
      onClick={(e) => handleClick(e)}
      className={classNames(classes, style, _size, bgColor)}
      disabled={readOnly}
    >
      <div className={classNames("flex w-4/5", bgLeft)}>
        <span className={classNames("flex my-auto text-sm ml-4", textLeft)}>
          <FM id={label} />
        </span>
      </div>
      <div className={classNames("flex w-1/5", bgRight)}>
        <i
          key={name}
          className={classNames(
            "flex text-2xl my-auto mx-auto",
            iconCheck,
            iconColor,
          )}
        />
      </div>
    </div>
  );
}

export default ButtonCheck;
