// Folio Charge model
import dates from "tools/dates";

function readOnlyUnitPrice(record) {
  console.log(record, "validate record");

  return record.status !== "draft";
}

const onSearchProduct = () => {
  let res = [
    ["account_category", "!=", null],
    ["salable", "=", true],
  ];
  return res;
};

const onChangeQty = (record) => {
  const { product, unit_price_w_tax, quantity } = record;
  if (product) {
    record.amount = (Number(unit_price_w_tax) * quantity).toFixed(2).toString();
  }
};

const onChangeProduct = (record, store) => {
  if (record.product) {
    const product = record.product;
    let unit_price = product.list_price;
    const sale_price_taxed = record.product.sale_price_taxed;
    const fmt_unit_price = unit_price.toFixed(2).toString();
    store.unit_price = fmt_unit_price;
    record.unit_price = unit_price;
    record.unit_price_w_tax = sale_price_taxed;
    if (record.quantity) {
      record.amount = record.quantity * sale_price_taxed;
    }
  }
};

const getAmount = (record) => {
  if (record.unit_price_w_tax && record.quantity) {
    const amount = (record.unit_price_w_tax * record.quantity).toFixed(2);
    return amount;
  }
};

const getView = (props) => {
  const selectable = props?.selectable;
  const table_action = props ? props.table_action : ["add", "edit", "delete"];
  let DictCtxView = {
    model: "hotel.folio.charge",
    form_action: ["add", "edit", "delete"],
    table_action: table_action,
    domain: [],
    orderBy: [["sale_date", "ASC"]],
    limit: 100,
    autoSave: true,
    selectable: selectable,
    target: "folio",
    webfields: {
      sale_date: {
        type: "date",
        readOnly: false,
        default: dates.dateToday(),
        required: true,
      },
      kind: {
        type: "selection",
        readOnly: true,
        required: true,
        default: { id: "product", name: "product" },
        translate: true,
        options: [
          { id: "product", name: "product" },
          { id: "accommodation", name: "accommodation" },
        ],
      },
      amount: {
        type: "numeric",
        readOnly: true,
        function: getAmount,
        decimalPlaces: 2,
      },
      status: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
      },
      folio: {
        type: "many2one",
        model: "hotel.folio",
        readOnly: true,
      },
      invoice_line: {
        type: "many2one",
        model: "account.invoice.line",
        readOnly: true,
      },
      product: {
        type: "many2one",
        model: "product.product",
        required: true,
        recSearch: onSearchProduct,
        withChange: onChangeProduct,
        attrs: [
          "id",
          "list_price",
          "name",
          "sale_price_taxed",
          "sale_uom.rec_name",
        ],
      },
      quantity: {
        type: "numeric",
        default: 1,
        required: true,
        withChange: onChangeQty,
      },
      order: {
        type: "char",
        readOnly: true,
        // default: 1,
      },
      unit_price_w_tax: {
        type: "numeric",
        required: true,
        decimalPlaces: 2,
        readOnly: readOnlyUnitPrice,
      },
    },
    webtree: [
      { name: "sale_date", width: "10%" },
      { name: "product", width: "25%" },
      { name: "quantity", width: "20%" },
      { name: "unit_price_w_tax", width: "20%" },
      { name: "amount", width: "25%" },
      { name: "status", width: "25%" },
    ],
    webform: [
      { name: "product" },
      {
        id: "info_main",
        grid: [{ name: "sale_date" }, { name: "folio" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "info_qty",
        grid: [{ name: "quantity" }, { name: "unit_price_w_tax" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "info_amounts",
        grid: [{ name: "amount" }, { name: "kind" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "order" },
      {
        id: "info_status",
        grid: [{ name: "status" }, { name: "invoice_line" }],
        size: [1, 2],
        span: "col-span-1",
      },
      // { name: "add_product" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
