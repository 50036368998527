import NumericCell from "./NumericCell";
import SelectionCell from "./SelectionCell";
import TextCell from "./CharCell";
import IntegerCell from "./IntegerCell";
import FloatCell from "./FloatCell";
import BooleanCell from "./BooleanCell";
import DropdownCell from "./DropdownCell";
import DatetimeCell from "./DatetimeCell";
import CharCell from "./CharCell";
import DateCell from "./DateCell";
import ButtonTable from "components/Buttons/ButtonTable";
import CircleCell from "./CircleCell";
import Badge from "components/Tools/Badge";
import ButtonCustomModal from "components/MeTable/ButtonCustomModal";
import CallAction from "components/MeTable/CallAction";
import QrCell from "./QrCell";
import ImageCell from "./ImageCell";
// import ButtonCustomModal from "./ButtonCustomModal";
// import IconSource from "components/Icons/IconSource";
// In future
// import ImageLink from "./ImageLink";

const poolCell = {
  char: CharCell,
  numeric: NumericCell,
  selection: SelectionCell,
  text: TextCell,
  date: DateCell,
  datetime: DatetimeCell,
  integer: IntegerCell,
  float: FloatCell,
  boolean: BooleanCell,
  many2one: DropdownCell,
  button: ButtonTable,
  image: ImageCell,
  button_custom_modal: ButtonCustomModal,
  badge: Badge,
  circle: CircleCell,
  qr: QrCell,
  call_action: CallAction,
};

export default poolCell;
