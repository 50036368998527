import CharField from "components/MeForm/CharField";
import NumericField from "components/MeForm/NumericField";
import DropdownField from "components/MeForm/DropdownField";
import DateField from "components/MeForm/DateField";
import SubTable from "components/MeTable/SubTable";
import SelectionField from "components/MeForm/SelectionField";
import TextField from "components/MeForm/TextField";
import CheckboxField from "components/MeForm/CheckboxField";
import DatetimeField from "components/MeForm/DatetimeField";
import DividerField from "components/MeForm/Divider";
import CloudImage from "components/MeForm/CloudImage";
import MultiSelectionField from "components/MeForm/MultiSelectionField";
import ImageField from "components/MeForm/ImageField";
import ButtonForm from "components/Buttons/ButtonForm";
import ButtonModal from "components/Buttons/ButtonModal";
import ButtonIcon from "components/Buttons/ButtonIcon";
import ButtonCheck from "components/Buttons/ButtonCheck";
import ButtonCustomModal from "components/Buttons/ButtonCustomModal";
import SearchField from "components/MeForm/SearchField";
import RadioGroupField from "components/MeForm/RadioGroupField";
import TakeCapture from "components/MeForm/TakeCapture";
import FileField from "components/MeForm/FileField";
import IntegerField from "components/MeForm/IntegerField";
import ModalWizard from "components/Modals/ModalWizard";
import FloatField from "components/MeForm/FloatField";
import Paragraph from "components/MeForm/Paragraph";
import GridFields from "components/MeForm/GridFields";
import CharInlineField from "components/MeForm/CharInlineField";
import CustomWidget from "components/MeForm/CustomWidget";
import Heading from "components/MeForm/Heading";
import Tabs from "components/Tabs/Tabs";
import SelectionCardField from "components/MeForm/SelectionCardField";
import GridCards from "components/Card/GridCardsNew";

const pool = {
  char: CharField,
  char_inline: CharInlineField,
  numeric: NumericField,
  selection: SelectionField,
  selection_card: SelectionCardField,
  text: TextField,
  date: DateField,
  datetime: DatetimeField,
  one2many: SubTable,
  multiselection: MultiSelectionField,
  integer: IntegerField,
  float: FloatField,
  boolean: CheckboxField,
  many2one: DropdownField,
  search_add: SearchField,
  search_add_new: SearchField,
  radio: RadioGroupField,
  button: ButtonForm,
  image: ImageField,
  cloud_image: CloudImage,
  button_wizard: ModalWizard,
  button_modal: ButtonModal,
  button_icon: ButtonIcon,
  button_check: ButtonCheck,
  take_capture: TakeCapture,
  file: FileField,
  divider: DividerField,
  button_custom_modal: ButtonCustomModal,
  paragraph: Paragraph,
  grid: GridFields,
  custom: CustomWidget,
  heading: Heading,
  tabs: Tabs,
  cards: GridCards,
};

export default pool;
