import React from "react";
import { FormattedMessage as FM } from "react-intl";

import HeaderCell from "components/MeTable/HeaderCell";

function TableHeader(props) {
  const { ctxView, parentRec, selectRows, selectable } = props;
  const { webtree, table_action, access } = ctxView;

  let cellButtonStart = [];
  let cellButtonEnd = [];
  if (table_action) {
    for (const btn of table_action) {
      if (btn === "add") continue;
      if (btn === "edit" || btn === "open") {
        cellButtonStart.push(btn);
      } else if (btn === "delete") {
        const accessDelete = access?.delete
          ? access.delete(parentRec.record)
          : true;
        if (accessDelete) cellButtonEnd.push(btn);
      } else {
        cellButtonEnd.push(btn);
      }
    }
  }

  function cellButtons(buttons) {
    return buttons.map((button) => {
      return (
        <th key={button} className="px-3">
          <FM id={`button_${button}`} />
        </th>
      );
    });
  }

  function handleCheck(e) {
    const checked = e.target.checked;
    selectRows(checked);
  }

  return (
    <thead className="sticky top-0 leading-6">
      <tr className="bg-amber-100">
        {selectable === "multi" && (
          <th className="text-center w-11">
            <input
              type="checkbox"
              name={"selectable_header"}
              onChange={handleCheck}
              className="h-5 w-5 rounded-lg accent-sky-600"
            />
          </th>
        )}
        {cellButtons(cellButtonStart)}
        {webtree.map((field, index) => (
          <HeaderCell key={index} index={index} field={field} {...props} />
        ))}
        {cellButtons(cellButtonEnd)}
      </tr>
    </thead>
  );
}

export default TableHeader;
