import React from "react";

import StdButton from "components/Buttons/StdButton";

const style =
  "flex sticky bottom-0 w-full justify-end my-4 4292md:flex-row gap-x-4";

function ButtonsStep({ buttons }) {
  const _buttons = [];

  for (const btn of buttons) {
    let ntbn;
    if (btn.name === "cancel") {
      ntbn = (
        <StdButton
          key="cancel"
          color="rose"
          size="w-full sm:w-1/2 md:w-1/3"
          onClick={btn.method}
          content="button.cancel"
        />
      );
    } else if (btn.name === "close") {
      ntbn = (
        <StdButton
          key="close"
          color="black"
          size="w-full sm:w-1/2 md:w-1/3"
          onClick={btn.method}
          content="button.close"
        />
      );
    } else {
      ntbn = (
        <StdButton
          key={btn.name}
          color="blue"
          size="w-full sm:w-1/2 md:w-1/3"
          onClick={() => btn.handleStep(btn.method)}
          content={btn.name}
        />
      );
    }
    _buttons.push(ntbn);
  }
  return <div className={style}>{_buttons}</div>;
}

export default ButtonsStep;
