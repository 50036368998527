import React, { Fragment, useEffect } from "react";

import FormWizard from "components/MeForm/FormWizard";
import { Alert } from "components/Alerts/Alert";
import { signal } from "@preact/signals-react";
import funcs from "tools/functions";
import ModelUpdateFolio from "./ModelUpdateFolio";
import { useWizardStore } from "store/wizardStore";
import { useBtnLoading } from "store/btnLoading";
// import mainStore from "store/mainStore";
import proxy from "api/proxy";
import useChildStore from "store/childStore";

const msgAlert = signal({});

const UpdateFolio = ({ onClose, parentRec }) => {
  const { setButtonLoading } = useBtnLoading();
  const { store, reset, updateWizard } = useWizardStore();
  const { record, reload } = useChildStore();

  useEffect(() => {
    updateWizard({ ...store, ...{ parentRec: parentRec } });
    msgAlert.value = {};
  }, []);

  async function handleUpdateFolio() {
    let _data = funcs.recToTryton(store);
    let folioId = parentRec.id;
    const values = {};

    Object.entries(_data).forEach(([key, value]) => {
      if (value && key !== "parentRec") {
        values[key] = value;
      }
    });
    delete values.user;
    delete values.sale_device;
    delete values.operation_center;
    delete values.shop;

    const args = {
      model: "hotel.folio",
      ids: [folioId],
      values: values,
      ctx: { overbooking: _data.overbooking },
    };
    const { data, error } = await proxy.save(args);
    if (error) {
      console.log(error, "error");

      msgAlert.value = {
        show: true,
        text: error?.error,
        icon: "xmark",
        onClose: () => {
          msgAlert.value = { show: false };
        },
      };
      setButtonLoading(false);
      return false;
    }
    reset();
    reload();
    onClose(true);
  }

  return (
    <Fragment>
      <Alert {...msgAlert.value} />
      <FormWizard
        key="hotel-booking-wizard-bill"
        ctxView={ModelUpdateFolio.ctxView(parentRec)}
        parentRec={{ record: record }}
        handleButton={handleUpdateFolio}
      />
    </Fragment>
  );
};

export default UpdateFolio;
