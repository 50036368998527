// Spanish translation

const es = {
  "hotel.folio.guest": "Huesped",
  "hotel.folio.guest.name": "Nombre",
  "hotel.folio.guest.adult": "Adulto",
  "hotel.folio.guest.child": "Niño",
  "hotel.folio.guest.sex": "Sexo",
  "hotel.folio.guest.male": "Masculino",
  "hotel.folio.guest.female": "Femenino",
  "hotel.folio.guest.nationality": "Nacionalidad",
  "hotel.folio.guest.main_guest": "Huesped Principal",
  "hotel.folio.guest.type_guest": "Tipo de Huesped",
  "hotel.folio.guest.type_document": "Tipo Doc.",
  "hotel.folio.guest.id_number": "Número ID",
  "hotel.folio.guest.mobile": "Movil",
  "hotel.folio.guest.email": "Correo Electrónico",
  "hotel.folio.guest.address": "Direccion",
  "hotel.folio.guest.origin_country": "País Origen",
  "hotel.folio.guest.target_country": "País Destino",
  "hotel.folio.guest.birthday": "Fecha Nac.",
  "hotel.folio.guest.notes": "Notas",
  "hotel.folio.guest.registro_civil": "Registro Civil de Nacimiento",
  "hotel.folio.guest.tarjeta_identidad": "Tarjeta de Identidad",
  "hotel.folio.guest.cedula_ciudadania": "Cedula de Ciudadania",
  "hotel.folio.guest.tarjeta_extranjeria": "Tarjeta de Extranjeria",
  "hotel.folio.guest.cedula_extranjeria": "Cedula de Extranjeria",
  "hotel.folio.guest.nit": "NIT",
  "hotel.folio.guest.add_guest": "Add",
  "hotel.folio.guest.cancel": "Cancelar",
  "hotel.folio.guest.pasaporte": "Pasaporte",
  "hotel.folio.guest.tipo_documento_extranjero": "Tipo de Documento Extranjero",
  "hotel.folio.guest.pep": "PEP",
  "hotel.folio.guest.nit_otro_pais": "NIT de otro pais",
  "hotel.folio.guest.nuip": "NUIP",
  "hotel.folio.guest.country": "País",
  "hotel.folio.guest.subdivision": "Subdivision",
  "hotel.booking.link_web_checkin": "Enlace para el Check-in",
  "hotel.folio.guest.city_co": "Ciudad",
  "hotel.folio.guest.foreign_city": "Ciudad Extranjera",
};

export default es;
