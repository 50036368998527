function getLabel(field, typeView, model) {
  console.log("deprecate function.......!!!");
  let label = field.name;
  if (typeView !== "tree") {
    label = `${model}.${label}`;
  }
  return label;
}

function getRequired(field, record) {
  let required = field.required || false;
  if (typeof field.required === "function") {
    required = field.required(record);
  }
  return required;
}

function getReadOnly(field, record, parentRec) {
  let readOnly = false;
  if (parentRec && parentRec.readOnly) {
    readOnly = true;
  } else if (typeof field.readOnly === "boolean") {
    readOnly = field.readOnly;
  } else if (record && typeof field.readOnly === "function") {
    readOnly = field.readOnly(record);
  } else if (record && field.readOnly) {
    for (const [keyField, valuesField] of Object.entries(field.readOnly)) {
      if (valuesField.includes(record[keyField])) {
        readOnly = true;
      }
    }
  }
  return readOnly;
}

function getValue(field, record) {
  const { name, type } = field;
  let _value = record[name];
  if (name.includes(".")) {
    _value = name
      .split(".")
      .reduce((acc, key) => acc?.[`${key}.`] ?? acc?.[key], record);
  } else if (
    ["many2one", "one2many"].includes(type) &&
    record[`${field.name}.`]
  ) {
    _value = record[`${field.name}.`];
  }
  if (field.function) {
    _value = field.function(record);
  }
  return _value;
}

function getVisible(field, record) {
  let isVisible = true;
  if (record && field.visible) {
    isVisible = field.visible(field.name, record);
  }
  return isVisible;
}

function getAlign(field) {
  let align = "text-center";
  if (["char", "many2one"].includes(field.type) && !field.widget) {
    align = "text-left";
  } else if (["numeric", "float"].includes(field.type)) {
    align = "text-right";
  }
  return align;
}

export default {
  getVisible,
  getValue,
  getReadOnly,
  getRequired,
  getLabel,
  getAlign,
};
