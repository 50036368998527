import React from "react";
import { FormattedMessage as FM } from "react-intl";

import Spinner from "components/Tools/Spinner.js";
import { classNames } from "tools/ui";

const colors = {
  blue: "from-blue-400 to-cyan-500 text-white ",
  yellow: "from-yellow-400 to-orange-500 text-white",
  amber: "from-amber-300 to-amber-400 text-gray-900",
  green: "from-lime-500 to-green-500 text-white",
  indigo: "from-indigo-400 to-violet-500 text-white",
  pink: "from-pink-500 to-pink-600 text-white",
  rose: "from-rose-400 to-rose-500 text-white",
  slate: "from-slate-500 to-slate-600 text-white",
  bluePresik: "bg-blue-presik text-white",
  yellowPresik: "bg-yellow-500 text-blue-presik",
  black: "bg-black text-white",
};

const offColors = {
  red: "border-rose-500 text-rose-800",
  blue: "border-blue-500 text-blue-800",
  lime: "border-lime-500 text-lime-800",
};

const classes =
  "flex shadow-md hover:shadow-lg bg-gradient-to-r py-1 px-3 items-center text-center hover:opacity-90 disabled:opacity-50 justify-center gap-2 rounded-xl active:scale-95 active:shadow-md";

function StdButton({
  name,
  size,
  style,
  height,
  color,
  disabled,
  loading,
  onClick,
  noFill,
  content,
  alingTextRight,
  children,
  iconLeft,
  iconRight,
}) {
  function handleClick(e) {
    e.preventDefault();
    if (loading) return;
    onClick(e, name);
  }

  let _size = "w-40";
  if (size) {
    _size = size;
  }
  let _height = height ?? "h-12";
  if (!style) {
    style = "";
  }
  if (!color) {
    color = "blue";
  }
  let _color = "";
  if (noFill) {
    style = style + " bg-transparent border-[1px] " + offColors[color];
  } else {
    style += " border-0";
    _color = colors[color] || color;
  }

  const _disabled = disabled ? disabled : false;

  let leftIcon = null;
  let rightIcon = null;
  if (iconLeft || iconRight) {
    const nameIcon = iconLeft || iconRight;
    let icon = (
      <i
        key={nameIcon}
        className={classNames("flex text-md my-auto", nameIcon)}
      />
    );
    if (iconLeft) {
      leftIcon = icon;
    }
    if (iconRight) {
      rightIcon = icon;
    }
  }
  return (
    <button
      type="submit"
      onClick={(e) => handleClick(e)}
      className={classNames(_size, _height, _color, classes, style)}
      disabled={_disabled}
    >
      {loading ? (
        <Spinner color="white" />
      ) : alingTextRight ? (
        <>
          {children}
          {content && (
            <p>
              <FM id={content} key={content} />
            </p>
          )}
        </>
      ) : (
        <>
          {leftIcon}
          {content && (
            <p className="w-auto h-full flex items-center justify-center ">
              <FM id={content} key={content} />
            </p>
          )}
          {rightIcon}
          {children}
        </>
      )}
    </button>
  );
}

export default StdButton;
