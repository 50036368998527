import React, { Fragment, useEffect } from "react";
import { signal } from "@preact/signals-react";

import { useWizardStore } from "store/wizardStore";
import mainStore from "store/mainStore";
import proxy from "api/proxy";
import dates from "tools/dates";
import func from "../../../tools/functions";
import SelectionCardField from "../../../components/MeForm/SelectionCardField";
import { statementField, amountField, voucherField } from "./PaymentFields";
import StdButton from "components/Buttons/StdButton";
import CharInlineField from "components/MeForm/CharInlineField";
import ModelSale from "../models/ModelSale";
import { Alert } from "components/Alerts/Alert";

const ATTRS = { level: "wizard" };
const selectedCard = signal(null);
const payMethods = signal([]);
const amount = signal(0);
const voucher = signal("");
const loading = signal(false);
const showAlert = signal({
  status: false,
  text: "",
  color: "",
  icon: "",
});

const WizardAddPayment = ({ onClose }) => {
  const {
    store: storeWizard,
    record: recordWizard,
    reset,
    updateWizard,
  } = useWizardStore();

  const { setRecord, record, store, updateRecord } = mainStore();
  const _terminal = func.getCookie();
  const ctxViewSale = ModelSale.ctxView();

  useEffect(() => {
    selectedCard.value = null;
    let _residual = record.total_amount - record.paid_amount;
    const rec = { amount: _residual };
    amount.value = _residual;
    showAlert.value = { status: false, text: "", color: "", icon: "" };
    updateWizard(rec);
    getMethodPayments();
    // upActiveWizard(rec);
  }, []);

  const handleAddPayment = async () => {
    loading.value = true;
    const _storeRecord = { ...store };
    const _data = func.recToTryton(_storeRecord);
    if (!record.party) {
      onClose(false, "missing_party");
      return;
    }
    const requireVoucher = selectedCard.value?.["journal."]?.require_voucher;
    if (
      !selectedCard.value ||
      (requireVoucher && !storeWizard.voucher) ||
      !storeWizard.amount
    ) {
      loading.value = false;
      showAlert.value = {
        status: true,
        text: "Voucher is required",
        icon: "xmark",
      };
      return;
    }
    let recordId = record.id;

    await proxy.saveQuery({
      model: "sale.sale",
      storeRec: _data,
    });

    const toStore = {
      statement: storeWizard.statement,
      amount: String(storeWizard.amount),
      number: storeWizard.voucher,
      date: dates.dateToday(),
      description: record.number,
      party: record.party.id,
      account: record.party.account_receivable,
      source: `sale.sale,${recordId}`,
      sale: recordId,
    };
    const { data, error } = await createStatementLine(toStore);
    if (data) {
      const sale = data[0]?.["sale."];
      const residual_amount = sale?.residual_amount;

      if (residual_amount > 0) {
        updateRecord({ ...record, ...sale });
        updateWizard({
          amount: residual_amount,
          statement: null,
          voucher: null,
        });
        amount.value = residual_amount;
        showAlert.value = {
          status: true,
          text: "Agregado correctamente",
          color: "bg-green-300",
          icon: "check",
        };
      } else {
        const _storeRecord = { ...store };
        _storeRecord.sale_id = recordId;
        const saleDoneError = await callSaleDoneMethod(recordId);
        onClose(true, saleDoneError);
        setRecord(recordId, "sale.sale", ctxViewSale);
        reset();
      }
      // showAlert.value = {
      //   status: false,
      //   text: "",
      //   color: "",
      //   icon: "",
      // };
      loading.value = false;
    }

    return true;
  };

  const createStatementLine = async (toStore) => {
    const model = "account.statement.line";
    const fields_names = ["id", "sale.residual_amount", "sale.paid_amount"];
    return await proxy.create(model, toStore, fields_names);
  };

  const callSaleDoneMethod = async (recordId) => {
    const sale = { sale_id: recordId };
    const argSaleDone = {
      model: "sale.sale",
      method: "faster_process",
      args: [sale],
    };
    const { data } = await proxy.methodCall(argSaleDone);
    const argSalePost = {
      model: "sale.sale",
      method: "faster_post_invoice",
      args: [sale],
    };

    const { dataPost } = await proxy.methodCall(argSalePost);
    const argSaleReconcile = {
      model: "sale.sale",
      method: "reconcile_invoice",
      args: [sale],
    };

    const { dataReconcile, errorReconcile } =
      await proxy.methodCall(argSaleReconcile);
    console.log(errorReconcile);
    return dataReconcile;
  };

  const getMethodPayments = async () => {
    const { data } = await proxy.search(
      "account.statement",
      [
        ["sale_device", "=", Number(_terminal.device_id)],
        ["state", "=", "draft"],
      ],
      ["id", "rec_name", "journal.name", "journal.require_voucher", "name"],
    );
    if (data.length > 0) {
      const updatedData = data.map((item) => ({
        ...item,
        rec_name: item["journal."]?.name,
      }));

      payMethods.value = updatedData;
    }
  };

  return (
    <Fragment>
      <Alert
        show={showAlert.value?.status}
        onClose={() => (showAlert.value = false)}
        text={showAlert.value?.text}
        color={showAlert.value?.color}
        icon={showAlert.value?.icon}
        autoRemove={true}
      />
      <SelectionCardField
        field={{
          ...statementField,
          options: payMethods.value,
        }}
        data={selectedCard}
        attrs={ATTRS}
      />
      {selectedCard.value && (
        <Fragment>
          <div>
            <div className="border-b mt-4 h-1 border-gray-300" />
            {selectedCard.value["journal."]?.["require_voucher"] && (
              <CharInlineField
                field={voucherField}
                data={voucher}
                attrs={ATTRS}
              />
            )}
            <CharInlineField
              field={amountField}
              data={amount}
              attrs={ATTRS}
              autoFocus={true}
            />
          </div>
          <div className="flex justify-end mt-10 space-x-4 ">
            <StdButton
              color="rose"
              onClick={() => onClose(false)}
              content={"Cancelar"}
              loading={loading.value}
              size="w-[270px]"
            />
            <StdButton
              onClick={handleAddPayment}
              content={"Pagar"}
              loading={loading.value}
              size="w-[270px]"
            />
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default WizardAddPayment;
