import purchase_line from "./ModelPurchaseLine";
import tools from "tools/common";

let dateTo = tools.dateToday();
let dateFrom = tools.dateAdd(new Date(), "days", -30);

const searchParty = (value) => {
  if (!value) return [];
  const search = [
    "OR",
    [["name", "ilike", `%${value}%`]],
    [["id_number", "ilike", `${value}%`]],
  ];
  return search;
};

// const searchPaymentTerm = (value) => {
//   const search = `[('name', 'ilike', '%${value}%'),]`;
//   return search;
// };

const getFilters = (session) => {
  return {
    all: [
      ["purchase_date", "<=", `${dateTo}`],
      ["purchase_date", ">=", `${dateFrom}`],
      ["create_uid", "=", session.user],
    ],
    today: [
      ["purchase_date", "=", `${dateTo}`],
      ["create_uid", "=", session.user],
    ],
  };
};

const getTotalAmount = (activeRecord) => {
  let total_amount = 0;
  if (activeRecord.lines) {
    for (let l of activeRecord.lines.values()) {
      total_amount += l.amount;
    }
  } else if (activeRecord.total_amount) {
    total_amount = activeRecord.total_amount;
  }
  return total_amount;
};

const stateColors = {
  draft: "gray",
  quotation: "sky",
  cancelled: "rose",
  confirmed: "indigo",
  processing: "pink",
  done: "lime",
};

const readOnlyField = (record) => {
  return record.state !== "draft";
};

const getView = (config) => {
  Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
  };
  let DictCtxView = {
    model: "purchase.purchase",
    limit: 500,
    activeSearch: true,
    form_action: ["save"],
    table_action: ["edit", "add"],
    // form_action_add: true,
    // table_action_update: true,
    // form_action_save: true,
    filters: getFilters,
    validate_state: true,
    tags: {
      state: stateColors,
    },
    webfields: {
      // company: {
      //   type: "many2one",
      //   model: "company.company",
      //   readOnly: { state: ["quotation"] },
      // },
      party: {
        type: "many2one",
        model: "party.party",
        required: true,
        recSearch: searchParty,
        readOnly: readOnlyField,
        searchable: true,
      },
      invoice_address: {
        type: "many2one",
        model: "party.address",
        readOnly: readOnlyField,
      },
      payment_term: {
        type: "many2one",
        model: "account.invoice.payment_term",
        readOnly: readOnlyField,
      },
      description: { type: "char", readOnly: readOnlyField },
      reference: { type: "char", readOnly: readOnlyField },
      number: { type: "char", readOnly: true },
      purchase_date: { type: "date", readOnly: readOnlyField },
      lines: {
        type: "one2many",
        model: "purchase.line",
        ctxView: purchase_line.ctxView(config),
        required: true,
        readOnly: readOnlyField,
      },
      total_amount: {
        type: "numeric",
        readOnly: true,
        function: getTotalAmount,
        search: true,
      },
      comment: { type: "text", readOnly: readOnlyField },
      quotation: {
        type: "button",
        method: "dash_quotation",
        visible: (field, record) => record["state"] === "draft",
      },
      state: {
        type: "char",
        readOnly: true,
        translate: true,
        default: "draft",
        tags: stateColors,
      },
    },
    webtree: [
      { name: "number", width: "15%" },
      { name: "purchase_date", width: "15%" },
      { name: "party", width: "20%" },
      { name: "total_amount", width: "25%" },
      { name: "description", width: "25%" },
      { name: "state", width: "10%", widget: "badge" },
    ],
    webform: [
      { name: "party" },
      {
        id: "infoPurchase",
        grid: [{ name: "purchase_date" }, { name: "number" }],
        size: [1, 2],
        span: "col-span-1",
      },
      {
        id: "infoPurchase2",
        grid: [{ name: "payment_term" }, { name: "reference" }],
        size: [1, 2],
        span: "col-span-1",
      },
      { name: "description" },
      { name: "lines" },
      { name: "total_amount" },
      { name: "state" },
      { name: "comment" },
      { name: "quotation" },
    ],
  };

  return DictCtxView;
};

export default { ctxView: getView };
